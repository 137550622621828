import React from 'react';
import CasinoV2Menu from '../../components/casino-v-2/menu/CasinoV2Menu';
import LoaderPageCasino from '../../components/loader/page/casino/LoaderPageCasino';
import Seo from '../../components/seo/Seo';
import UiProductClosedBlurMessage from '../../components/ui/product-closed/blur-message/UiProductClosedBlurMessage';
import UiProductClosed from '../../components/ui/product-closed/UiProductClosed';
import useCasinoInit from '../../hooks/useCasinoInit';
import { useStore } from '../../hooks/useStore';
import { translate } from '../../services/translate';
import { isBlurredBlockedProduct } from '../../services/users/country';
import { casino } from '../../stores/casino';
import { media } from '../../stores/media/media';
import { PRODUCT } from '../../types/common';
import MainLayout from '../main/MainLayout';
import Wrapper from './styles';
import { stores } from '../../stores';
import CasinoV2Maintenance from '../../components/casino-v-2/maintenance/CasinoV2Maintenance';

export default function CasinoLayout({ children }: React.PropsWithChildren) {
    useCasinoInit({ isLobby: true });
    const [isBlockedCountry] = useStore(casino.isCasinoBlocked);
    const [isMaintenance] = useStore(stores.maintenance.isCasinoMaintenance);
    const [casinoInitialized] = useStore(casino.isInitialized);
    const [{ isSmallerThanLaptop }] = useStore(media);

    return (
        <>
            <Seo title={translate('seo.casino.title')} description={translate('seo.casino.desc')} />
            <MainLayout>
                {isMaintenance ? (
                    <CasinoV2Maintenance />
                ) : (
                    <Wrapper>
                        {casinoInitialized ? (
                            <>
                                <div className="casino-shell">
                                    <div className="casino-shell-content-container">
                                        {!isSmallerThanLaptop && (
                                            <div className="casino-shell-menu">
                                                <CasinoV2Menu />
                                            </div>
                                        )}
                                        {isBlockedCountry && !isBlurredBlockedProduct() ? (
                                            <UiProductClosed product={PRODUCT.CASINO} />
                                        ) : (
                                            <div className="casino-shell-content">
                                                <div className="casino-shell-games-container">{children}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {isBlockedCountry && isBlurredBlockedProduct() && <UiProductClosedBlurMessage />}
                            </>
                        ) : (
                            <div className="casino-shell">
                                <div className="casino-shell-content-container">
                                    {!isSmallerThanLaptop && <div className="casino-shell-menu" />}
                                    <div className="casino-shell-content">
                                        <LoaderPageCasino label={translate('Loading...', 'ui.common')} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Wrapper>
                )}
            </MainLayout>
        </>
    );
}

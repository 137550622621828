import { LocalStorage } from '../local-storage/types';
import { storageGet } from '../storage';
import { BETSLIP_SETTINGS_TTL } from './constants';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
export * from './betslip/odds';
export * from './betslip/actions';
export * from './betslip/stake';
export * from './betslip/errors';
export * from './betslip/bet-placement';

export function getOrResetBetSlipSettings() {
    const betSlipSettings: { updatedAt: number; betSlipMarketIdToOutcomeId: Record<number, number> } =
        storageGet(LocalStorage.BETSLIP_SETTINGS) || {};
    if (betSlipSettings.updatedAt) {
        const duration = new Date().getTime() - betSlipSettings.updatedAt;
        if (duration > BETSLIP_SETTINGS_TTL) {
            betSlipSettings.betSlipMarketIdToOutcomeId = {};
        }
    }
    return betSlipSettings.betSlipMarketIdToOutcomeId || {};
}

export function validateBetHandler<T>(
    handler: (marketsByMatchId: Record<string, BetSlipMinimalMarket[]>) => Promise<T> | T,
) {
    return async (marketsByMatchId: Record<string, BetSlipMinimalMarket[]>): Promise<T> => {
        return await handler(marketsByMatchId);
    };
}

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useScheduler } from '../../../hooks/useScheduler';
import { useStore } from '../../../hooks/useStore';
import { isFeatureAvailable } from '../../../services/feature';
import {
    GEOCOMPLY_IP_CHECK_TASK_NAME,
    GEOCOMPLY_VERIFICATION_TASK_NAME,
    getClientIp,
    getCountdownState,
    hardResetGeocomplyStore,
    isDesktopPlatform,
    isMobilePlatform,
    loadGeocomplyLicenseStatus,
    loadGeocomplyVerificationStatus,
    renewGeoLocation,
    verifyGeoLocation,
} from '../../../services/geocomply/geocomply';
import { GeoComplyDesktopLibrary, GeocomplyGeoverification } from '../../../services/geocomply/types';
import { useTimeout } from '../../../services/hooks';
import { logger } from '../../../services/logger';
import { FEATURE } from '../../../services/types';
import { stores } from '../../../stores';
import { environment } from '../../../stores/environment/environment';
import { geocomply } from '../../../stores/geocomply';
import ScriptLoader from '../../script-loader/ScriptLoader';

export default function SystemGeocomply() {
    const [user] = useStore(stores.user);
    const [token] = useStore(stores.token);
    const [{ GEOCOMPLY }] = useStore(environment);

    const [geocomplyLicense] = useStore(geocomply.license);
    const [isLoadingLicense] = useStore(geocomply.isLoadingLicense);
    const [geocomplyVerification] = useStore(geocomply.verification);
    const [isLoadingVerification] = useStore(geocomply.isLoadingVerification);
    const [appType] = useStore(stores.appType);
    const [verificationData] = useStore(stores.verification.data);

    const [msUntilGeocomplyLicenseExpiry, setMsUntilGeocomplyLicenseExpiry] = useState<number>();
    const [msUntilGeocomplyVerificationExpiry, setMsUntilGeocomplyVerificationExpiry] = useState<number>();

    useEffect(() => {
        if (!isFeatureAvailable(FEATURE.GEOCOMPLY)) {
            return;
        }

        if (
            ((verificationData.isGeoComplyAgeVerificationRequired &&
                verificationData.signUpToken &&
                verificationData.userId) ||
                (token && user)) &&
            geocomplyLicense.license === null &&
            !isLoadingLicense
        ) {
            loadGeocomplyLicenseStatus();
        }

        if (token && user && geocomplyVerification.isGeoVerified === null && !isLoadingVerification) {
            loadGeocomplyVerificationStatus();
        }
    }, [
        verificationData.isGeoComplyAgeVerificationRequired,
        token,
        user,
        geocomplyLicense,
        geocomplyVerification,
        isLoadingLicense,
        isLoadingVerification,
        appType,
    ]);

    useEffect(() => {
        const { license, expiresAt } = geocomplyLicense;

        if (license !== null && expiresAt !== null) {
            // Old license has a grace period of 5 minutes after expiry
            // Making FO update the license later makes sure the backend has had a chance to load the new license
            const licenseExpiresAtCountdownState = getCountdownState(
                moment.utc(expiresAt).add(2, 'minutes').toISOString(),
            );

            setMsUntilGeocomplyLicenseExpiry(licenseExpiresAtCountdownState.totalMillisecondsLeft);
        }

        return () => {
            setMsUntilGeocomplyLicenseExpiry(undefined);
        };
    }, [geocomplyLicense]);

    useEffect(() => {
        const { isGeoVerified, expiresAt, retryAt } = geocomplyVerification;

        if (isGeoVerified && expiresAt && retryAt) {
            const currentTime = moment().toISOString();
            const expiresAtCountdownState = getCountdownState(expiresAt, currentTime);
            setMsUntilGeocomplyVerificationExpiry(expiresAtCountdownState.totalMillisecondsLeft);
        }

        return () => {
            setMsUntilGeocomplyVerificationExpiry(undefined);
        };
    }, [geocomplyVerification]);

    function getNextIpCheckTimeoutMsForGeoVerification(geocomplyVerification?: GeocomplyGeoverification) {
        if (!geocomplyVerification) {
            return;
        }

        const { isGeoVerified, retryAt } = geocomplyVerification;

        if (isGeoVerified && retryAt) {
            const ipCheckIntervalInSeconds = 30;

            const currentTime = moment();
            const checkIpLastTimeAt = moment(retryAt).subtract(ipCheckIntervalInSeconds, 'seconds');
            const checkIpNextTimeAt = currentTime.clone().add(ipCheckIntervalInSeconds, 'seconds');
            const checkIpLastSchedulingTime = checkIpLastTimeAt.clone().subtract(5, 'seconds');

            const checkIpAt = currentTime.isSameOrBefore(checkIpLastSchedulingTime)
                ? checkIpNextTimeAt.isAfter(checkIpLastTimeAt)
                    ? checkIpLastTimeAt
                    : checkIpNextTimeAt
                : null;

            if (checkIpAt) {
                const checkIpAtCountdownState = getCountdownState(checkIpAt.toISOString(), currentTime.toISOString());
                return checkIpAtCountdownState.totalMillisecondsLeft;
            }
        }
    }

    useTimeout(
        () => {
            loadGeocomplyVerificationStatus();
        },
        msUntilGeocomplyVerificationExpiry,
        [msUntilGeocomplyVerificationExpiry],
    );

    const { startScheduler: startGeoVerificationScheduler, stopScheduler: stopGeoVerificationScheduler } = useScheduler(
        GEOCOMPLY_VERIFICATION_TASK_NAME,
        renewGeoLocation,
        {
            taskEndedInOtherTabCallback: () => {
                loadGeocomplyVerificationStatus();
            },
            reschedule: false,
        },
    );

    const { startScheduler: startIpCheckScheduler, stopScheduler: stopIpCheckScheduler } = useScheduler(
        GEOCOMPLY_IP_CHECK_TASK_NAME,
        geocomplyIpCheck,
        {
            taskEndedCallback: () => {
                startIpCheckScheduler(getNextIpCheckTimeoutMsForGeoVerification(geocomplyVerification));
            },
            reschedule: false,
        },
    );

    async function geocomplyIpCheck() {
        try {
            const clientCurrentIp = await getClientIp();

            if (clientCurrentIp !== geocomplyVerification.ipAddress) {
                await loadGeocomplyVerificationStatus(); // This request removes previous active verification in our GeoComply service if IP has changed
                await verifyGeoLocation('IP_CHANGE', true, true);
                return;
            }
        } catch (error) {
            logger.error('SystemGeocomply', 'geocomplyIpCheck', error);
        }
    }

    useEffect(() => {
        const { isGeoVerified, ipAddress, retryAt } = geocomplyVerification;

        if (isGeoVerified && ipAddress && retryAt) {
            const currentTime = moment().toISOString();
            const retryAtCountdownState = getCountdownState(retryAt, currentTime);

            startGeoVerificationScheduler(retryAtCountdownState.totalMillisecondsLeft);
            startIpCheckScheduler(getNextIpCheckTimeoutMsForGeoVerification(geocomplyVerification));
        }

        return () => {
            stopIpCheckScheduler();
            stopGeoVerificationScheduler();
        };
    }, [geocomplyVerification]);

    useTimeout(
        () => {
            loadGeocomplyLicenseStatus();
        },
        msUntilGeocomplyLicenseExpiry,
        [msUntilGeocomplyLicenseExpiry],
    );

    useEffect(() => {
        if (!token) {
            setMsUntilGeocomplyLicenseExpiry(undefined);
            setMsUntilGeocomplyVerificationExpiry(undefined);

            hardResetGeocomplyStore();

            if (isDesktopPlatform()) {
                const GeoComplyLibrary = (window as any).GeoComply as GeoComplyDesktopLibrary;
                GeoComplyLibrary?.Client.disconnect();
            }
        }
    }, [token]);

    function getGeoComplyLibraryUrl() {
        if (isMobilePlatform()) {
            return GEOCOMPLY?.MOBILE_LIBRARY_URL || '';
        }

        if (isDesktopPlatform()) {
            return '/lib/geocomply-importer.js';
        }

        return '';
    }

    if (!isFeatureAvailable(FEATURE.GEOCOMPLY)) {
        return null;
    }

    return (
        <ScriptLoader
            onLoad={() =>
                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.isLibraryLoaded = true;
                })
            }
            url={getGeoComplyLibraryUrl()}
            attributes={isDesktopPlatform() ? { type: 'module' } : undefined}
        />
    );
}

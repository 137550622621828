import styled, { css } from 'styled-components';

type StyledProps = {
    $mode: 'default' | 'drawer';
    maxWidth?: number | string;
    minWidth?: number | string;
    padding?: number | string;
    width?: number | string;
};

function getDimensionValue(value: number | string) {
    return Number.isInteger(value) ? `${value}px` : value;
}
function getMaxWidth({ maxWidth = 325 }: StyledProps) {
    return css`
        max-width: ${getDimensionValue(maxWidth)};
    `;
}

function getMinWidth({ minWidth }: StyledProps) {
    if (!minWidth) {
        return '';
    }

    return css`
        min-width: ${getDimensionValue(minWidth)};
    `;
}

function getWidth({ width = '100%' }: StyledProps) {
    return css`
        width: ${getDimensionValue(width)};
    `;
}

function getPadding({ padding }: StyledProps) {
    return css`
        padding: ${padding ?? 'var(--spacing-24)'};
    `;
}

export default styled.div<StyledProps>`
    color: var(--font-color-primary);
    font-size: var(--font-14);
    .new-close-button {
        display: none;
    }
    ${({ $mode }) =>
        $mode !== 'drawer' &&
        css<StyledProps>`
            ${getMinWidth};
            ${getMaxWidth};
            outline: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &.new-modal {
                ${getMinWidth};
                ${getMaxWidth};
                max-height: 90vh;
                overflow: auto;

                background-color: var(--surface-level-2-bg);
                border: var(--surface-level-2-border);
                border-radius: var(--ui-radius);
                box-shadow: var(--ui-shadow);
                color: var(--font-color-primary);
                ${getPadding};
                .ui-modal-title {
                    text-align: center;
                    font-size: 1.25rem;
                    font-weight: var(--font-weight-bold);
                    ${({ theme }) => theme.optimizeFontRendering};
                    margin: var(--spacing-12) auto var(--spacing-24) auto;
                }
                .new-close-button {
                    display: block;
                    top: var(--spacing-12);
                    right: var(--spacing-12);
                }
            }
            &.linear-bg {
                background: linear-gradient(180deg, #7cb0ac 10%, #0f525a 50%, #1f263d 90%);
                .new-close-button svg {
                    color: white;
                }
            }
        `};
    &.new-modal {
        ${getMinWidth};
        ${getMaxWidth};
        ${getWidth};
        margin: 0 auto;
        ${getPadding};
        background-color: var(--surface-level-2-bg);

        .ui-modal-title {
            text-align: center;
            font-size: 1.25rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            margin: var(--spacing-12) auto var(--spacing-24) auto;
        }
        .new-close-button {
            display: block;
            top: var(--spacing-12);
            right: var(--spacing-12);
        }

        .cutout {
            background-color: var(--surface-level-2-bg);
        }
    }
    .ui-modal-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--spacing-12) 0;
    }

    .betslip-box,
    .tickets-box {
        background: var(--betslip-bg);
        padding: 0 var(--spacing-12);
        color: var(--font-color-primary);

        user-select: none;
        overflow: auto;
    }

    .tickets-box {
        min-height: 100vh;
        max-height: 100vh;
        border-top-left-radius: var(--ui-radius);
        border-top-right-radius: var(--ui-radius);
    }

    .betslip-box {
        max-height: 100vh;
        padding-bottom: 10px;
    }

    .game-launcher-content {
        background: var(--betslip-bg);
        padding: 0 var(--spacing-16) var(--spacing-32) var(--spacing-16);
        min-height: 100vh;
        max-height: 100vh;
        overflow: auto;
    }

    p {
        color: var(--font-color-secondary);
        font-size: var(--font-14);
    }
    .ui-modal-content {
        text-align: center;
        max-height: 85vh;
        overflow: auto;
    }
    .cutout {
        background-color: var(--betslip-bg);
        padding: var(--spacing-8);
        display: flex;
        justify-content: center;
        z-index: 11;
    }
    .holder {
        width: 45px;
        height: 8px;
        background-color: var(--surface-level-3-bg);
        border: var(--surface-level-3-border);
        border-radius: 100px;
    }
    a {
        color: var(--font-link-color);
    }
`;

import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';
import transparentize from 'polished/lib/color/transparentize';

export default styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => transparentize(0.2, theme.surfacesLevel1.background)};
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-8);
    align-items: center;
    text-align: center;
    padding: var(--spacing-16);

    .image-wrapper img {
        width: 120px;
    }

    .title {
        color: var(--color-primary);
        font-size: 2rem;
    }

    .content {
        margin: 0;
    }

    @media ${mediaQuery.isLargerThanTablet} {
        align-items: start;
        text-align: left;
        padding-left: 100px;
        border-bottom-left-radius: 10rem;

        .image-wrapper img {
            width: 145px;
        }

        .title {
            font-size: 2.5rem;
        }

        .content {
            font-size: var(--font-20);
        }
    }
`;

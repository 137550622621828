import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';
import { ClientName } from '../../../services/utils/types';

type StyledProps = {
    isTwoToneTheme?: boolean;
};

export default styled.div<StyledProps>`
    top: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-header-bg);
    border-bottom: 2px solid var(--kiosk-header-border-color);
    height: 60px;
    padding: 1.6rem 0.8rem 1.6rem 1.6rem;

    @media ${mediaQuery.isPhone} {
        padding: var(--spacing-12);
    }

    @media ${mediaQuery.isTablet} {
        padding: 2rem;
    }

    .secondary-button {
        background-color: ${({ isTwoToneTheme }) => (isTwoToneTheme ? 'var(--kiosk-header-button-bg)' : '')};
        border: ${({ isTwoToneTheme }) => (isTwoToneTheme ? 'var(--kiosk-header-button-border)' : '')};
    }

    .header-info {
        display: flex;
        align-items: center;
        max-width: 1920px;
        width: 100%;
        margin: 0 auto;
        > *:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .header-controls {
        display: flex;
        align-items: center;

        margin-left: auto;

        > *:not(:last-child) {
            margin-right: 2rem;
        }
    }
    .header-logo {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    }

    &.header-${ClientName.FONTAINEBLEAU} {
        @media ${mediaQuery.isPhone} {
            .header-logo img {
                height: 1.4rem;
            }
        }
    }

    &.header-${ClientName.IVC} {
        .header-logo img {
            height: 3.375rem;
        }
        .menu-item {
            color: #d3e3f0;
            &.active {
                color: #89f1fa;
            }
        }
    }
`;

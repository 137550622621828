import some from 'lodash/some';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import {
    MATCH_STATUS,
    initialBetSlipUserState,
    maxComboSelectionsByClient,
    initialBetSlipPlacingState,
    initialComboCardBetslipState,
} from '../constants';
import { isMobile } from '../../browser';
import { getClient } from '../../environment';
import { storageSet, storageGet } from '../../storage';
import type { MultiBetslipState, MultiParlayState } from '../types';
import { BetslipMode } from '../types';
import zipObject from 'lodash/zipObject';
import { getMarketInfoByOutcomeId } from '../../../microservices/sbgate';
import { logger } from '../../logger';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { LocalStorage } from '../../local-storage/types';

function removeSelection(marketId: string) {
    stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
        delete state[marketId];
    });
}

function removeSelections(betbuilder?: boolean) {
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    Object.values(marketInfoById).forEach(
        (marketInfo) =>
            (betbuilder ? marketInfo.view_type === 'bet_builder' : marketInfo.view_type !== 'bet_builder') &&
            removeSelection(marketInfo.id.toString()),
    );
}

function hasLiveMatchesInBetslip() {
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    return some(
        betSlipMarketIdToOutcomeId,
        (_, marketId) => marketInfoById[marketId] && marketInfoById[marketId].match_status === MATCH_STATUS.LIVE,
    );
}

function getMaxComboSelections() {
    const client = getClient();
    return maxComboSelectionsByClient[client] || maxComboSelectionsByClient.default;
}

function clearBetslipSelection(
    betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard | BetslipMode.ComboCard,
    navigatePreviousPage = true,
    clearBetslipCollection = false,
    { comboCardId }: { comboCardId?: number } = {},
) {
    const clearSelection = {
        [BetslipMode.Betslip]: () => {
            stores.sports.betSlipUserState.set(initialBetSlipUserState);
            storageSet('betSlipSettings', {
                betSlipMarketIdToOutcomeId: {},
                updatedAt: new Date().getTime(),
            });
            stores.sports.betSlipMarketIdToOutcomeId.set({});
            stores.sports.betSlipComboCardMarketIdToOutcomeId.set({});
            stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId.set({});
            stores.sports.customBetbuilderV2.selectedOutcomesByFixtureId.set({});
            stores.sports.betSlipErrorByMarketId.set({});
            stores.sports.teaserSelectedPoint.set(undefined);
            storageSet(LocalStorage.BETBUIDLER_BETSLIP_ID_BY_MARKET_ID, {});
            if (clearBetslipCollection) {
                stores.sports.betslipCollection.set([]);
                stores.sports.betSlipPosition.set(0);
            }

            const comboCardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);
            if (isMobile() && navigatePreviousPage && isEmpty(comboCardsInBetslip)) {
                stores.isBetslipOpen.set(false);
            }
        },
        [BetslipMode.ParlayCard]: () => {
            storageSet('parlayCardSettings', {
                betSlipMarketIdToOutcomeId: {},
                updatedAt: new Date().getTime(),
            });
            stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set({});
            stores.sports.parlayCard.betSlipErrors.set([]);
            stores.sports.parlayCard.stake.set(0);
            if (clearBetslipCollection) {
                stores.sports.parlayCard.parlayBetslipCollection.set([]);
                stores.sports.parlayCard.betslipPosition.set(0);
            }
            if (isMobile() && navigatePreviousPage) {
                stores.isParlayBetslipOpen.set(false);
            }
        },
        [BetslipMode.ComboCard]: () => {
            if (!comboCardId) {
                clearAllComboCardBetslipContent();
                return;
            }

            const cardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);

            const cardToRemove = cardsInBetslip.find(({ id }) => id === comboCardId)!;
            const marketIds = cardToRemove.matches.flatMap(({ markets }) => markets.map(({ id }) => id));

            stores.sports.betSlipComboCardMarketIdToOutcomeId.set((prevState) => {
                const stateCopy = { ...prevState };
                marketIds.forEach((id) => delete stateCopy[id]);
                return stateCopy;
            });

            const updatedCards = cardsInBetslip.filter(({ id }) => id !== comboCardId);

            const willComboCardBetslipBeEmpty = Boolean(!updatedCards.length);
            if (willComboCardBetslipBeEmpty) {
                clearAllComboCardBetslipContent();
                return;
            }

            stores.sports.comboCard.ineligibleCardIds.set((prevState) => prevState.filter((id) => id !== comboCardId));
            stores.sports.comboCard.cardsInBetslip.set(updatedCards);
            stores.sports.comboCard.stakeByCardId.set((prevState) => omit(prevState, comboCardId));
            stores.sports.comboCard.betslipErrorsByCardId.set((prevState) => omit(prevState, comboCardId));
            stores.sports.comboCard.receiptByCardId.set((prevState) => omit(prevState, comboCardId));
            stores.sports.comboCard.isComboCardBetslipOpen.set(true);
        },
    };
    clearSelection[betslipType]();
    stores.sports.bonusBetsSelection.set({});
    stores.sports.qrCode.set('');
}

async function copyTicketToBetslipByOutcomeIds(outcomeIds, ticketId, bettingContext?) {
    try {
        const markets: { id: string }[] = await Promise.all(
            outcomeIds.map((outcomeId) => getMarketInfoByOutcomeId(outcomeId)),
        );
        const marketIds = markets.map((market) => market.id);
        const groupedMarketIdToOutcomeId = zipObject(marketIds, outcomeIds.map(Number));
        stores.sports.betSlipMarketIdToOutcomeId.set((state) => ({ ...state, ...groupedMarketIdToOutcomeId }));
        stores.sports.betSlipUserState.set((state) => ({ ...state, copiedFrom: ticketId }));
        if (bettingContext) {
            const groupedOutcomeIdToBettingContext = zipObject(
                outcomeIds.map(Number),
                outcomeIds.map(() => bettingContext),
            );
            stores.sports.bettingContextByOutcomeId.set((state) => ({ ...state, ...groupedOutcomeIdToBettingContext }));
        }
    } catch (error) {
        logger.error('SportsBetslipActionsService', 'copyTicketToBetslipByOutcomeIds', error);
    }
}

async function copyComboCardTicketOutcomeIdsToBetslip(outcomeIds: number[], code: string) {
    try {
        const markets: { id: number }[] = await Promise.all(
            outcomeIds.map((outcomeId) => getMarketInfoByOutcomeId(outcomeId)),
        );
        const marketIds = markets.map(({ id }) => id);
        const groupedMarketIdToOutcomeId = zipObject(marketIds, outcomeIds);
        stores.sports.betSlipComboCardMarketIdToOutcomeId.set((state) => ({ ...state, ...groupedMarketIdToOutcomeId }));
        stores.sports.comboCard.betslipState.set((state) => ({ ...state, betslipCode: code }));
    } catch (error) {
        logger.error('SportsBetslipActionsService', 'copyComboCardTicketOutcomeIdsToBetslip', error);
    }
}

function isBetSlipLoadingMarkets() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    return some(betSlipMarketIdToOutcomeId, (outcomeId, marketId) => !marketInfoById[marketId]);
}

function getCurrentBetslipState() {
    const betslipState: MultiBetslipState = {
        betslipUserState: getStoreValue(stores.sports.betSlipUserState),
        betslipSettings: storageGet('betslipSettings'),
        betSlipMarketIdToOutcomeId: getStoreValue(stores.sports.betSlipMarketIdToOutcomeId),
        betSlipErrorByMarketId: getStoreValue(stores.sports.betSlipErrorByMarketId),
        code: getStoreValue(stores.sports.qrCode),
        teaserSelectedPoint: getStoreValue(stores.sports.teaserSelectedPoint),
        betbuilderBetslipIdByMarketId: storageGet(LocalStorage.BETBUIDLER_BETSLIP_ID_BY_MARKET_ID),
    };
    return betslipState;
}

function getCurrentParlayBetslip() {
    const betslipState: MultiParlayState = {
        marketInfo: getStoreValue(stores.sports.parlayCard.marketInfo),
        betSlipMarketIdToOutcomeIds: getStoreValue(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds),
        stake: getStoreValue(stores.sports.parlayCard.stake),
        betSlipPlacingState: getStoreValue(stores.sports.parlayCard.betSlipPlacingState),
        betSlipErrors: getStoreValue(stores.sports.parlayCard.betSlipErrors),
        manualAcceptanceStake: getStoreValue(stores.sports.parlayCard.manualAcceptanceStake),
        code: getStoreValue(stores.sports.qrCode),
    };
    return betslipState;
}

function storeAndClearSelection(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const { betslipState, betslipCollection, betslipPosition: position } = getBetslipData(betslipType);
    betslipCollection[position] = betslipState;
    const betslip = initialBetslip(betslipType);
    betslipCollection[betslipCollection.length] = betslip;
    if (betslipType === BetslipMode.Betslip) {
        stores.sports.betslipCollection.set(betslipCollection as MultiBetslipState[]);
        stores.sports.betSlipPosition.set(betslipCollection.length - 1);
    } else {
        stores.sports.parlayCard.parlayBetslipCollection.set(betslipCollection as MultiParlayState[]);
        stores.sports.parlayCard.betslipPosition.set(betslipCollection.length - 1);
    }
    clearBetslipSelection(betslipType);
}

function getBetslipData(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const data = {
        [BetslipMode.Betslip]: {
            betslipState: getCurrentBetslipState(),
            betslipCollection: [...getStoreValue(stores.sports.betslipCollection)],
            betslipPosition: getStoreValue(stores.sports.betSlipPosition),
        },
        [BetslipMode.ParlayCard]: {
            betslipState: getCurrentParlayBetslip(),
            betslipCollection: [...getStoreValue(stores.sports.parlayCard.parlayBetslipCollection)],
            betslipPosition: getStoreValue(stores.sports.parlayCard.betslipPosition),
        },
    };
    return data[betslipType];
}

function initialBetslip(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const betslipMap = {
        [BetslipMode.Betslip]: {
            betslipUserState: initialBetSlipUserState,
            betslipSettings: {
                betSlipMarketIdToOutcomeId: {},
                updatedAt: new Date().getTime(),
            },
            betSlipMarketIdToOutcomeId: {},
            betSlipErrorByMarketId: {},
            teaserSelectedPoint: undefined,
            betbuilderBetslipIdByMarketId: {},
        },
        [BetslipMode.ParlayCard]: {
            marketInfo: {},
            betSlipMarketIdToOutcomeIds: {},
            stake: 0,
            betSlipPlacingState: initialBetSlipPlacingState,
            betSlipErrors: [],
            manualAcceptanceStake: 0,
        },
    };

    return betslipMap[betslipType];
}

function deleteBetSlip(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const { betslipCollection, betslipPosition } = getBetslipData(betslipType);
    betslipCollection.splice(betslipPosition, 1);
    const position = betslipPosition - 1 < 0 ? betslipPosition : betslipPosition - 1;
    const betslipState = betslipCollection[position] || initialBetslip(betslipType);
    betslipType === BetslipMode.Betslip
        ? updateBetslipStore(betslipState as MultiBetslipState, position, betslipCollection as MultiBetslipState[])
        : updateParlayBetslipStore(betslipState as MultiParlayState, position, betslipCollection as MultiParlayState[]);
}

function navigateToBetslip(position: number, betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const { betslipCollection, betslipPosition, betslipState: currentBetslipState } = getBetslipData(betslipType);
    betslipCollection[betslipPosition] = currentBetslipState;
    if (position < 0 || position >= betslipCollection.length) {
        return;
    }
    const betSlipState = betslipCollection[position] || {};
    betslipType === BetslipMode.Betslip
        ? updateBetslipStore(betSlipState as MultiBetslipState, position, betslipCollection as MultiBetslipState[])
        : updateParlayBetslipStore(betSlipState as MultiParlayState, position, betslipCollection as MultiParlayState[]);
}

function updateParlayBetslipStore(
    betSlipState: MultiParlayState,
    position: number,
    betslipCollection: MultiParlayState[],
) {
    const {
        marketInfo,
        betSlipMarketIdToOutcomeIds,
        stake,
        betSlipPlacingState,
        betSlipErrors,
        manualAcceptanceStake,
        code,
    } = betSlipState;
    stores.sports.parlayCard.betSlipPlacingState.set(betSlipPlacingState || initialBetSlipPlacingState);
    storageSet('parlayCardSettings', {
        betSlipMarketIdToOutcomeIds: betSlipMarketIdToOutcomeIds || {},
        updatedAt: new Date().getTime(),
    });
    stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set(betSlipMarketIdToOutcomeIds || {});
    stores.sports.parlayCard.betSlipErrors.set(betSlipErrors);
    stores.sports.parlayCard.marketInfo.set(marketInfo);
    stores.sports.parlayCard.stake.set(stake);
    stores.sports.parlayCard.manualAcceptanceStake.set(manualAcceptanceStake);
    stores.sports.parlayCard.betslipPosition.set(position);
    stores.sports.parlayCard.parlayBetslipCollection.set(betslipCollection);
    stores.sports.qrCode.set(code || Math.random().toString().substring(2, 8));
}

function updateBetslipStore(betSlipState: MultiBetslipState, position: number, betslipCollection: MultiBetslipState[]) {
    const {
        betslipUserState,
        betslipSettings,
        betSlipMarketIdToOutcomeId,
        betSlipErrorByMarketId,
        code,
        teaserSelectedPoint,
        betbuilderBetslipIdByMarketId,
    } = betSlipState;
    stores.sports.teaserSelectedPoint.set(teaserSelectedPoint);
    storageSet(LocalStorage.BETBUIDLER_BETSLIP_ID_BY_MARKET_ID, betbuilderBetslipIdByMarketId || {});
    stores.sports.betSlipUserState.set(betslipUserState || initialBetSlipUserState);
    storageSet('betSlipSettings', {
        betSlipMarketIdToOutcomeId: betslipSettings?.betSlipMarketIdToOutcomeId || {},
        updatedAt: new Date().getTime(),
    });
    stores.sports.betSlipPosition.set(position);
    stores.sports.betSlipMarketIdToOutcomeId.set(betSlipMarketIdToOutcomeId || {});
    stores.sports.betSlipErrorByMarketId.set(betSlipErrorByMarketId || {});
    stores.sports.betslipCollection.set(betslipCollection);
    stores.sports.qrCode.set(code || Math.random().toString().substring(2, 8));
}

function clearBetslip() {
    stores.sports.betSlipMarketIdToOutcomeId.set({});
    stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId.set({});
}

function clearAllComboCardBetslipContent() {
    stores.sports.comboCard.isComboCardBetslipOpen.set(false);
    stores.sports.comboCard.cardsInBetslip.set([]);
    stores.sports.comboCard.stakeByCardId.set({});
    stores.sports.comboCard.betslipErrorsByCardId.set({});
    stores.sports.comboCard.receiptByCardId.set({});
    stores.sports.comboCard.betslipState.set(initialComboCardBetslipState);
    stores.sports.betSlipComboCardMarketIdToOutcomeId.set({});

    const isRegularBetslipEmpty = isEmpty(getStoreValue(stores.sports.betSlipMarketIdToOutcomeId));
    if (isMobile() && isRegularBetslipEmpty) {
        stores.isBetslipOpen.set(false);
    }
}

export {
    removeSelections,
    hasLiveMatchesInBetslip,
    getMaxComboSelections,
    clearBetslipSelection,
    copyTicketToBetslipByOutcomeIds,
    copyComboCardTicketOutcomeIdsToBetslip,
    isBetSlipLoadingMarkets,
    storeAndClearSelection,
    deleteBetSlip,
    navigateToBetslip,
    clearBetslip,
    clearAllComboCardBetslipContent,
};

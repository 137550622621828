import round from 'lodash/round';
import { stringify } from 'query-string';
import { toast } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import { depositMoney, getMyCardCount, getPaymentProviders } from '../../microservices/payments';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { isFeatureAvailable } from '../feature';
import { LocalStorage } from '../local-storage/types';
import { logger } from '../logger';
import { getLastActiveProductRoute, getRoute } from '../router';
import { translate } from '../translate';
import { FEATURE } from '../types';
import { isTestUser } from '../user';
import {
    DepositStatus,
    ExistingPaymentMethod,
    PaymentProvider,
    PROVIDER_TYPE,
    PROVIDERS,
    QUICK_DEPOSIT_REDIRECT_STATUS,
} from './types';

export type Card = ExistingPaymentMethod & {
    id: string;
    card_number: string;
    description: string;
    masked_pan: string;
    number: string;
    payer_name: string;
    cardholder: string;
    expiration_date: string;
    expiration_month: string;
    expiration_year: string;
    expiry: string;
    expiry_date: string;
    expirationDate: string;
};

export const FAILED_DEPOSIT_TYPES = {
    BANK_NOT_SUPPORTED: 'Bank not supported',
    CARD_EXPIRED: 'Card expired',
    CARD_NOT_SUPPORTED: 'Card country/currency not supported',
    DEPOSIT_LIMIT_REACHED: 'Deposit limit reached',
    FAILED_BY_PROVIDER: 'Payment failed by provider',
    INCOMPLETE_PAYMENT: 'Customer did not complete the payment',
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    INVALID_DETAILS_ENTERED: 'Invalid details entered',
    MAX_TRANASACTIONS_PER_DAY_REACHED: 'Max transactions per day reached',
    PROVIDER_NETWORK_ERROR: 'Provider network error, contact provider',
    TECHNICAL_ERROR: 'Technical error, contact Payments IT',
    TOO_MANY_CONSECUTIVE_ATTEMPTS: 'Too many consecutive attempts',
    UNMAPPED_ERROR: 'Unmapped error',
    USER_BLACKLISTED: 'User listed',
};

export const PAYMENT_ERROR_MESSAGE = {
    CARD_EXPIRED: 'Your card has expired',
    DECLINED: 'Your payment was declined',
    FAILED_TO_PROCESS: 'Failed to process payment',
    FAILED_TO_VERIFY_CARD: 'Failed to verify card',
} as const;

export type PaymentErrorMessage = typeof PAYMENT_ERROR_MESSAGE[keyof typeof PAYMENT_ERROR_MESSAGE];

export const PROVIDER_UNAVAILABLE_MESSAGE = {
    PROVIDER_DOWN: "Provider's service seems to be down. Please try again later!",
    TOO_MANY_ATTEMPTS: 'Payment failed, you have made too many requests. Please try again later!',
};

export const LOGOS = {
    SEB: '/assets/images/payments/SEB.svg',
    SWEDBANK: '/assets/images/payments/Swedbank.png',
};

export const CHILE_BANKS = {
    '504': 'Banco BBVA Chile',
    '028': 'Banco Bice',
    '055': 'Banco Consorcio',
    '001': 'Banco de Chile',
    '016': 'Banco de Crédito e Inversiones',
    '507': 'Banco del Desarrollo',
    '012': 'Banco del Estado de Chile',
    '051': 'Banco Falabella',
    '009': 'Banco Internacional',
    '039': 'Banco Itaú',
    '053': 'Banco Ripley',
    '037': 'Banco Santander Chile',
    '027': 'Itaú Corpbanca',
    '014': 'Scotiabank Chile',
};

export const ECUADOR_BANKS = {
    '0001': 'Banco Central del Ecuador',
    '0010': 'Banco Pichincha C.A.',
    '0017': 'Banco de Guayaquil S.A',
    '0024': 'Banco City Bank',
    '0025': 'Banco Machala',
    '0029': 'Banco de Loja',
    '0030': 'Banco del Pacifico',
    '0032': 'Banco Internacional',
    '0034': 'Banco Amazonas',
    '0035': 'Banco del Austro',
    '0036': 'Produbanco / Promerica',
    '0037': 'Banco Bolivariano',
    '0039': 'Comercial de Manabi',
    '0042': 'Banco General Ruminahui S.A.',
    '0043': 'Banco del Litoral S.A.',
    '0059': 'Banco Solidario',
    '0060': 'Banco Procredit S.A.',
    '0061': 'Banco Capital',
    '0065': 'Banco Desarrollo de Los Pueblos S.A.',
    '0066': 'Banecuador B.P.',
    '0201': 'Banco Delbank S.A.',
};

export const PERU_SHARED_BANKS = [
    '002',
    '003',
    '007',
    '009',
    '011',
    '018',
    '023',
    '035',
    '038',
    '043',
    '049',
    '053',
    '054',
    '056',
    '800',
    '801',
    '802',
    '803',
    '805',
    '806',
    '808',
];

export const ECUADOR_BANK_CODES = [
    {
        value: 1,
        label: 'BANCO CENTRAL DEL ECUADOR',
    },
    {
        value: 2,
        label: 'FOMENTO',
    },
    {
        value: 10,
        label: 'BANCO PICHINCHA',
    },
    {
        value: 17,
        label: 'BANCO DE GUAYAQUIL',
    },
    {
        value: 19,
        label: 'TERRITORIAL',
    },
    {
        value: 20,
        label: 'LLOYDS BANK',
    },
    {
        value: 24,
        label: 'CITIBANK',
    },
    {
        value: 25,
        label: 'BANCO MACHALA',
    },
    {
        value: 26,
        label: 'UNIBANCO',
    },
    {
        value: 29,
        label: 'BANCO DE LOJA',
    },
    {
        value: 30,
        label: 'BANCO DEL PACIFICO',
    },
    {
        value: 32,
        label: 'BANCO INTERNACIONAL',
    },
    {
        value: 34,
        label: 'BANCO AMAZONAS',
    },
    {
        value: 35,
        label: 'BANCO DEL AUSTRO',
    },
    {
        value: 36,
        label: 'PRODUBANCO y BANCO PROMERICA',
    },
    {
        value: 37,
        label: 'BANCO BOLIVARIANO',
    },
    {
        value: 39,
        label: 'BANCO COMERCIAL DE MANABI',
    },
    {
        value: 42,
        label: 'BANCO GENERAL RUMINAHUI',
    },
    {
        value: 43,
        label: 'BANCO DEL LITORAL',
    },
    {
        value: 45,
        label: 'SUDAMERICANO',
    },
    {
        value: 50,
        label: 'COFIEC',
    },
    {
        value: 59,
        label: 'BANCO SOLIDARIO',
    },
    {
        value: 60,
        label: 'BANCO PROCREDIT',
    },
    {
        value: 62,
        label: 'BANCO CAPITAL S.A. CORFINSA',
    },
    {
        value: 85,
        label: 'MUTUALISTA IMBABURA',
    },
    {
        value: 86,
        label: 'MUTUALISTA PICHINCHA',
    },
    {
        value: 87,
        label: 'MUTUALISTA AMBATO',
    },
    {
        value: 88,
        label: 'MUTUALISTA AZUAY',
    },
    {
        value: 140,
        label: 'ECUATORIANO DE LA VIVIENDA',
    },
    {
        value: 9964,
        label: 'FINANCIERA FINANCOOP',
    },
    {
        value: 9965,
        label: 'COOP. AHORRO Y CREDITO PROGRESO',
    },
    {
        value: 9966,
        label: 'DELBANK S.A.',
    },
    {
        value: 9967,
        label: 'DINERS CLUB',
    },
    {
        value: 9968,
        label: 'PACIFICARD',
    },
    {
        value: 9969,
        label: 'COOP. TULCAN',
    },
    {
        value: 9970,
        label: 'COOP. PABLO MUNOZ VEGA',
    },
    {
        value: 9971,
        label: 'COOP. CALCETA LTDA.',
    },
    {
        value: 9972,
        label: 'COOP. CAMARA DE COMERCIO QUITO',
    },
    {
        value: 9974,
        label: 'COOP. JUVENTUD ECUATORIANA PROGRESISTA LTDA.',
    },
    {
        value: 9975,
        label: 'COOP. AHO Y CREDITO 23 DE JULIO',
    },
    {
        value: 9976,
        label: 'COOP. AHO Y CREDITO SANTA ANA',
    },
    {
        value: 9977,
        label: 'COOP. ALIANZA DEL VALLE LTDA.',
    },
    {
        value: 9978,
        label: 'COOP AHO Y CREDITO DESARROLLO PUEBLOS',
    },
    {
        value: 9979,
        label: 'COOP. RIOBAMBA',
    },
    {
        value: 9980,
        label: 'COOP. COMERCIO LTDA PORTOVIEJO',
    },
    {
        value: 9981,
        label: 'COOP. CHONE LTDA.',
    },
    {
        value: 9982,
        label: 'COOP. CACPECO',
    },
    {
        value: 9983,
        label: 'COOP. ATUNTAQUI',
    },
    {
        value: 9984,
        label: 'COOP. GUARANDA',
    },
    {
        value: 9985,
        label: 'COOP AHO Y CREDITO SANTA ROSA',
    },
    {
        value: 9986,
        label: 'COOP. AHO Y CREDITO EL SAGRARIO',
    },
    {
        value: 9987,
        label: 'COOP. OSCUS',
    },
    {
        value: 9988,
        label: 'COOP. LA DOLOROSA',
    },
    {
        value: 9989,
        label: 'COOP AHO Y CREDITO  MANUEL GODOY',
    },
    {
        value: 9990,
        label: 'COOP. AHO Y CREDITO NACIONAL',
    },
    {
        value: 9991,
        label: 'COOP AHO Y CREDITO SAN JOSE',
    },
    {
        value: 9993,
        label: 'COOP. AHO Y CREDITO JARDIN AZUAYO',
    },
    {
        value: 9994,
        label: 'COOP. COTOCOLLAO',
    },
    {
        value: 9995,
        label: 'COOP. 29 DE OCTUBRE',
    },
    {
        value: 9997,
        label: 'COOP. PEQ. EMPRESA DE PASTAZA',
    },
    {
        value: 9998,
        label: 'COOP. ANDALUCIA',
    },
    {
        value: 9999,
        label: 'COOP. PREVISION AHORRO Y DESARROLLO',
    },
];

export const CHILE_BANK_CODES = [
    {
        value: 1,
        label: 'Banco de Chile',
    },
    {
        value: 9,
        label: 'Banco Internacional',
    },
    {
        value: 12,
        label: 'Banco del Estado de Chile',
    },
    {
        value: 14,
        label: 'Scotiabank Chile',
    },
    {
        value: 16,
        label: 'Banco Crédito e Inversiones',
    },
    {
        value: 28,
        label: 'Banco Bice',
    },
    {
        value: 31,
        label: 'HSBC Bank',
    },
    {
        value: 37,
        label: 'Banco Santander- Santiago',
    },
    {
        value: 39,
        label: 'Banco Itaú',
    },
    {
        value: 49,
        label: 'Banco Security',
    },
    {
        value: 51,
        label: 'Banco Falabella',
    },
    {
        value: 53,
        label: 'Banco Ripley',
    },
    {
        value: 55,
        label: 'Consorcio',
    },
    {
        value: 116,
        label: 'BCI (Mach)',
    },
    {
        value: 504,
        label: 'BBVA Chile',
    },
    {
        value: 507,
        label: 'Banco del Desarrollo',
    },
    {
        value: 672,
        label: 'Coopeuch',
    },
    {
        value: 730,
        label: 'Tenpo',
    },
    {
        value: 729,
        label: 'Prepago los Héroes',
    },
    {
        value: 874,
        label: 'MercadoPago',
    },
];

export const cryptoProviders = [PROVIDERS.PAYHOUND_BITCOIN, PROVIDERS.PAYHOUND_USDC, PROVIDERS.PAYHOUND_USDT];

export const cryptoCurrencyByProvider = {
    [PROVIDERS.PAYHOUND_BITCOIN]: 'BTC',
    [PROVIDERS.PAYHOUND_USDT]: 'USDT',
    [PROVIDERS.PAYHOUND_USDC]: 'USDC',
};

export function cleanCardNumber(number: string): string {
    const regex = new RegExp(`[^0-9]+`, 'g');
    return String(number).replace(regex, '');
}

export function cleanExpirationDate(expirationDate: string): string {
    const regex = new RegExp(`[^0-9/]+`, 'g');
    return String(expirationDate).replace(regex, '');
}

export function depositWithProvider(params: {
    amount: number;
    deviceHash: string;
    methodId?: string;
    provider: string;
    providerParams?: any;
}) {
    const language = getStoreValue(stores.language);
    return depositMoney({ ...params, language });
}

export function fiatToCrypto(amount: number, providerCurrencyRate: number, provider: PROVIDERS) {
    const precision = provider === PROVIDERS.PAYHOUND_BITCOIN ? 8 : 2;
    return round(amount / providerCurrencyRate, precision);
}

export function formatCardNumber(number: string, options?: { clean?: boolean }): string {
    const clean = options?.clean;
    if (isApplePayAccountNumber(number)) {
        return number;
    }
    return clean ? cleanCardNumber(number) : number;
}

export function formatExpirationDate(expirationDate: string): string {
    return `${expirationDate.slice(0, 3)}${expirationDate.slice(5)}`;
}

export function expirationDateTo6Digits(expirationDate: string) {
    return `${expirationDate.slice(0, 3)}20${expirationDate.slice(3)}`;
}

export function validateExpirationDate(expirationDate: string): [boolean, string] {
    const [expirationMonth = '', expirationYear = ''] = expirationDate.split('/');
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    if (
        !expirationMonth ||
        !expirationYear ||
        !expirationMonth.match(/^\d{2}$/) ||
        !expirationYear.match(/^\d{4}$/) ||
        parseInt(expirationMonth) > 12
    ) {
        return [false, 'Invalid expiration date'];
    }
    if (
        parseInt(expirationYear) < currentYear ||
        (parseInt(expirationYear) === currentYear && parseInt(expirationMonth) < currentMonth)
    ) {
        return [false, 'Card has expired'];
    }
    return [true, ''];
}

export function getPaymentProviderImage(fileName) {
    const language = getStoreValue(stores.language);
    return fileName.replace('{{language}}', language);
}

export function getPaymentMethodImage(cardNumber = '') {
    if (cardNumber.startsWith('4')) {
        return `/assets/images/payments/visa-logo.svg`;
    }
    if (cardNumber.startsWith('5')) {
        return `/assets/images/payments/mastercard.svg`;
    }
    if (isApplePayAccountNumber(cardNumber)) {
        return '/assets/images/payments/applepay.svg';
    }
    return `/assets/images/payments/bank.png`;
}

export async function getIsMaxCardsReached({ raiseLimitBy = 0 }) {
    const { current, limit } = await getMyCardCount();
    if (!limit && limit !== 0) {
        return false;
    }
    return current >= limit + raiseLimitBy;
}

export function getDepositReturnRoute(status: DepositStatus, snippetKey?: string) {
    if (snippetKey) {
        return `${getRoute('deposit-return')}/${status}?snippetKey=${snippetKey}`;
    }
    return `${getRoute('deposit-return')}/${status}`;
}

function isApplePayAccountNumber(number: string) {
    return number.toLowerCase().startsWith('apple');
}

export const PAYMENTS_ERROR_CODE = {
    RETRY_WITH_FALLBACK_PROVIDER: 4612,
};

export function redirectDepositTo(state: DepositStatus, snippetKey?: string) {
    const route = isFeatureAvailable(FEATURE.PAYMENT_PACKAGES) ? getRoute('purchase-packages') : getRoute('deposit');
    switch (state) {
        case DepositStatus.AUTO_REFUNDED_3RD_PERSON_METHOD:
        case DepositStatus.AUTO_REFUNDED_NAME_MISMATCH:
        case DepositStatus.CANCELLED:
        case DepositStatus.FAILED: {
            return `${route}?${stringify({
                snippetKey:
                    {
                        [DepositStatus.AUTO_REFUNDED_3RD_PERSON_METHOD]:
                            'deposit-auto-refunded-3rd-person-method-pop-up',
                        [DepositStatus.AUTO_REFUNDED_NAME_MISMATCH]: 'deposit-auto-refunded-name-mismatch-pop-up',
                    }[state] ?? snippetKey,
            })}`;
        }
        case DepositStatus.COMPLETED:
        case DepositStatus.PENDING: {
            return getLastActiveProductRoute();
        }
    }
}

export async function getQuickDepositProviders() {
    let providers: PaymentProvider[] = [];
    if (isFeatureAvailable(FEATURE.QUICK_DEPOSIT) && isTestUser()) {
        try {
            providers = (
                await getPaymentProviders({
                    type: PROVIDER_TYPE.DEPOSIT,
                    quickDeposit: false,
                })
            ).providers;
        } catch (error) {
            logger.error('PaymentsPaymentsService', 'getQuickDepositProviders', error);
        }
    }
    return providers;
}

export function updateOrClearQuickDepositStatus(status?: DepositStatus | typeof QUICK_DEPOSIT_REDIRECT_STATUS) {
    if (status) {
        localStorage.setItem(LocalStorage.QUICK_DEPOSIT_STATUS, status);
    } else {
        localStorage.removeItem(LocalStorage.QUICK_DEPOSIT_STATUS);
    }
}

export function showDepositResultMessage(state: DepositStatus) {
    const resultMessage = {
        [DepositStatus.COMPLETED]: translate('Your deposit was successful.', 'ui.account'),
        [DepositStatus.FAILED]: translate('Your deposit did not go through.', 'ui.account'),
        [DepositStatus.CANCELLED]: translate('Your deposit was cancelled.', 'ui.account'),
        [DepositStatus.PENDING]: translate('Your deposit is pending.', 'ui.account'),
    }[state];
    const toastMethod: TypeOptions = {
        [DepositStatus.COMPLETED]: 'success',
        [DepositStatus.FAILED]: 'error',
        [DepositStatus.CANCELLED]: 'warning',
        [DepositStatus.PENDING]: 'info',
    }[state];

    resultMessage && toastMethod && toast[toastMethod](resultMessage);
}

export function onDepositReturnRedirect(statusOnRedirect: DepositStatus) {
    const currentQuickDepositStatus = localStorage.getItem(LocalStorage.QUICK_DEPOSIT_STATUS);
    const isQuickDepositInProgress =
        isFeatureAvailable(FEATURE.QUICK_DEPOSIT) && currentQuickDepositStatus === QUICK_DEPOSIT_REDIRECT_STATUS;
    if (isQuickDepositInProgress) {
        updateOrClearQuickDepositStatus(statusOnRedirect);
        window.close();
    } else {
        showDepositResultMessage(statusOnRedirect);
    }
}

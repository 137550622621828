import { OddsStatus } from '@staycool/odds-types';
import { RefObject } from 'react';
import { Country } from '@staycool/location';
import { Language } from '../language/types';
import { SportsUserSettings } from '../types';
import { DatabaseStreamInfo, MarketStatus, MarketViewType, OutcomeStatus, Serialize } from '@staycool/sports-types';
import {
    BetSlipMinimalMarket,
    FoCategoryMarketType,
    FoCategoryMatch,
    FoSportCategoryWithMatches,
    SportSearchCategory,
    SportSearchMatch,
} from '@staycool/sbgate-types';
import { SportsLayout } from '../layout/types';
import { Currency } from '../wallet/types';
import { FOParlayCardMarket, GetParlayCardFOResponse, ParlayCardFO } from '@staycool/sports-types/parlay-card';

export type Odds = {
    odds_id: string;
    value: number;
    outcome_id: number;
    market_id: number;
    status: OddsStatus;
};

export type OddsByOutcomeIdStore = { [outcomeId: number]: Odds | undefined };

interface CategoryMatchMarketOutcome {
    id: number;
    status: 'OPEN';
    result_key: string;
    name: string;
    has_trading_position: boolean;
    global_id?: string;
}

export type CategoryMatchMarket = FoCategoryMatch['markets'][number];

export interface CmsGeneralSetting {
    id: number;
    key: string;
    value: string;
    created_at?: Date;
    updated_at?: Date;
    updated_by: string;
    type: string;
}

export type GroupedMarketType = {
    id: number;
    market_types: FoCategoryMarketType[];
};

export type MarketTypeCategory = {
    id: number;
    view_type: MarketViewType;
    sport_id: number;
    name: string;
    shortName?: string;
};

export type SportCategoryWithMatches = FoSportCategoryWithMatches;

export interface FavouriteCategoryWithMatches {
    matches: CategoryMatch[];
    top_market_types_by_league: GroupedMarketType[];
    has_more: boolean;
    open_match_id?: number[];
}

export type CategoryMatch = Serialize<FoCategoryMatch> & { isFake?: boolean; category?: number };

export type DatabaseSequence =
    | {
          sequence: number;
      }
    | {
          set: number;
          game: number;
          point?: number;
      };

export interface FoSidebetsMarketGroupMarket {
    translatedName: string;
    id: number;
    name: string;
    line: string;
    raw_line: number;
    status: 'OPEN' | 'SUSPENDED';
    singles_only: boolean;
    market_type_id: number;
    team_names: string[];
    player_names: string[];
    sequence: DatabaseSequence;
    outcomes_order_by: 'odds' | 'outcomeName' | 'outcomeCreated' | null;
    provider: number;
    is_betbuilder_eligible: boolean;
    outcomes: CategoryMatchMarketOutcome[];
}

export interface FoSidebetsMarketGroup {
    market_type_id: number;
    translatedName: string;
    sequence: DatabaseSequence;
    view_type: MarketViewType;
    result_keys: string[];
    fo_groups: number[];
    placeholder_count: number;
    outcome_names?: string[];
    uniqueSequences: { key: string; translation: string }[];
    markets: FoSidebetsMarketGroupMarket[];
    isChunked?: boolean;
}

interface SidebetMatch {
    sport_category_id: number;
    home_team_name?: string;
    away_team_name?: string;
    match_start: Date;
}

export interface SidebetMarketsResponse {
    markets: FoSidebetsMarketGroup[];
    match: SidebetMatch;
    foGroups: FoGroup[];
}

export type StreamInfo = Pick<DatabaseStreamInfo, 'provider' | 'url'>;

export enum BET_TYPE {
    SINGLE = 'single',
    COMBO = 'combo',
    SYSTEM = 'system',
    TEASER = 'teaser',
    BETBUILDER = 'betbuilder',
    PARLAY_CARD = 'parlayCard',
    COMBO_CARD = 'comboCard',
}

export type BetSlipUserState = {
    acceptAnyOddsChanges?: boolean;
    betType: BET_TYPE;
    copiedFrom: string | null;
    disableInputs?: boolean;
    disableSystemIndividualUpdate?: boolean;
    disableSystemSingles?: boolean;
    MAStakeByMarketId: Record<string, string> | Readonly<Record<string, unknown>>;
    MASystemStakes: Record<string, string> | Readonly<Record<string, unknown>>;
    parentSystemStakes: { withSingles: string; noSingles: string };
    stakeByMarketId: Record<string, string> | Readonly<Record<string, unknown>>;
    systemStakes: Record<string, string> | Readonly<Record<string, unknown>>;
    userBetTypeSelection: BET_TYPE | undefined;
    betslipCode: string | undefined;
};

export type BetSlipPlacingState = {
    isConfirmed: boolean;
    isLoading: boolean;
    needsConfirm: boolean;
    receiptById: Record<string, string>;
    needsConfirmDuplicate?: boolean;
};

type Bet = {
    created_at?: Date;
    id: string;
    initial_stake_bc: number;
    initial_stake: number;
    is_ma: boolean;
    selections?: Selection[];
    stake_bc: number;
    stake: number;
    status: BetStatusEnum;
    ticket_id: string;
    transaction_id?: string;
    updated_at?: Date;
};

enum BetStatusEnum {
    WON = 'WON',
    LOST = 'LOST',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
    MANUAL_ACCEPTANCE = 'MANUAL_ACCEPTANCE',
    MANUAL_ACCEPTANCE_DENIED = 'MANUAL_ACCEPTANCE_DENIED',
}

type BetWithOutcomeIds = Bet & { outcome_ids: number[] };

type ExtraInfo = {
    campaignId?: number;
    freeBet?: string;
    riskFreeBet?: string;
    multiplierBet?: string;
    brand?: string;
};

type SelectionStatus =
    | SelectionStatusesEnum.CANCELLED
    | SelectionStatusesEnum.LOST
    | SelectionStatusesEnum.MANUALLY_CANCELLED
    | SelectionStatusesEnum.PENDING
    | SelectionStatusesEnum.MANUAL_ACCEPTANCE
    | SelectionStatusesEnum.WON
    | SelectionStatusesEnum.MANUAL_ACCEPTANCE_DENIED;

enum SelectionStatusesEnum {
    MANUAL_ACCEPTANCE = 'MANUAL_ACCEPTANCE',
    MANUAL_ACCEPTANCE_DENIED = 'MANUAL_ACCEPTANCE_DENIED',
    CANCELLED = 'CANCELLED',
    WON = 'WON',
    LOST = 'LOST',
    PENDING = 'PENDING',
    MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
}

export enum TicketType {
    SINGLE = 'single',
    COMBO = 'combo',
    SYSTEM = 'system',
    TEASER = 'teaser',
    PARLAY_CARD = 'parlayCard',
    COMBO_CARD = 'comboCard',
}

export type UniqueSelection = {
    away_team: string;
    cashout_status?: string;
    category_id: number;
    display_line: string;
    display_odds: number;
    extra?: ExtraInfo;
    home_team: string;
    league_name: string;
    leagueName?: string;
    line?: number;
    market_id: number;
    market_name: string;
    market_sequence?: { [key: string]: number };
    market_type_id: number;
    marketTypeName: string;
    match_id: number;
    match_name: string;
    match_start: Date;
    odds: string;
    outcome_id: number;
    outcome_name: string;
    player_names: string[];
    product: ProductTypeEnum;
    region_name: string;
    score_away: null | number;
    score_home?: null | number;
    sport_category_id: number;
    sport_name: string;
    sportId?: number;
    status: SelectionStatus;
    team_names?: string[];
    view_type: string;
    teaser_points: number;
    expected_result_date?: Date;
    sport_icon: string | null;
    is_betbuilder_selection: boolean;
};

enum ProductTypeEnum {
    LIVE = 'LIVE',
    PREMATCH = 'PREMATCH',
}

export interface TicketDetails {
    bets: BetWithOutcomeIds[];
    selectionByOutcomeId: {
        [key: number]: UniqueSelection;
    };
    systemsMeta?: SystemsMeta;
    ticket: Ticket;
    uniqueSelections: UniqueSelection[];
    userRiskSettings?: UserRiskSettings;
}

type BetWithMaBet = BetWithOutcomeIds & { maBet: BetWithOutcomeIds };

export type SystemsMeta = {
    [k in SystemBetType]: {
        bets: BetWithMaBet[];
        totalStake: number;
    };
};

export type SystemBet = {
    bets: BetWithMaBet[];
    totalStake: number;
    systemType: SystemBetType;
};

enum SystemBetType {
    DOUBLE = 'DOUBLE',
    EIGHTFOLD = 'EIGHTFOLD',
    FIVEFOLD = 'FIVEFOLD',
    FOURFOLD = 'FOURFOLD',
    NINEFOLD = 'NINEFOLD',
    SEVENFOLD = 'SEVENFOLD',
    SINGLE = 'SINGLE',
    SIXFOLD = 'SIXFOLD',
    TENFOLD = 'TENFOLD',
    TREBLE = 'TREBLE',
}

type UserRiskSettings = {
    bo_user_id?: number;
    cashout_disabled?: boolean;
    country_code: string;
    disabled_manual_acceptance?: boolean;
    display_cashout_disabled?: boolean;
    display_disabled_manual_acceptance?: boolean;
    display_limit_ratio?: number;
    id: number;
    limit_ratio?: number;
    livebet_grace_time?: number;
    risk_group_id: number;
    risk_group_name?: string;
    updated_at?: Date;
    user_id: string;
    user_limit_ratio?: number;
};

type MatchType = 'OUTRIGHT' | null;

export type FakeMarket = { id: number; outcomes: number[] };

export type MarketInfo = {
    away_team_name?: string;
    betting_end?: Date;
    category_id: number;
    home_team_name?: string;
    id: number;
    in_play: boolean;
    market_type_id: number;
    name: string; // added in sbgate
    match_id: number;
    match_name: string;
    match_status: 'OPEN' | 'LIVE';
    match_type: MatchType;
    player_names: any;
    outcomes: {
        id: number;
        status: OutcomeStatus;
        name: string;
        result_key: string;
        market_id: number;
    }[];
    raw_line: number;
    singles_only: boolean;
    sport_category_id: number;
    sport_id: number;
    status: MarketStatus;
    view_type: MarketViewType;
    same_match_combo_allowed: boolean;
    provider: number;
    expected_result_time?: Date;
    teasers_enabled?: boolean;
    sameMatchTeaserAllowed?: boolean;
    marketTypeName?: string;
};

export type Ticket = {
    sportName: string;
    marketTypeName: string;
    matchName: string;
    marketName: string;
    outcomeName: string;
    total_max_win: number;
    banker_outcome_ids: number[];
    bets: Bet[];
    bonusAmount: number;
    cashout_amount?: number;
    cashout_enabled: boolean;
    cashout_status?: string;
    cashoutAmount: number;
    cashoutEnabled: boolean;
    created_at: Date;
    currency: string;
    display_id: number;
    extra?: ExtraInfo;
    first_bet_odds: number;
    first_match: FirstMatch;
    id: string;
    is_match_live: boolean;
    ma_bets: Bet[];
    ma_bo_user_id?: number;
    ma_decided_at?: string;
    ma_stake_requested: number;
    ma_status?: string;
    max_win: number;
    num_bets_lost: number;
    num_bets_pending: number;
    num_bets_total: number;
    num_bets_won: number;
    potential_return: number;
    risk_group_name?: string;
    sport_id: number;
    sportId: number;
    status: SelectionStatus;
    ticket_type: TicketType;
    total_matches: number;
    total_stake: number;
    transaction_id: string;
    updated_at: Date;
    user_id: number;
    user_limit_ratio: number;
    product?: string;
    odds_format?: string;
    expected_result_date?: Date;
    parlay_card_name?: string;
    w2g_events?: TicketW2gEvent[] | null;
    has_betbuilder_selection: boolean;
};

interface TicketW2gEvent {
    bet_id: string;
    threshold_reached: string;
    status: string;
}

export type FirstMatch = {
    categoryName?: string;
    marketTypeName?: string;
    league_name: string;
    market_id: number;
    market_name: string;
    match_name: string;
    outcome_name: string;
    region_name: string;
    sport_category_id: number;
    sport_name: string;
    sport_icon: string | null;
};

export type Turnover = {
    id: string;
    bets: number;
    share: number;
    stake: number;
    sum: number;
};

export type Campaign = {
    id: number;
    name: string;
    active: boolean;
    deleted: boolean;
    bet_min_odds: number;
    bet_amounts: BetAmounts;
    sport_requirements: SportRequirements;
    start_date: Date | null;
    end_date: Date | null;
    recurrence: string | null;
    repeat_requirement: number | null;
    start_day: number;
    daily_start_time: string;
    daily_end_time: string;
    length_days: null | number;
    recurring_amount_field: 'max_win' | 'stake';
    related_campaign_id: null | number;
    created_at: Date;
    updated_at: Date;
    segment_id: string;
    bo_user_id: number;
    is_first_day: boolean;
    previous_day_bet: CampaignBet;
    info_link: InfoLink;
    public_availability: PublicAvailability;
    bet_period: 'day' | 'week' | 'minute' | 'hour' | 'month';
    require_previous_bet: boolean;
    limits_override: boolean;
    article_id: string;
    min_odd_per_selection: number | null;
    max_selection_lost: number | null;
    max_bet_per_period: number | null;
    duration_time?: any;
    country_daily_start_time?: {
        default: string;
        [country: string]: string;
    };
};

interface CampaignBet {
    bet_stats: BetStats;
    bo_user_id: number;
    campaign_id: number;
    currency: string;
    id: number;
    initial_bet_stake: string | null;
    match_ids: number[] | string[];
    odds: string;
    stake: number | null;
    ticket_id: string;
    created_at: Date | string;
    updated_at: Date | string | undefined;
    user_id: string;
    valid: boolean;
    won: boolean | null | undefined;
    won_amount: number | null;
}

interface BetStats {
    id: string;
    bets: Bet[];
    totalOdds: number;
    created_at: Date;
    display_id: number;
    marketsById: Record<string, Market>;
    matchesById: Record<string, Match>;
    num_bets_ma: number;
    systemsMeta: any;
    ticket_type: string;
    total_stake: number;
    num_bets_won: number;
    outcomesById: Record<string, Outcome>;
    num_bets_lost: number;
    num_bets_total: number;
    selectionsMeta: SectionMeta[];
    num_bets_pending: number;
    potential_return: number;
    banker_outcome_ids: any;
    ma_stake_requested: any;
}

interface Market {
    id: number;
    line: number;
    name: string;
    status: string;
    in_play: boolean;
    highRisk: boolean;
    match_id: number;
    view_type: string;
    betting_end: any;
    singles_only: boolean;
    parlayEnabled: boolean;
    market_type_id: number;
    teasersEnabled: boolean;
    excludedCountries: string[];
    paperParlayEnabled: boolean;
    sameMatchComboAllowed: boolean;
}

interface Outcome {
    id: number;
    name: string;
    status: string;
    team_id: any;
    limitUser: number;
    market_id: number;
    limitTotal: number;
    result_key: string;
    excludedCountries: never[];
}
interface SectionMeta {
    odds: number;
    outcome_id: number;
}
interface Match {
    id: number;
    name: string;
    status: string;
    matchType: any;
    bettingEnd: string;
    livebetOur: boolean;
    matchStart: string;
    awayTeamName: string;
    bettingStart: string;
    homeTeamName: string;
    preferHalfLines: boolean;
    sportCategoryId: number;
    leagueCategoryId: number;
    regionCategoryId: number;
    excludedCountries: string[];
    expectedResultTime: string;
    livebetGracePeriod: number;
    matchLivebetGracePeriod: any;
    categoryLivebetGracePeriod: number;
}

type SportRequirements = {
    product: Product;
    bet_type: string;
    category_ids: number[];
    allow_outrights: boolean;
    market_type_ids: number[];
    sport_category_ids: number[];
    next_day_match_result_time: number;
    match_ids?: number[];
    selections?: any;
    override_next_day_match_result_time?: boolean;
};

enum Product {
    PREMATCH = 'PREMATCH',
    LIVEBET = 'LIVEBET',
    BOTH = 'BOTH',
}

type BetAmounts = {
    [currency: string]: BetAmount;
};

type InfoLink = {
    [language: string]: string;
};

type PublicAvailability = {
    [language: string]: boolean;
};

export type BetAmount = {
    min: number;
    max: number;
    default: number;
};

// eslint-disable-next-line import/no-unused-modules
export type LeaderBoard = {
    created_at: string;
    currency: Currency;
    start_bet_amount: number;
    user_alias: string;
    won_amount: number;
    ranking: number;
    total_odds: number;
    bets_by_period: any;
};

export type LeaderBoardSports = {
    amount: number;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    regionName: string;
    sportsbook: {
        awayTeamName: string;
        categoryId: number;
        categoryNames: Record<string, string>;
        fixtureName: string;
        homeTeamName: string;
        marketName: string;
        marketTypeNames: Record<string, string>;
        outcomeIds: number[];
        outcomeName: string;
        potentialReturn: number;
        product: string;
        sportId: number;
        sportNames: Record<string, string>;
        ticketId: string;
        ticketType: string;
        totalOdds: number;
    };
};

export type SearchResult = SportSearchCategory | SportSearchMatch;

export type SportMatchLayoutComponentParams = {
    match: CategoryMatch;
    matchStatus?: string;
};

export interface SportMatchLayoutHeadParams extends SportMatchLayoutComponentParams {
    matchHeader: RefObject<HTMLDivElement> | null;
}

type MinimalExtraMatchInfoForOdds = {
    betting_end: Date;
    status?: string;
    sport_category?: number;
};
export type SportMatchSidebetMarketProps = {
    marketType: FoSidebetsMarketGroup;
    match: Serialize<MinimalExtraMatchInfoForOdds> & {
        home_team_name?: string;
        away_team_name?: string;
        prefer_half_lines: boolean;
    };
    collapsed?: boolean;
    isBetbuilderMarkets?: boolean;
};

export type FoGroup = { id: number; name: string; position?: number };

export type BetbuilderInfo = {
    allBetBuilderSportCategoryIds: number[];
    category: {
        market_types: MarketTypeCategory[];
        matches: CategoryMatch[];
    };
};

export type LightweightCategoryData = {
    id: number;
    name: string;
    fullSlug: string;
    sportCategoryId: number;
    icon: string | null;
    depth: number;
    sportIcon: string | null;
};

export interface ComboGeneratorMarketInfo extends BetSlipMinimalMarket {
    outcome: BetSlipMinimalMarket['outcomes'][number];
}

export type BoostedEventsParams = {
    language: Language;
    country: Country;
    layout: SportsLayout;
    limit: number;
    offset: number;
};

export interface FeaturedMatchesResponse {
    matches: CategoryMatch[];
    top_market_types_by_league: GroupedMarketType[];
    has_more: boolean;
    sportCategoryIds: number[];
}

export interface TeaserValidationMatchMarketResponse {
    matches: CategoryMatch[];
}

export interface BetSlipReplayState {
    betSlipMarketIdToOutcomeId: { [marketId: number]: number };
    betType: BET_TYPE;
}

export enum ScoreboardType {
    bgRounds = 'bgRounds',
    bgIcehockey = 'bgIcehockey',
    bgAmericanFootball = 'bgAmericanFootball',
    betgenius = 'betgenius',
    inhouse = 'inhouse',
    bgFootball = 'bgFootball',
    bgDarts = 'bgDarts',
    bgBaseball = 'bgBaseball',
    bgTennis = 'bgTennis',
    bgBasketball = 'bgBasketball',
}

export type LocalSportsUserSettings = Omit<SportsUserSettings, 'user_id'>;
export interface MultiParlayState {
    marketInfo: Record<number, FOParlayCardMarket>;
    betSlipMarketIdToOutcomeIds: Record<number, number[]>;
    stake: number;
    betSlipPlacingState: BetSlipPlacingState;
    betSlipErrors: any[];
    manualAcceptanceStake: number;
    code?: string;
}

export interface MultiBetslipState {
    betslipUserState: BetSlipUserState;
    betslipSettings: Record<string, any>;
    betSlipMarketIdToOutcomeId: { [marketId: number]: number };
    betSlipErrorByMarketId: { [marketId: number]: number[] };
    code?: string;
    teaserSelectedPoint?: number;
    betbuilderBetslipIdByMarketId?: Record<number, string>;
}

export enum BetslipMode {
    History = 'HISTORY',
    Betslip = 'BETSLIP',
    Cashout = 'CASHOUT',
    ParlayCard = 'PARLAY_CARD',
    ComboCard = 'COMBO_CARD',
}

export interface SportEventTypeObject {
    SportEventType: number[];
}

export type ParlayCard = ParlayCardFO;
export type ParlayCardMarket = FOParlayCardMarket;
export type ParlayCardWithMarket = GetParlayCardFOResponse;
export type ParlayCardMarketOutcome = ParlayCardMarket['outcomes'][0];

export type PreMatchTree = {
    active: boolean;
    children?: PreMatchTreeChildren[];
    depth: number;
    fullSlug: string;
    id: number;
    name: string;
    slug: string;
};

export type PreMatchTreeChildren = {
    active: boolean;
    children?: PreMatchTreeChildren[];
    countryName: string;
    country_code: string;
    depth: number;
    fullSlug: string;
    id: number;
    icon: string | null;
    name: string;
    ordering: Record<string, number>;
    slug: string;
    sportName: string;
    sport_category_id: number;
    is_sports_wrapper?: boolean;
};

export type PlaceBetHandle = (
    isManualAcceptance?: boolean,
    allowErrorForMarket?: boolean,
    isForceDuplicate?: boolean,
) => Promise<boolean | void>;

export type PlaceBetRequest = (
    marketId: string,
    isManualAcceptance: boolean,
    isForceDuplicate: boolean,
) => Promise<string>;

export type PlaceComboCardBetRequest = (cardId: number, isForceDuplicate: boolean) => Promise<string>;

export const ClientName = {
    COOLBET: 'coolbet',
    DEMO: 'demo',
    LOCKER01: 'l1',
    IVC: 'ivc',
    WYNNBET: 'wynnbet',
    WYNNBET_NEVADA: 'wynnbet-nv',
    STATION: 'station',
    FONTAINEBLEAU: 'fontainebleau-nv',
    SOARING_EAGLE: 'soaring-eagle',
    SOARING_EAGLE_MI: 'soaring-eagle-mi',
    OAKLAWN: 'oaklawn',
} as const;

export const ClientTheme = {
    COOLBET: 'coolbet',
    IVC: 'ivc',
    WYNNBET: 'wynnbet',
    STATION: 'station',
    FONTAINEBLEAU: 'fountainblue',
    SOARING_EAGLE: 'soaringEagle',
    OAKLAWN: 'oaklawn',
} as const;

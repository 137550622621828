import { Country, Licence } from '@staycool/location';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getLicence } from './licence';
import { isMobileApp } from './mobile-app';
import { getUserCountry } from './users/country';
import { FEATURE } from './types';
import { isTestUser } from './user';

const disabledCountriesByFeature: Partial<Record<FEATURE, Country[]>> = {
    [FEATURE.FAILED_TRANSACTION_REASONS]: [Country.CANADA, Country.FINLAND, Country.ICELAND],
    [FEATURE.GENIUS_PLAYER]: [Country.CANADA],
    [FEATURE.POKER]: [Country.MEXICO],
    [FEATURE.VIRTUAL_SPORTS]: [Country.PERU],
    [FEATURE.REFER_A_FRIEND]: [Country.NORWAY, Country.SWEDEN],
    [FEATURE.RTP_CALCULATOR]: [Country.CHILE, Country.ECUADOR, Country.MEXICO, Country.PERU],
    [FEATURE.SAFER_WITH_COOLBET]: [Country.FINLAND],
    [FEATURE.WELCOME_BONUS_LIST_POKER]: [Country.MEXICO, Country.PERU],
};

const disabledLicencesByFeature: Partial<Record<FEATURE, Licence[]>> = {
    [FEATURE.BETBUILDER]: [Licence.SWEDEN],
    [FEATURE.CASINO_PAYBACK]: [Licence.SWEDEN],
    [FEATURE.CASINO_RACE]: [Licence.SWEDEN],
    [FEATURE.CASINO_RULES]: [
        Licence.ESTONIA,
        Licence.MALTA,
        Licence.MEXICO,
        Licence.PERU,
        Licence.SIMULATED_GAMING,
        Licence.SWEDEN,
    ],
    [FEATURE.COOLBET_LEAGUE]: [Licence.SWEDEN],
    [FEATURE.DEVICE_FINGERPRINT_SEON]: [Licence.SWEDEN],
    [FEATURE.FAILED_TRANSACTION_REASONS]: [
        Licence.ESTONIA,
        Licence.SIMULATED_GAMING,
        Licence.SWEDEN,
        Licence.USA_ARIZONA,
        Licence.USA_ARKANSAS,
        Licence.USA_MASSACHUSETTS,
        Licence.USA_MICHIGAN,
        Licence.USA_MISSISSIPPI,
        Licence.USA_NEVADA,
        Licence.USA_NEW_YORK,
    ],
    [FEATURE.GEOCOMPLY]: [
        Licence.ESTONIA,
        Licence.MALTA,
        Licence.MEXICO,
        Licence.PERU,
        Licence.SIMULATED_GAMING,
        Licence.SWEDEN,
    ],
    [FEATURE.LEAGUE]: [Licence.SWEDEN],
    [FEATURE.POKER_MARKETING_ONLY]: [
        Licence.ESTONIA,
        Licence.MALTA,
        Licence.PERU,
        Licence.SIMULATED_GAMING,
        Licence.SWEDEN,
        Licence.USA_ARIZONA,
        Licence.USA_ARKANSAS,
        Licence.USA_MASSACHUSETTS,
        Licence.USA_MICHIGAN,
        Licence.USA_MISSISSIPPI,
        Licence.USA_NEVADA,
        Licence.USA_NEW_YORK,
    ],
    [FEATURE.REFER_A_FRIEND]: [Licence.SWEDEN],
    [FEATURE.RESPONSIBLE_GAMING_LOGIN_DURATION_LIMIT]: [Licence.ESTONIA],
    [FEATURE.RESPONSIBLE_GAMING_SESSION_REMINDER]: [Licence.ESTONIA],
    [FEATURE.RESPONSIBLE_GAMING_UNTIL_FURTHER_NOTICE_EXCLUSION]: [
        Licence.ESTONIA,
        Licence.MEXICO,
        Licence.PERU,
        Licence.SIMULATED_GAMING,
        Licence.USA_ARIZONA,
        Licence.USA_ARKANSAS,
        Licence.USA_MASSACHUSETTS,
        Licence.USA_MICHIGAN,
        Licence.USA_MISSISSIPPI,
        Licence.USA_NEVADA,
        Licence.USA_NEW_YORK,
    ],
    [FEATURE.RTP_CALCULATOR]: [Licence.MEXICO],
    [FEATURE.SIMPLE_COMBO]: [Licence.SWEDEN],
    [FEATURE.WELCOME_BONUS_LIST_POKER]: [Licence.SWEDEN],
    [FEATURE.VIP_CLUB]: [Licence.SWEDEN],
};

function isEnabled(feature: FEATURE) {
    const features = getStoreValue(stores.features);

    return (
        features.includes(feature) &&
        !disabledLicencesByFeature[feature]?.includes(getLicence()) &&
        !disabledCountriesByFeature[feature]?.includes(getUserCountry() as Country)
    );
}

export function isFeatureAvailable(feature: FEATURE) {
    if (feature === FEATURE.POKER && isTestUser() && getLicence() === Licence.PERU) {
        return true;
    }

    if (isMobileApp()) {
        return ![FEATURE.GEOCOMPLY].includes(feature) && isEnabled(feature);
    }

    return isEnabled(feature);
}

export function isFeatureIPBlocked(feature: FEATURE) {
    return Boolean(getStoreValue(stores.blockedProducts)[feature]);
}

export function isGeniusStreamAvailable() {
    const ipCountry = getStoreValue(stores.ipCountry) as Country;
    return !(disabledCountriesByFeature[FEATURE.GENIUS_PLAYER] || []).includes(ipCountry);
}

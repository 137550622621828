import { getStoreValue } from '../../stores/store/utils';
import { stores } from '../../stores';
import { getPaybackAvailableProgram } from '../../microservices/payback/casino-payback';
import { logger } from '../logger';
import { isFeatureAvailable } from '../feature';
import { FEATURE } from '../types';
import { casinoPaybackStore } from '../../stores/casino-payback';
import { CasinoGame, CasinoGameLauncher } from './types';
import { casino } from '../../stores/casino';

export async function loadCasinoPaybackProgram() {
    try {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        let casinoPayback = getStoreValue(casinoPaybackStore.casinoPayback);

        if (isAuthenticated && isFeatureAvailable(FEATURE.CASINO_PAYBACK)) {
            if (casinoPayback && new Date(casinoPayback.expiresAt) < new Date()) {
                casinoPayback = null;
            } else {
                casinoPayback = await getPaybackAvailableProgram();
            }
            casinoPaybackStore.casinoPayback.set(casinoPayback);
            if (casinoPayback?.gameIds) {
                casinoPaybackStore.games.set(getPaybackGames(casinoPayback.gameIds, getStoreValue(casino.gamesById)));
            }
        }
    } catch (error) {
        logger.error('CasinoCasinoPaybackService', 'loadCasinoPaybackProgram', error);
    }
}

function getPaybackGames(gameIds: number[], gamesById: { [key: number]: CasinoGame }) {
    return gameIds.map((gameId) => gamesById[gameId]).filter(Boolean) ?? [];
}

export function isGameInPayback(
    gameIds: number[],
    gameLauncher: CasinoGameLauncher,
    gamesByServerId: { [key: number]: CasinoGame },
) {
    return gameLauncher?.serverGameId && gameIds.includes(gamesByServerId[gameLauncher.serverGameId]?.id);
}

import isEqual from 'lodash/isEqual';
import { getLiveLobbyMetaData } from '../../microservices/casino-live-lobby';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { getActiveCurrency } from '../currency';
import { mapCasinoCategories } from './categories';
import { loadFilteredCasinoGames, mapCasinoGames } from './games';
import { mapCasinoProviders } from './providers';
import { CasinoGame, CasinoGameMetaData, CasinoGameType, CasinoProviders, LiveGameMetaData } from './types';

export function manageLiveLobbyUpdates(payload: LiveGameMetaData[]) {
    updateLiveGameMetaData(payload);
    const oldLiveClosedGames = getStoreValue(casino.closedGames);
    setClosedLiveGames(payload);
    const newLiveClosedGames = getStoreValue(casino.closedGames);
    if (!isEqual(oldLiveClosedGames, newLiveClosedGames)) {
        loadFilteredCasinoGames();
        mapCasinoGames();
        mapCasinoCategories();
        mapCasinoProviders();
    }
}

export async function setInitialLiveGameMetaData() {
    manageLiveLobbyUpdates(await getLiveLobbyMetaData());
}

const LIVE_LOBBY_PROVIDERS = [
    CasinoProviders.EVOLUTION,
    CasinoProviders.PRAGMATIC_PLAY,
    CasinoProviders.MICROGAMING,
    CasinoProviders.STAKELOGIC,
];

function getLiveGameById(gameId: string): CasinoGame | null {
    const allGamesByProviderAndServerId = getStoreValue(casino.allGamesByProviderAndServerId);
    const allGamesByExternalId = getStoreValue(casino.allGamesByExternalId);
    let game: CasinoGame | null = null;
    LIVE_LOBBY_PROVIDERS.every((providerId) => {
        if (allGamesByProviderAndServerId[providerId]?.[gameId]) {
            game = allGamesByProviderAndServerId[providerId][gameId];
            return false;
        }
        return true;
    });

    if (!game && allGamesByExternalId[gameId]) {
        game = allGamesByExternalId[gameId];
    }

    return game;
}

function setClosedLiveGames(liveGames: LiveGameMetaData[]): void {
    const incomingClosedGames = {};
    liveGames.forEach((liveGame) => {
        const game = getLiveGameById(liveGame.id);
        if (
            game &&
            (!liveGame.open ||
                (game?.type === CasinoGameType.BLACKJACK && !liveGame.betBehind && liveGame.availableSeats === 0))
        ) {
            incomingClosedGames[game.id] = game;
        }
    });

    casino.closedGames.set(incomingClosedGames);
}

function updateLiveGameMetaData(liveGames: LiveGameMetaData[]): void {
    const gamesMetaDataById: Record<number, CasinoGameMetaData> = getStoreValue(casino.gamesMetaDataById);
    const updatedGamesMetaDataById: Record<number, CasinoGameMetaData> = {};
    liveGames.forEach((liveGame) => {
        const game = getLiveGameById(liveGame.id);
        if (game) {
            const betLimits = liveGame.betLimits?.find((betLimit) => betLimit.currency === getActiveCurrency());
            let seatsTaken: number[] | undefined = undefined;
            let results: string[] = [];
            if (game.type === CasinoGameType.BLACKJACK) {
                seatsTaken = liveGame?.seatsTaken;
            }
            if (
                game.type &&
                (
                    [
                        CasinoGameType.ROULETTE,
                        CasinoGameType.BACCARAT,
                        CasinoGameType.GAME_SHOW,
                        CasinoGameType.CRASH,
                    ] as string[]
                ).includes(game.type)
            ) {
                results = liveGame?.results;
            }
            const metaData: CasinoGameMetaData = {
                ...gamesMetaDataById[game.id],
                availableSeats: liveGame.availableSeats,
                seatedPlayers: liveGame.seatedPlayers,
                results,
                seatsTaken,
                betBehind: liveGame.betBehind,
                dealerName: liveGame.dealerName,
                imageUrl: liveGame.imageUrl,
            };

            if (betLimits) {
                metaData.minBet = betLimits.min;
                metaData.maxBet = betLimits.max;
            }

            if (game.id) {
                updatedGamesMetaDataById[game.id] = metaData;
            }
        }
    });
    updateGameMetaData(updatedGamesMetaDataById);
}

function updateGameMetaData(newMetaData: Record<number, CasinoGameMetaData>): void {
    const oldAllMetaDataGamesById = getStoreValue(casino.gamesMetaDataById);
    const newAllMetaDataGamesById = { ...oldAllMetaDataGamesById, ...newMetaData };
    if (!isEqual(oldAllMetaDataGamesById, newAllMetaDataGamesById)) {
        casino.gamesMetaDataById.set(newAllMetaDataGamesById);
    }
}

import { stores } from '../../stores';
import { environment } from '../../stores/environment/environment';
import { geocomply } from '../../stores/geocomply';
import { getStoreValue } from '../../stores/store/utils';
import { getDecodedToken } from '../token';
import { GEOCOMPLY_REASON, GeocomplyMobileSettings } from './types';

export function getGeocomplyMobileSettings(reason: GEOCOMPLY_REASON): GeocomplyMobileSettings {
    const user = getStoreValue(stores.user);
    const verificationData = getStoreValue(stores.verification.data);
    const token = getStoreValue(stores.token);
    const license = getStoreValue(geocomply.license);
    return {
        serviceUrl: getStoreValue(environment)?.GEOCOMPLY?.MOBILE_OOBEE_SERVICE_URL ?? '',
        oobeeUrl: getStoreValue(environment)?.GEOCOMPLY?.MOBILE_OOBEE_URL ?? '',
        license: license.license ?? '',
        userId: verificationData.userId ?? user?.id ?? '',
        reason,
        customFields: {
            sessionKey: token ? getDecodedToken(token).login_session_id : '',
        },
    };
}

import React, { useEffect, useRef, useState } from 'react';
import { getSportsbookIframe } from '../../../microservices/casino';
import Wrapper from './styles';
import { getDeviceCategory } from '../../../services/device';
import { getUserCountry } from '../../../services/users/country';
import { getUserProvince } from '../../../services/user';
import { getLicence } from '../../../services/licence';
import { getStoreValue } from '../../../stores/store/utils';
import { stores } from '../../../stores';
import { Currency } from '../../../services/wallet/types';
import { useStore } from '../../../hooks/useStore';
import UiDotsLoader from '../../../components/ui/dots-loader/UiDotsLoader';
import { environment } from '../../../stores/environment/environment';
import { getDeviceUuid } from '../../../services/fingerprint';
import { verifyGeoLocation } from '../../../services/geocomply/geocomply';
import { useSecondaryEffect } from '../../../services/hooks';
import { requestLogin } from '../../../services/auth';

export default function SportExternalPage() {
    const [iframeUrl, setIframeUrl] = useState<string>();
    const [iframeParams, setIframeParams] = useState<URLSearchParams>();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [language] = useStore(stores.language);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ SPORTSBOOK_GAME_VARIANT_ID }] = useStore(environment);

    function eventHandler(event: MessageEvent) {
        if (event.data === 'loginRequested') {
            requestLogin();
        }
    }

    function getPayload() {
        return {
            lobbyUrl: '',
            gameVariantId: SPORTSBOOK_GAME_VARIANT_ID,
            platform: getDeviceCategory(),
            country: getUserCountry(),
            province: getUserProvince(),
            licence: getLicence(),
            deviceUuid: getDeviceUuid(),
            language,
            gameLaunchPosition: '',
            userCurrency: getStoreValue(stores.wallet)?.currency,
            gamePlayCurrency: Currency.USD,
        };
    }

    useEffect(() => {
        window.addEventListener('message', eventHandler);

        getSportsbookIframe({ payload: getPayload(), isAuthenticated }).then((url) => {
            setIframeUrl(url);
        });

        return () => window.removeEventListener('message', eventHandler);
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            verifyGeoLocation('CASINO').then(() => {
                getSportsbookIframe({ payload: getPayload(), isAuthenticated }).then((url) => {
                    url && setIframeParams(new URL(url).searchParams);
                });
            });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (iframeParams) {
            iframeRef.current?.contentWindow?.postMessage(
                {
                    name: 'loginCompleted',
                    payload: Object.fromEntries(iframeParams),
                },
                '*',
            );
        }
    }, [iframeParams]);

    useSecondaryEffect(() => {
        if (!isAuthenticated && iframeRef.current) {
            iframeRef.current.contentWindow?.postMessage(
                {
                    name: 'logoutCompleted',
                    payload: {
                        playMode: '',
                        loginToken: '',
                        language,
                        currency: getStoreValue(stores.wallet)?.currency,
                        lobbyUrl: '',
                    },
                },
                '*',
            );

            setIframeParams(undefined);
        }
    }, [isAuthenticated]);

    return iframeUrl ? <Wrapper frameBorder="0" ref={iframeRef} src={iframeUrl} /> : <UiDotsLoader />;
}

import { ObjectValues } from '../ts-utils';
import { Country } from '@staycool/location';
import { Currency } from '../wallet/types';

export type GameLaunchPosition = {
    launchSource: GameLaunchSource;
    categoryLobbyPosition?: number;
    gameRowPosition?: number;
};

export enum CasinoCategoryCode {
    PROVIDERS_LOBBY = 'PROVIDERS_LOBBY',
    FEATURED_GAMES = 'FEATURED_GAMES',
    NEW_GAMES = 'NEW_GAMES',
    RECENTLY_PLAYED = 'RECENTLY_PLAYED',
    CASINO_PAYBACK = 'CASINO_PAYBACK',
    TOP_20 = 'TOP_20',
    ALL = 'ALL',
    RACE = 'RACE',
    POPULAR_GAMES = 'POPULAR_GAMES',
    LIVE_CASINO = 'LIVE_CASINO',
    REAL_DEALER = 'REAL_DEALER',
    TOP_WINS_DAILY = 'TOP_WINS_DAILY',
    MINI_CATEGORY = 'MINI_CATEGORY',
    DROP_WIN = 'DROP_WIN',
    FAVORITE_GAMES = 'FAVORITE_GAMES',
    JACKPOT = 'JACKPOT',
    CASINO_LOBBY = 'CASINO_LOBBY',
}

export interface CasinoCategory {
    id: number;
    name: string;
    code: CasinoCategoryCode;
    order: number;
    type: CasinoCategoryTypes;
    games: number[];
    filters: Filter[];
    isCustom?: boolean;
    iconUrl?: string;
    studioOrder: StudioOrder[];
}

export interface StudioOrder {
    name: string;
    id: number;
    position?: number;
}

export interface CasinoFeature {
    id: number;
    name: string;
    showInCasino: boolean;
    showInLiveCasino: boolean;
}

export interface CasinoFilter {
    id?: string;
    name: string;
    filter: CasinoFilterProperties;
}

export interface CasinoFilterProperties {
    studios: number[];
    types: number[];
    rtps: number[];
    maxWinMultipliers: number[];
    volatilities: string[];
    features: number[];
    minBets: number[];
    operatingLanguages: number[];
    jackpots: string[];
    gameTypes?: number[];
    themes?: number[];
    releaseDateLast30Days: string[];
    releaseDateCurrentYear: string[];
}

export interface Filter {
    type: string;
    id?: number;
    name: string | null;
}

export interface Option {
    label: string;
    value: string;
}

export enum CasinoCategoryTypes {
    CASINO = 'casino',
    LIVE = 'live',
    MINI = 'mini',
}

export enum CasinoCustomCategoryIds {
    RECENTLY_PLAYED = -1,
    CASINO_RACE = -3,
    CASINO_PAYBACK = -4,
    PROVIDERS_LOBBY = -5,
    FAVORITE_GAMES = -6,
}

export type GameLaunchSource =
    | 'UNDEFINED'
    | 'DIRECT_LAUNCH'
    | 'FILTER'
    | 'PROVIDER'
    | 'INGAME_SEARCH'
    | 'LOBBY_SEARCH'
    | 'CASINO_RACE_LOBBY'
    | 'CASINO_RACE_INGAME'
    | 'GAME_INFO_MODAL'
    | 'BIG_TILE'
    | 'INGAME_LOBBY'
    | 'MY_CASINO'
    | 'RTP_CALCULATOR'
    | 'MOBILE_LANDING'
    | CasinoCategoryCode;

export interface GameType {
    id: number;
    typeCom: string;
    typeDk: string;
}

export enum CasinoProviders {
    PLAYGO = 1,
    YGGDRASIL = 2,
    MICROGAMING = 3,
    EVOLUTION = 4,
    // NETENT_LIVE = 5,
    // NETENT = 6,
    HUB88 = 7,
    ELK_STUDIOS = 8,
    PRAGMATIC_PLAY = 9,
    ISOFTBET = 10,
    RELAXGAMING = 11,
    PUSHGAMING = 12,
    NOLIMIT_CITY = 13,
    QUICKSPIN = 14,
    SPINOMENAL = 15,
    HACKSAW = 16,
    SUPERRGS = 17,
    STAKELOGIC = 18,
    LIGHTNWONDER = 19,
    PLAYTECH = 20,
    GAMES_GLOBAL = 21,
}

export enum RACE_TYPE {
    HIGHEST_WIN_MULTIPLIER = 'HIGHEST_WIN_MULTIPLIER',
    SLOT_TOURNAMENT = 'SLOT_TOURNAMENT',
}

export enum AWARD_TYPE {
    CUSTOM = 'custom',
    FREE_SPINS = 'free_spins',
    FREE_MONEY = 'free_money',
}

export interface CasinoRace {
    id: number;
    startDate: string;
    endDate: string;
    name: string;
    cmsTemplate: string;
    minBetAmount: number | null;
    prizePoolDisplay: string;
    isMainRace: boolean;
    status: string;
    awards: CasinoRaceAward[];
    games: string[];
    gameIds: number[];
    showMostWon: boolean;
    buyInAmount: number | null;
    totalFreeMoneyAmount: number;
    totalFreeSpinsAmount: number;
    type: RACE_TYPE;
    leaderboard?: CasinoRaceScore[];
    backgroundImageName?: string;
    score?: CasinoRaceScore;
    imageName?: string;
    skip?: string;
    timestamp?: string;
    joined?: boolean;
    raceSettings?: { minBet?: { [keys: string]: number } };
}

export interface CasinoRaceHistory extends CasinoRace {
    leaderboard: CasinoRaceScore[];
}

export interface CasinoRaceScore {
    country?: string;
    device?: string;
    gameId?: string;
    lastBetType?: string;
    points: number;
    position?: number;
    spins: number;
    updated?: Date;
    userAlias: string;
    active: boolean;
    highestWinMultipliers: number[];
    multiplierPoints?: number;
    multiplierPointsTotal?: number;
    isMe?: boolean;
    isActive?: boolean;
}

export interface CasinoRaceAward {
    displayName: any;
    prizeMessage: any;
    worth?: number;
    bonusCode?: string;
    type: string;
    amount: number;
    startPosition: number;
    endPosition: number;
    raceId?: number;
}

export interface LiveGameMetaData {
    id: string;
    open: boolean;
    language: string;
    betBehind: boolean;
    virtualTableId: string;
    dealerName: string;
    type: 'FullTime' | 'Bounded';
    startTime: Date;
    endTime: Date;
    results: string[];
    seatedPlayers: number;
    availableSeats: number;
    seatsTaken?: number[];
    betLimits: [
        {
            min: number;
            max: number;
            currency: string;
        },
    ];
    imageUrl?: string;
}

export type GamePlayCurrency = 'EUR' | 'USD';

export enum CasinoSubProvider {
    PLAYSON = 2,
    BOOMING_GAMES = 3,
    PRAGMATIC_PLAY = 4,
    PUSH_GAMING = 6,
    QUICKSPIN = 8,
    ALL41_STUDIOS = 9,
    JUST_FOR_THE_WIN = 11,
    TRIPLE_EDGE_STUDIOS = 14,
    FOXIUM = 17,
    RED_RAKE_GAMING = 18,
    SWITCH_STUDIOS = 19,
    '2BY2' = 21,
    BIG_TIME_GAMING = 27,
    ELECTRIC_ELEPHANT = 28,
    IRON_DOG_STUDIOS = 36,
    ONE_TOUCH = 40,
    WAZDAN = 78,
    NOLIMIT_CITY = 80,
    RED_TIGER = 83,
    RELAX_GAMING = 84,
    BLUEPRINT = 85,
    HACKSAW_GAMING = 88,
    THUNDERKICK = 95,
    SWINTT = 100,
    SPINOMENAL = 104,
    HABANERO = 105,
    SPRIBE = 107,
    EZUGI = 108,
    EVOLUTION = 124,
    NETENT = 126,
    PRAGMATIC_PLAY_LIVE = 132,
    GAMING_CORPS = 151,
    BOMBAY_LIVE = 149,
    AUTHENTIC_GAMING = 197,
    ON_AIR = 131,
    PLAYTECH_LIVE = 200,
    STAKELOGIC_LIVE = 155,
}

export interface CasinoGameLauncher {
    id?: number;
    name: string;
    lobbyUrl?: string;
    providerId: CasinoProviders;
    subProviderId?: CasinoSubProvider;
    gameType?: string;
    type?: string;
    lobbyLauncherType?: string;
    gameVariantId?: number;
    serverGameId?: string;
    imageName?: string;
    backgroundImageName?: string;
    disabledWithBonus?: boolean;
    gamePlayCurrency?: GamePlayCurrency;
}

export interface CasinoJackpot {
    provider: string;
    CAD: number;
    USD: number;
    SEK: number;
    NOK: number;
    EUR: number;
    CLP?: number;
}

export interface CasinoMaintenance {
    providerId: number | null;
    startDate: string;
    endDate: string;
    subproviderIds: number[];
}

export const CasinoGameGameType = {
    CASINO: 'casino',
    LIVE: 'live',
} as const;

export const CasinoGameType = {
    SLOTS: 'Slots',
    VIDEO_POKER: 'Video Poker',
    ROULETTE: 'Roulette',
    BLACKJACK: 'Blackjack',
    POKER: 'Poker',
    GAME_SHOW: 'Gameshow',
    DICE: 'Dice',
    BACCARAT: 'Baccarat',
    BINGO: 'Bingo',
    CRASH: 'Crash',
} as const;

type CasinoGameVolatility = 'N/A' | 'Low' | 'Medium' | 'High';

export interface CasinoGame {
    id: number;
    groupId: number;
    gameVariantId: number;
    name: string;
    mode: ObjectValues<typeof Platform>;
    isPublic: boolean;
    jackpotName?: string;
    wageringContribution: number;
    externalCategoryId?: string;
    gameType?: ObjectValues<typeof CasinoGameGameType>;
    releaseDate?: string;
    lines?: number;
    reels?: number;
    xWin?: number;
    rtp?: string;
    minRtpEmta?: string;
    minRtpSga?: string;
    minRtpMga?: string;
    minRtpCur?: string;
    minRtpOnt?: string;
    minRtpMex?: string;
    volatility?: CasinoGameVolatility;
    hitRate?: string;
    imageName: string;
    backgroundImageName?: string;
    subProviderId?: number;
    providerId: number;
    studioId: number;
    minBet?: number;
    maxBet?: number;
    labels: number[];
    themes: number[];
    serverGameId?: string;
    tableId?: number;
    properties: number[];
    type?: ObjectValues<typeof CasinoGameType>;
    disabledWithBonus: boolean;
    additionalAttributes: { liveExternalCategory?: string };
    operatingLanguageId?: number;
    gameLogos: GameLogo[];
    externalId: string;
}

interface GameLogo {
    imageName: string;
    type: 'PROVIDER' | 'PROMOTION';
}

export interface CasinoProvider {
    id: number;
    name: string;
    abbreviation: string;
    totalGames: number;
    type: 'provider' | 'subProvider'; // TODO This logic is flawed. Games should have only provider (and parent provider should be set and used only for reports). This information should not come to FO.
    position?: number;
    url?: string;
    isPinned?: boolean;
}

export interface CasinoCategorySubprovider {
    id: number;
    name: string;
    position: number;
    isPinned?: boolean;
}

export interface RecentlyPlayed {
    userId: string;
    gameGroupId: number;
    gameVariantId: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

export interface GamePlayedCount {
    gameGroupId: number;
    playCount: number;
}

export interface UserStatistics {
    biggestWin?: {
        gameGroupId: number;
        multiplier: number;
        betsUc: number;
        winsUc: number;
    };
    highestRtp?: {
        gameGroupId: number;
        rtp: number;
    };
    mostPlayedGameGroupId?: number;
    totalPlayedGames: number;
    totalSpins: number;
    latestUpdate: Date | null;
}

export interface UserActivity {
    rtp: UserActivitySegment;
    spins: UserActivitySegment;
    hitRate: UserActivitySegment;
    betsWins: {
        bets: number;
        wins: number;
        trend: {
            day: string;
            bets: number;
            wins: number;
        }[];
        games: GameTotalBetsWins[];
    };
    latestUpdate: Date | null;
}

interface GameTotal {
    gameGroupId: number;
    total: number;
}

interface GameTotalBetsWins {
    gameGroupId: number;
    bets: number;
    wins: number;
}

interface UserActivitySegment {
    total: number;
    trend: { day: string; total: number }[];
    games: GameTotal[];
}

export interface CasinoDateFilter {
    period: Period;
    startDate: string | null | Date;
    endDate: string | null | Date;
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

export interface UserHighestWin {
    betsUc: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    gameRoundId: number;
    gameGroupId: number;
    multiplier: number;
    winsUc: number;
    image?: string;
    gameName?: string;
}

export interface HighestDailyWins {
    id: number;
    gameGroupId: number;
    city: string;
    country: Country;
    countryCode: string;
    winsUc: number;
    multiplier: number;
}

export interface WinnerTicker {
    gameName: string;
    amount: number;
    alias?: string;
}

export enum Period {
    LAST_7_DAYS = 'last_7_days',
    LAST_30_DAYS = 'last_30_days',
    CURRENT_YEAR = 'current_year',
    CUSTOM = 'custom',
}

export interface ProviderInfo {
    providerId: CasinoProviders;
    subProviderId?: CasinoSubProvider;
}

export const Platform = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
    IOS: 'IOS',
} as const;

interface LaunchParams {
    origin: string;
}
export interface EvolutionLobbyUrl {
    launchParams: LaunchParams;
}

export const RaceHistorySortingKey = {
    LAST_10: 'last_10',
    ONLY_MINE: 'only_mine',
} as const;

export interface CasinoGameMetaData {
    minBet?: number;
    maxBet?: number;
    dealerName?: string;
    availableSeats?: number;
    seatedPlayers?: number;
    results?: string[];
    seatsTaken?: number[];
    betBehind: boolean;
    imageUrl?: string;
}

interface insertedClosedGamesTimeStamp {
    insertionDate?: number;
}

// eslint-disable-next-line import/no-unused-modules
export interface ClosedCasinoGames {
    [key: number]: CasinoGame & insertedClosedGamesTimeStamp;
}

export interface LeaderboardRow {
    points: number;
    raceId: number;
    userAlias: string;
    gameId: string;
    isHighlighted: boolean;
    position?: number;
    prize?: Record<string, number>;
}

export interface LeaderboardQueryParams {
    raceId: number;
    showMostWon: boolean;
    userId?: string;
}

export interface SimCasinoRace extends Omit<CasinoRace, 'leaderboard'> {
    leaderboard: LeaderboardRow[];
}

export interface MenuItem {
    title: string;
    icon: string;
    route: string;
    isPublic?: boolean;
    isAvailable: boolean;
}

export enum MenuState {
    CASINO = 'casino',
    LIVE = 'live',
}

export interface CasinoHighestWinsPayload {
    country: Country;
    currency: Currency;
    language: string;
    period: 'DAY' | 'WEEK' | 'MONTH';
}

export interface CasinoGameFull extends CasinoGame {
    description?: string;
    linesComment?: string;
    rtpComment?: string;
    maxWinMultiplierComment?: string;
}

import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import round from 'lodash/round';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { systemBetTypeBySystemKey } from './constants';
import { getUniqCombinations } from '../util';
import { payoutRound } from './betslip';

function baseCalculateStake(systemBets: SystemBets, stakeBySystemType) {
    const totalStake = map(systemBets, (betsOfType, systemKey) => {
        const systemType = systemBetTypeBySystemKey[systemKey];
        const betStake = Number(stakeBySystemType[systemType]);
        if (!betStake) {
            return;
        }
        return betStake * betsOfType.length;
    })
        .filter((totalBetsOfOneTypeStakeOrUndefined) => totalBetsOfOneTypeStakeOrUndefined)
        .reduce((totalSystemStake, oneTypeOfSystemStake) => Number(totalSystemStake) + Number(oneTypeOfSystemStake), 0);

    // TODO: fix places to show correct amount with currency
    return round(Number(totalStake), 2);
}

export function calculateSystemTotalStake(systemBets) {
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { systemStakes, MASystemStakes } = userState;
    const stakesBySystem = mapValues(
        systemStakes,
        (userStake, systemKey) => Number(userStake) + (Number(MASystemStakes[systemKey]) || 0),
    );
    return baseCalculateStake(systemBets, stakesBySystem);
}

export function calculateSystemUserOnlyStake(systemBets) {
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { systemStakes } = userState;
    return baseCalculateStake(systemBets, systemStakes);
}

export function calculateSystemExtraTotalStake(systemBets) {
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { MASystemStakes } = userState;
    return baseCalculateStake(systemBets, MASystemStakes);
}

export function calculateSystemTotalPotentialReturn(systemBets) {
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const { systemStakes } = userState;
    const potentialReturn = map(systemBets, (betsOfType, systemKey) => {
        const stake = systemStakes[systemBetTypeBySystemKey[systemKey]];
        if (!stake) {
            return;
        }
        return betsOfType
            .map((marketIdsForBet) => {
                const betTotalOdds = marketIdsForBet
                    .map((marketId) => {
                        const outcomeId = betSlipMarketIdToOutcomeId[marketId];
                        return oddsByOutcomeId[outcomeId] ? oddsByOutcomeId[outcomeId]?.value : 1;
                    })
                    .reduce((oddsMultipliedValue, currentOddsValue) => oddsMultipliedValue * currentOddsValue, 1);
                return payoutRound(Number(stake) * betTotalOdds);
            })
            .reduce(
                (totalBetsPotentialRetun: number, betPotentialReturn: number) =>
                    totalBetsPotentialRetun + betPotentialReturn,
                0,
            );
    })
        .filter((systemBetPotentialReturnOrUndefined) => systemBetPotentialReturnOrUndefined)
        .reduce(
            (totalSystemBetSystemTypePotentialReturn: number, systemBetSystemTypePotentialReturn: number) =>
                totalSystemBetSystemTypePotentialReturn + systemBetSystemTypePotentialReturn,
            0,
        );
    return payoutRound(potentialReturn);
}

export function getSystemCombinations(betSlipMarketIdToOutcomeId) {
    const systemBets = {};
    const betSlipMarketIdsWithOutBankers = Object.keys(betSlipMarketIdToOutcomeId).slice(0, 10);

    for (let combinationLength = 1; combinationLength <= betSlipMarketIdsWithOutBankers.length; combinationLength++) {
        const combinations = getUniqCombinations(betSlipMarketIdsWithOutBankers, combinationLength);
        combinations.forEach((combination) => {
            if (!systemBets[combinationLength]) {
                systemBets[combinationLength] = [];
            }
            systemBets[combinationLength].push(combination);
        });
    }
    return systemBets as SystemBets;
}

export type SystemBets = Record<number, number[]>;

import {
    loadCasinoActiveBonus,
    loadCasinoCategories,
    loadCasinoFeatures,
    loadCasinoGameLabels,
    loadCasinoGameOperatingLanguages,
    loadCasinoGameTypes,
    loadCasinoLobbySettings,
    loadCasinoProviderLiveLobbyTypes,
    loadCasinoProviders,
    loadCasinoStudios,
} from '../../microservices/casino';
import { loadRecentlyPlayedGames } from '../../services/casino/casino-statistics';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { isFeatureAvailable } from '../feature';
import { FEATURE } from '../types';
import { loadCasinoPaybackProgram } from './casino-payback';
import { initializeCasinoRace } from './casino-race';
import { loadThrilltechJackpots } from './casino-thrilltech-jackpot';
import { loadFavoriteGames, mapCasinoCategories } from './categories';
import { loadCasinoGames, loadFilteredCasinoGames, mapCasinoGames } from './games';
import { setCasinoJackpots } from './jackpots';
import { setInitialLiveGameMetaData } from './live-lobby';
import { updateCasinoMaintenance } from './maintenance';
import { mapCasinoProviders } from './providers';

let casinoInitializationPromise: Promise<void>;

export async function initCasino(): Promise<void> {
    return casinoInitializationPromise || (await loadCasino());
}

export async function loadCasino(): Promise<void> {
    casino.playForReal.set(getStoreValue(stores.isAuthenticated));
    casino.shouldReload.set(false);
    await updateCasinoMaintenance(); // TODO Should be implemented with pusher.
    if (getStoreValue(stores.maintenance.isCasinoMaintenance)) {
        casino.isInitialized.set(true);
        return;
    }

    casinoInitializationPromise = Promise.all([
        loadCasinoGames(),
        loadCasinoCategories(),
        loadCasinoProviders(),
        loadCasinoStudios(),
        loadCasinoProviderLiveLobbyTypes(), // TODO: remove this from init and use in place
    ])
        .then(() => setCasinoJackpots())
        .then(loadFavoriteGames)
        .then(() => {
            if (isFeatureAvailable(FEATURE.RECENTLY_PLAYED_GAMES)) {
                return loadRecentlyPlayedGames();
            }
        })
        .then(() => mapCasinoCategories())
        .then(mapCasinoGames)
        .then(mapCasinoProviders)
        .then(loadFilteredCasinoGames)
        .then(initializeCasinoRace)
        .then(() => {
            casino.isInitialized.set(true);
        })
        .then(() => loadSecondaryData());

    await checkCasinoActiveBonus(); // TODO Should be updated with pusher, when user gets bonus or when lock is removed.

    return casinoInitializationPromise;
}

async function loadSecondaryData() {
    return Promise.all([
        loadCasinoFeatures(),
        loadCasinoGameLabels(),
        loadCasinoGameOperatingLanguages(),
        loadCasinoLobbySettings(),
        loadCasinoGameTypes(),
        loadCasinoPaybackProgram(),
        loadThrilltechJackpots(),
        setInitialLiveGameMetaData(),
    ])
        .then(() => mapCasinoCategories())
        .then(mapCasinoGames)
        .then(() => casino.isSecondaryDataInitialized.set(true));
}

export async function checkCasinoActiveBonus() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    if (!isAuthenticated) {
        casino.hasActiveBonus.set(false);
        return;
    }

    await loadCasinoActiveBonus();
}

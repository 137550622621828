import classNames from 'classnames';
import React from 'react';
import Wrapper from './styles';
import { useStore } from '../../hooks/useStore';
import { environment } from '../../stores/environment/environment';

interface Props {
    size?: number;
    compact?: boolean;
    className?: string;
}

export default function Logo({ size, compact, className }: Props) {
    const [{ CLIENT_NAME }] = useStore(environment);

    return (
        <Wrapper size={size} className={classNames('brand-logotype', className)}>
            {compact ? (
                <img src={`/assets/images/logo/${CLIENT_NAME}-compact.svg`} alt={CLIENT_NAME} />
            ) : (
                <img src={`/assets/images/logo/${CLIENT_NAME}.svg`} alt={CLIENT_NAME} />
            )}
        </Wrapper>
    );
}

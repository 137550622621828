import React, { useState } from 'react';
import { translate } from '../../../../../services/translate';
import Wrapper from './styles';
import { getRoute, useRouter } from '../../../../../services/router';
import Svg from '../../../../svg/Svg';
import UiNavLink from '../../../../ui/nav-link/UiNavLink';
import UiGroup from '../../../../ui/group/UiGroup';
import { stores } from '../../../../../stores';
import { logout } from '../../../../../services/auth';
import { isFeatureAvailable } from '../../../../../services/feature';
import AccountMobileSidebar from '../../../../account/mobile-sidebar/AccountMobileSidebar';
import { isB2B } from '../../../../../services/environment';
import { formattedAmountWithCurrency } from '../../../../../services/currency';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
import { FEATURE } from '../../../../../services/types';

export default function CoolbetHeaderAuthenticatedMenu() {
    const [user] = useStore(stores.user);
    const [wallet] = useStore(stores.wallet);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { navigateTo } = useRouter();
    const [{ isPhone, isTablet }] = useStore(media);
    const isMobileDevice = isPhone || isTablet;

    if (!user) {
        return null;
    }

    const links: any = [
        {
            label: translate('My Account', 'ui.header'),
            route: getRoute('account'),
            icon: 'user',
        },
        {
            label: translate('Support', 'ui.footer'),
            route: getRoute('support'),
            icon: 'support',
        },
        isFeatureAvailable(FEATURE.WITHDRAWALS) && {
            label: translate('Payout', 'ui.common'),
            route: getRoute('payout'),
            icon: 'withdrawal',
        },
        isFeatureAvailable(FEATURE.PAYMENTS_W2G_REPORT) && {
            label: translate('Tax statements', 'ui.account'),
            route: getRoute('tax-statements'),
            icon: 'wallet',
        },
        isFeatureAvailable(FEATURE.SPORTSBOOK) && {
            label: translate('Bet History', 'ui.account'),
            route: getRoute('bet-history.sports'),
            icon: 'bethistory',
        },
        isFeatureAvailable(FEATURE.REFER_A_FRIEND) && {
            label: translate('Refer a friend', 'raf.ui'),
            route: getRoute('refer-a-friend'),
            icon: 'refer-a-friend',
        },
    ];

    function getLinks() {
        return links.filter(Boolean).map((link) => {
            const { label, route, icon } = link;
            const dataTest = label.toLowerCase().replace(/\s/g, '-');

            return (
                <UiNavLink key={route} to={route} data-test={dataTest} className="button">
                    <Svg icon={icon} className="icon" />
                    <span>{label}</span>
                </UiNavLink>
            );
        });
    }

    function toggleMenuOpen() {
        if (isMobileDevice) {
            setIsMenuOpen(!isMenuOpen);
        } else {
            navigateTo(getRoute('account'));
        }
    }

    return (
        <>
            <Wrapper>
                <div className="user-menu" onClick={toggleMenuOpen}>
                    <div className="user-info">
                        {user && <div className="user-name">{user.firstName || user.alias}</div>}
                        {wallet && <div className="user-balance">{formattedAmountWithCurrency(wallet.balance_uc)}</div>}
                    </div>
                    <Svg icon="menu-dots-vertical" size={1} />
                </div>

                <div className="account-button-dropdown-container">
                    <UiGroup vertical className="account-button-dropdown">
                        {getLinks()}
                        <div className="button" data-test="logout" onClick={() => logout()}>
                            <Svg icon="logout" className="icon" />
                            <span>{translate('Logout', 'ui.header')}</span>
                        </div>
                    </UiGroup>
                </div>
            </Wrapper>
            <AccountMobileSidebar
                position={isB2B() ? 'left' : 'right'}
                open={isMenuOpen}
                onClose={toggleMenuOpen}
                onOpen={toggleMenuOpen}
            />
        </>
    );
}

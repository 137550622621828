import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import {
    ADJUSTED_TOWIN_CALC,
    AT_LEAST_TWO_SELECTIONS,
    BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR,
    BONUS_BET_MIN_ODDS_ERROR,
    BONUS_BET_MIN_STAKE_ERROR,
    CAMPAIGN_BET_TYPE_ERROR,
    CAMPAIGN_MAX_SELECTIONS_ERROR,
    CAMPAIGN_MIN_ODDS_ERROR,
    CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
    CAMPAIGN_MIN_SELECTIONS_ERROR,
    CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
    CAMPAIGN_WRONG_STAKE_ERROR,
    COMBO_MARKET_ID,
    COMBO_NOT_ALLOWED_ERROR,
    INCORRECT_AMOUNT_OF_SELECTIONS,
    INPLAY_NOT_ALLOWED,
    INSUFFICIENT_FUNDS_ERROR,
    INVALID_MAIN_LINE,
    INVALID_STAKE_ERROR,
    LOCKED_UNTIL_FUNDED,
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    MAX_SELECTIONS_TEASER_ERROR,
    MIN_SELECTIONS_TEASER_ERROR,
    NOT_MAIN_LINE,
    ODDS_CHANGED_ERROR,
    ODDS_CLOSED_ERRORS_BACKEND,
    SAME_MARKET_IN_BETSLIP_ERROR,
    SAME_MATCH_IN_BETSLIP_ERROR,
    TEASERS_WRONG_MARKET_TYPE,
    TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
    TICKET_LIMIT_ERROR,
    ACCOUNT_CLOSED_ERROR,
    ACCOUNT_CLOSED_ERROR_MESSAGE,
    BET_BUILDER_NOT_ALLOWED_ERROR,
    BET_MIN_ODDS,
    BET_PERIOD_SHOULD_BE_DAY,
    BETBUILDER_ONLY,
    BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE,
    BETBUILDER_SELECTION_IS_REDUNDANT,
    BETSTAKE_NOT_EQUAL_PREV_DAY_MAXWIN,
    BETSTAKE_NOT_EQUAL_PREV_DAY_SATE,
    COMBO_CARD_BETSLIP_MIN_MAX_STAKE,
    DUPLICATE_REQUEST,
    FIRST_DAY_BETSTAKE_AMOUNT,
    GEOCOMPLY_FAILED_ERROR,
    GEOCOMPLY_FAILED_ERROR_MESSAGE,
    INVALID_TIME_FORMAT,
    LIMIT_EXCEEDED_ERROR,
    LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR,
    LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE,
    MA_DISABLED_ERROR,
    MA_ENABLED_ERROR,
    MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR,
    MIN_STAKE_REQUIREMENT,
    MORE_THAN_ONE_BET,
    MULTIPLE_BET_IN_THE_PERIOD,
    NO_CAMPAIGN,
    NOT_ENOUGH_MONEY_LOCKED_BY_BONUS_ERROR,
    NOT_ENOUGH_SELECTIONS,
    NOT_MAIN_LINE_BACKEND,
    ODDS_CLOSED_ERROR,
    ODDS_HEARTBEAT_DOWN,
    ON_DEMAND_BET_ERROR_MESSAGE,
    PARLAY_CARD_BET_TIME_ERROR,
    PARLAY_CARD_MARKET_STATUS,
    PARLAY_CARD_MATCH_STATUS,
    PARLAY_CARD_MAX_WIN,
    PARLAY_CARD_NOT_AVAILABLE,
    PARLAY_CARD_OUTCOME_STATUS,
    PARLAY_CARD_SELECTION_AMOUNT_ERROR,
    PARLAY_CARD_SELECTION_NOT_AVAILABLE,
    PARLAY_CARD_STATUS_ERROR,
    SELECTION_NOT_BETBUILDER_ELIGIBLE,
    sportPrefix,
    TOO_MANY_SELECTIONS,
    USER_CURRENCY_NOT_AVAILABLE_IN_CAMPAIGN,
    WRONG_BET_TYPE,
    WRONG_MARKET_TYPE_GROUP,
    WRONG_MATCH,
    WRONG_MATCH_RESULT_TIME,
    WRONG_PRODUCT_ERROR,
    WRONG_SEGMENT,
    GEOCOMPLY_EXPIRED_ERROR,
    GEOCOMPLY_INVALID_ID_ERROR,
    GEOCOMPLY_EXPIRED_ERROR_MESSAGE,
    GEOCOMPLY_INVALID_ID_ERROR_MESSAGE,
    CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE,
    DUPLICATE_TICKET_ERROR,
} from '../constants';
import fromPairs from 'lodash/fromPairs';
import { logout } from '../../auth';
import { getActiveCurrency } from '../../currency';
import { translate } from '../../translate';
import { getMaxWinDaily, getMaxWinWeekly, getMinStake } from '../limits';
import { BET_TYPE, BetslipMode } from '../types';
import { getMaxComboSelections } from '../betslip';
import type { Currency } from '../../wallet/types';
import keyBy from 'lodash/keyBy';

function addBetslipError(marketId, error) {
    stores.sports.betSlipErrorByMarketId.set((state) => {
        state[marketId] = (state[marketId] || []).concat(error);
        state[marketId] = uniqBy(state[marketId], (error: any) => error.code || error);
    });
}

function addComboCardBetslipError(cardId: number, error) {
    stores.sports.comboCard.betslipErrorsByCardId.set((state) => {
        const updatedCardErrors = (state[cardId] || []).concat(error);
        return {
            ...state,
            [cardId]: uniqBy(updatedCardErrors, (error: any) => error.code || error),
        };
    });
}

const isSameError = (existingError, error) => (existingError.code || existingError) === (error.code || error);

function removeBetslipError(marketId, error) {
    stores.sports.betSlipErrorByMarketId.set((state) => {
        remove(state[marketId], (existingError) => isSameError(existingError, error));
    });
}

function removeComboCardBetslipError(cardId: number, error) {
    stores.sports.comboCard.betslipErrorsByCardId.set((state) => {
        const cardErrors = state[cardId];
        if (!cardErrors) {
            return state;
        }

        const updatedCardErrors = cardErrors.filter(({ code }) => code !== error.code);
        return { ...state, [cardId]: updatedCardErrors };
    });
}

function getErrorCode(error) {
    return error.code || error;
}

function removeAllBetslipErrorsOfCode(errorCodesToRemove: (string | number)[]) {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    Object.keys(betSlipMarketIdToOutcomeId)
        .concat(String(COMBO_MARKET_ID))
        .forEach((marketId) => errorCodesToRemove.forEach((errorCode) => removeBetslipError(marketId, errorCode)));
}

function hasErrorsByMarketId(marketId: string) {
    const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
    return (
        betSlipErrorByMarketId[marketId] &&
        betSlipErrorByMarketId[marketId].filter((error) => !isWarning(error) && !isInfo(error)).length
    );
}

const FRONTEND_ERRORS = [
    ODDS_CLOSED_ERRORS_BACKEND[0],
    ODDS_CHANGED_ERROR,
    SAME_MATCH_IN_BETSLIP_ERROR,
    SAME_MARKET_IN_BETSLIP_ERROR,
    COMBO_NOT_ALLOWED_ERROR,
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    MAX_SELECTIONS_TEASER_ERROR,
    MIN_SELECTIONS_TEASER_ERROR,
    TEASERS_WRONG_MARKET_TYPE,
    TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
    NOT_MAIN_LINE,
    INVALID_MAIN_LINE,
    INCORRECT_AMOUNT_OF_SELECTIONS,
    INPLAY_NOT_ALLOWED,
    AT_LEAST_TWO_SELECTIONS,
    ADJUSTED_TOWIN_CALC,
    INVALID_STAKE_ERROR,
    TICKET_LIMIT_ERROR,
    LOCKED_UNTIL_FUNDED,
    INSUFFICIENT_FUNDS_ERROR,
    BONUS_BET_MIN_ODDS_ERROR,
    BONUS_BET_MIN_STAKE_ERROR,
    BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR,
    CAMPAIGN_BET_TYPE_ERROR,
    CAMPAIGN_MAX_SELECTIONS_ERROR,
    CAMPAIGN_MIN_ODDS_ERROR,
    CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
    CAMPAIGN_MIN_SELECTIONS_ERROR,
    CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
    CAMPAIGN_WRONG_STAKE_ERROR,
];

function removeAllNonFrontendErrors(exclusionList = []) {
    const keepOnly = [...FRONTEND_ERRORS, ...exclusionList];
    stores.sports.betSlipErrorByMarketId.set((errorsByMarketId) => {
        Object.keys(errorsByMarketId).forEach((marketId) => {
            errorsByMarketId[marketId] = (errorsByMarketId[marketId] || []).filter((error) =>
                keepOnly.includes(getErrorCode(error)),
            );
        });
    });
}

function getCampaignBetTypesInString() {
    const { selected: selectedCampaign } = getStoreValue(stores.sports.campaigns);
    if (!selectedCampaign?.sport_requirements?.bet_type) {
        return '';
    } else if (selectedCampaign.sport_requirements.bet_type === CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE.BOTH) {
        // TODO: When 'BOTH' is deprecated as a bet type in a campaign's sport requirements, remove this if statement
        return `${BET_TYPE.SINGLE}, ${BET_TYPE.COMBO}`;
    }
    return selectedCampaign.sport_requirements.bet_type
        .split(',')
        .map((betType) => betType.toLowerCase())
        .join(', ');
}

const getInvalidStakeErrorMessage = (error, activeCurrency: Currency) => {
    if (error.maxStake) {
        return translate('MAX_STAKE', sportPrefix, [error.maxStake, activeCurrency]);
    }
    const minStake = error.minStake || getMinStake();
    return translate('MIN_STAKE', sportPrefix, [minStake, activeCurrency]);
};

const betslipPrefix = 'ui.betslip';

function getErrorMessage(error) {
    const errorCode = getErrorCode(error);
    if (errorCode === ACCOUNT_CLOSED_ERROR) {
        logout();
    }
    const maEnabledError = 'You are exceeding our limits, please use one of the following options:';
    const maDisabledError = 'You are exceeding our limits, your stake has been set to maximum allowed!';
    const oddsClosedErrorsDict = fromPairs(
        ODDS_CLOSED_ERRORS_BACKEND.map((code) => [code, translate('ODDS_CLOSED', sportPrefix)]),
    );
    const activeCurrency = getActiveCurrency();
    const betslipMode = getStoreValue(stores.sports.betslipMode);
    const betType =
        betslipMode === BetslipMode.ParlayCard
            ? BET_TYPE.PARLAY_CARD
            : getStoreValue(stores.sports.betSlipUserState).betType;
    // insert all new error codes the user can know of below
    // all the errors not here will show Technical error
    return {
        ...oddsClosedErrorsDict,
        [ODDS_CLOSED_ERROR]: translate('ODDS_CLOSED', sportPrefix),
        [ODDS_CHANGED_ERROR]: translate('ODDS_CHANGED', sportPrefix),
        [ODDS_HEARTBEAT_DOWN]: translate('ODDS_CHANGED', sportPrefix),
        [MA_ENABLED_ERROR]: translate(maEnabledError, betslipPrefix),
        [MA_DISABLED_ERROR]: translate(maDisabledError, betslipPrefix),
        [INSUFFICIENT_FUNDS_ERROR]: translate('INSUFFICIENT_FUNDS', sportPrefix),
        [LIMIT_EXCEEDED_ERROR]: translate('INSUFFICIENT_FUNDS', sportPrefix),
        [NOT_ENOUGH_MONEY_LOCKED_BY_BONUS_ERROR]: translate('NOT_ENOUGH_MONEY_LOCKED_BY_BONUS', sportPrefix),
        1001: translate('COMBO_NOT_ALLOWED', sportPrefix),
        1131: translate('IndividualBetLimitExceededError', sportPrefix),
        4009: translate('IndividualBetLimitExceededError', sportPrefix),
        1130: translate('WagerLimitExceededError', sportPrefix),
        4006: translate('WagerLimitExceededError', sportPrefix),
        1112: translate('LossLimitExceededError', sportPrefix),
        4002: translate('LossLimitExceededError', sportPrefix),
        1111: translate('sb-closed-support1', 'ui.account'),
        1110: translate('sb-closed-support1', 'ui.account'),
        [LOCKED_UNTIL_FUNDED]: translate('LOCKED_UNTIL_FUNDED', betslipPrefix),
        1109: translate('DUPLICATE_TICKET_ERROR', betslipPrefix),
        [BET_BUILDER_NOT_ALLOWED_ERROR]: translate('BET_BUILDER_NOT_ALLOWED_ERROR', betslipPrefix),
        [COMBO_NOT_ALLOWED_ERROR]: translate('COMBO_NOT_ALLOWED', sportPrefix),
        [SELECTION_NOT_BETBUILDER_ELIGIBLE]: translate(
            [SELECTION_NOT_BETBUILDER_ELIGIBLE, 'Selection is not betbuilder eligible'],
            'ui.betslip',
        ),
        [BETBUILDER_SELECTION_IS_REDUNDANT]: translate(
            [BETBUILDER_SELECTION_IS_REDUNDANT, 'Selection is redundant for this combination'],
            'ui.betslip',
        ),
        [BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE]: translate(
            [BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE, 'These selections set cannot be combined'],
            'ui.betslip',
        ),
        [BETBUILDER_ONLY]: translate([BETBUILDER_ONLY, 'Selection can be used only for betbuilder'], 'ui.betslip'),
        [SAME_MATCH_IN_BETSLIP_ERROR]: translate('SAME_MATCH_IN_BETSLIP', sportPrefix),
        [SAME_MARKET_IN_BETSLIP_ERROR]: translate('SAME_MARKET_IN_BETSLIP', sportPrefix),
        [INVALID_STAKE_ERROR]: getInvalidStakeErrorMessage(error, activeCurrency),
        [TICKET_LIMIT_ERROR]: translate('TICKET_LIMIT', sportPrefix),
        1119: translate('DAILY_LIMIT', sportPrefix, [getMaxWinDaily(), activeCurrency]),
        1120: translate('WEEKLY_LIMIT', sportPrefix, [getMaxWinWeekly(), activeCurrency]),
        [GEOCOMPLY_FAILED_ERROR]: translate(GEOCOMPLY_FAILED_ERROR_MESSAGE, betslipPrefix),
        [GEOCOMPLY_EXPIRED_ERROR]: translate(GEOCOMPLY_EXPIRED_ERROR_MESSAGE, betslipPrefix),
        [GEOCOMPLY_INVALID_ID_ERROR]: translate(GEOCOMPLY_INVALID_ID_ERROR_MESSAGE, betslipPrefix),
        [MAX_SELECTIONS_COMBO_ERROR]: translate(MAX_SELECTIONS_COMBO_ERROR, betslipPrefix, {
            maxSelections: getMaxComboSelections(),
        }),
        [MAX_SELECTIONS_TEASER_ERROR]: translate(MAX_SELECTIONS_TEASER_ERROR, betslipPrefix),
        [MIN_SELECTIONS_TEASER_ERROR]: translate(MIN_SELECTIONS_TEASER_ERROR, betslipPrefix),
        [MAX_SELECTIONS_SYSTEM_ERROR]: translate(MAX_SELECTIONS_SYSTEM_ERROR, betslipPrefix),
        [COMBO_CARD_BETSLIP_MIN_MAX_STAKE]: translate(COMBO_CARD_BETSLIP_MIN_MAX_STAKE, betslipPrefix, {
            minValue: error.minStake,
            maxValue: error.maxStake,
            currency: activeCurrency,
        }),
        [AT_LEAST_TWO_SELECTIONS]: translate(AT_LEAST_TWO_SELECTIONS, sportPrefix),
        [NOT_MAIN_LINE]: translate(ON_DEMAND_BET_ERROR_MESSAGE[NOT_MAIN_LINE][betType], betslipPrefix),
        [NOT_MAIN_LINE_BACKEND]: translate(ON_DEMAND_BET_ERROR_MESSAGE[NOT_MAIN_LINE][betType], betslipPrefix),
        [INVALID_MAIN_LINE]: translate(ON_DEMAND_BET_ERROR_MESSAGE[INVALID_MAIN_LINE][betType], betslipPrefix),
        [TEASERS_WRONG_MARKET_TYPE]: translate('TEASERS_WRONG_MARKET_TYPE', betslipPrefix),
        [TEASER_CATEGORY_COMBINATION_NOT_ALLOWED]: translate(TEASER_CATEGORY_COMBINATION_NOT_ALLOWED, betslipPrefix),
        [PARLAY_CARD_SELECTION_AMOUNT_ERROR]: translate(INCORRECT_AMOUNT_OF_SELECTIONS, betslipPrefix),
        [PARLAY_CARD_BET_TIME_ERROR]: translate(PARLAY_CARD_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_STATUS_ERROR]: translate(PARLAY_CARD_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_OUTCOME_STATUS]: translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_MARKET_STATUS]: translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_MATCH_STATUS]: translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_MAX_WIN]: translate('PARLAY_CARD_MAX_WIN', betslipPrefix),
        [INPLAY_NOT_ALLOWED]: translate('INPLAY_NOT_ALLOWED', betslipPrefix),
        [INCORRECT_AMOUNT_OF_SELECTIONS]: translate(INCORRECT_AMOUNT_OF_SELECTIONS, betslipPrefix),
        [ADJUSTED_TOWIN_CALC]: translate(ADJUSTED_TOWIN_CALC, betslipPrefix),
        [ACCOUNT_CLOSED_ERROR]: translate(ACCOUNT_CLOSED_ERROR_MESSAGE, betslipPrefix),
        [MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR]: translate(
            'MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR',
            betslipPrefix,
        ),
        [CAMPAIGN_BET_TYPE_ERROR]: translate(CAMPAIGN_BET_TYPE_ERROR, betslipPrefix, {
            betTypes: getCampaignBetTypesInString(),
        }),
        3000: translate('PLAYER_NOT_AUTHENTICATED', sportPrefix),
        [LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR]: translate(LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE, sportPrefix),
        4010: translate(FIRST_DAY_BETSTAKE_AMOUNT, betslipPrefix),
        4011: translate(BETSTAKE_NOT_EQUAL_PREV_DAY_MAXWIN, betslipPrefix),
        4012: translate(BETSTAKE_NOT_EQUAL_PREV_DAY_SATE, betslipPrefix),
        4013: translate(BET_MIN_ODDS, betslipPrefix),
        4014: translate(MORE_THAN_ONE_BET, betslipPrefix),
        4015: translate(MULTIPLE_BET_IN_THE_PERIOD, betslipPrefix),
        4016: translate(MIN_STAKE_REQUIREMENT, betslipPrefix),
        4017: translate(WRONG_SEGMENT, betslipPrefix),
        4018: translate(WRONG_PRODUCT_ERROR, betslipPrefix),
        4019: translate(WRONG_MARKET_TYPE_GROUP, betslipPrefix),
        4020: translate(WRONG_MATCH_RESULT_TIME, betslipPrefix),
        4021: translate(WRONG_MATCH_RESULT_TIME, betslipPrefix),
        4022: translate(WRONG_BET_TYPE, betslipPrefix),
        4023: translate(NOT_ENOUGH_SELECTIONS, betslipPrefix),
        4024: translate(TOO_MANY_SELECTIONS, betslipPrefix),
        4025: translate(WRONG_MATCH, betslipPrefix),
        4026: translate(NO_CAMPAIGN, betslipPrefix),
        4027: translate(BET_PERIOD_SHOULD_BE_DAY, betslipPrefix),
        4028: translate(INVALID_TIME_FORMAT, betslipPrefix),
        4029: translate(DUPLICATE_REQUEST, betslipPrefix),
        4030: translate(USER_CURRENCY_NOT_AVAILABLE_IN_CAMPAIGN, betslipPrefix),
        5000: translate('UNKNOWN_ERROR', betslipPrefix),
        [CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR]: translate(CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR, betslipPrefix),
        [CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR]: translate(CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR, betslipPrefix),
        [CAMPAIGN_WRONG_STAKE_ERROR]: translate(CAMPAIGN_WRONG_STAKE_ERROR, betslipPrefix),
        [CAMPAIGN_MIN_ODDS_ERROR]: translate(CAMPAIGN_MIN_ODDS_ERROR, betslipPrefix),
        [CAMPAIGN_MIN_SELECTIONS_ERROR]: translate(CAMPAIGN_MIN_SELECTIONS_ERROR, betslipPrefix),
    }[errorCode];
}

function getBetslipErrorMessage(error) {
    return getErrorMessage(error) || translate('technical_error', sportPrefix);
}

function getMarketErrorStatusByErrorsArray(betslipErrors) {
    const { true: betslipWarning, false: betslipError } = keyBy(betslipErrors, isWarning);
    const isBetslipError = Boolean(betslipError);
    const isBetslipWarning = Boolean(betslipWarning);
    return { isBetslipWarning, isBetslipError };
}

const betslipWarningCodes = [DUPLICATE_TICKET_ERROR];

function isWarning(errorObjectOrCode) {
    const errorCode = getErrorCode(errorObjectOrCode);
    return betslipWarningCodes.includes(errorCode);
}

const betslipInfoCodes = [
    ODDS_CHANGED_ERROR,
    ADJUSTED_TOWIN_CALC,
    ODDS_HEARTBEAT_DOWN,
    COMBO_CARD_BETSLIP_MIN_MAX_STAKE,
];

function isInfo(errorObjectOrCode) {
    const errorCode = getErrorCode(errorObjectOrCode);
    return betslipInfoCodes.includes(errorCode);
}

export {
    isWarning,
    isInfo,
    getErrorMessage,
    addBetslipError,
    addComboCardBetslipError,
    removeBetslipError,
    removeComboCardBetslipError,
    removeAllNonFrontendErrors,
    removeAllBetslipErrorsOfCode,
    getErrorCode,
    getBetslipErrorMessage,
    getMarketErrorStatusByErrorsArray,
    hasErrorsByMarketId,
};

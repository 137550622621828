import {
    initialHorseRacingBetslipPlacingState,
    initialHorseRacingBetslipState,
    initialLivestreamDetails,
} from '../services/horse-racing/horse-racing';
import { createStores } from './store/utils';
import { RaceCarryover } from '@staycool/lvdc-types/race-v-2';
import { RaceDetails, RaceInfo, TrackWithRaceInfo } from '@staycool/lvdc-types/track-v-2';

const initTrackRaceDetails: RaceDetails = {
    raceInfo: {} as RaceInfo,
    entries: [],
    betTypes: [],
};

export const horseRacing = createStores({
    betslip: initialHorseRacingBetslipState,
    betslipErrors: [] as any[],
    betslipStakeErrors: [] as any[],
    betslipPlacingState: initialHorseRacingBetslipPlacingState,
    favouriteTrackIds: [] as number[],
    livestreamDetails: initialLivestreamDetails,
    isBetslipOpen: false,
    isRacebookClosed: false,
    todaysTracks: [] as TrackWithRaceInfo[],
    isLoadingRaceData: false,
    isLoadingTrackData: false,
    trackRaceDetails: initTrackRaceDetails,
    raceEndedActions: {
        shouldReloadUpcomingMatches: true,
        shouldReloadCarryovers: false,
        shouldReloadTrackRace: false,
    },
    carryovers: [] as RaceCarryover[],
    minutesToPostByTimestamp: new Map<number, number>(),
    predefinedBetStakes: [] as number[],
    minMaxStakes: {
        minStake: 0,
        maxStake: 0,
    },
});

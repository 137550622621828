import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { GeocomplyGeoverificationStatus, GeocomplyLicense } from '../services/geocomply/types';
import { getGeocomplyHeaders } from '../services/geocomply/geocomply';

const getUrl = (url) => getServiceUrl('geocomply', url);

export function postGeopacketForProcessing(encryptedGeopacket: string): Promise<GeocomplyGeoverificationStatus> {
    const headers = getGeocomplyHeaders();
    return httpPost(getUrl(`geopacket/`), { geopacket: encryptedGeopacket }, { headers });
}

export function getGeoVerificationStatus(): Promise<GeocomplyGeoverificationStatus> {
    const headers = getGeocomplyHeaders();
    return httpGet(getUrl(`status/`), null, { headers });
}

export function getGeocomplyLicense(isLoadByError = false): Promise<GeocomplyLicense> {
    return httpGet(getUrl(`license/`), { isLoadByError });
}

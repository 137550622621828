import { useEffect } from 'react';

interface Props {
    url: string;
    onLoad?: () => void;
    onError?: (error: Event | string) => void;
    attributes?: Record<string, string>;
}

export default function ScriptLoader({ onError = () => {}, onLoad = () => {}, url, attributes = {} }: Props) {
    function addEventListeners(script: HTMLScriptElement) {
        function onLoadListener() {
            script.setAttribute('data-state', 'loaded');
            onLoad();
            removeEventListeners();
        }

        function onErrorListener(error: ErrorEvent) {
            script.setAttribute('data-state', 'error');
            onError(error);
            removeEventListeners();
        }

        function removeEventListeners() {
            script.removeEventListener('load', onLoadListener);
            script.removeEventListener('error', onErrorListener);
        }

        script.addEventListener('load', onLoadListener);
        script.addEventListener('error', onErrorListener);

        return removeEventListeners;
    }

    function load() {
        let script = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement;

        if (script) {
            const state = script.getAttribute('data-state');

            if (state === 'loaded') {
                onLoad();
                return;
            }

            if (state === 'error') {
                onError('Script failed to load');
                return;
            }

            return addEventListeners(script);
        }

        script = document.createElement('script');
        script.async = true;
        script.src = url;
        Object.entries(attributes).forEach(([key, value]) => script.setAttribute(key, value));
        document.body.append(script);

        return addEventListeners(script);
    }

    useEffect(load, []);

    return null;
}

import asyncComponent from './components/async-component/AsyncComponent';
import LoaderPageCasino from './components/loader/page/casino/LoaderPageCasino';
import LoaderPage from './components/loader/page/LoaderPage';
import { withLayout } from './higher-order-components/layout';
import AccountLayout from './layouts/account/AccountLayout';
import CasinoGameLayout from './layouts/casino-game/CasinoGameLayout';
import CasinoLayout from './layouts/casino/CasinoLayout';
import EmptyLayout from './layouts/empty/EmptyLayout';
import PokerLayout from './layouts/poker/PokerLayout';
import { isB2B, isBYOD, isOfficeIp, isSIM } from './services/environment';
import { isFeatureAvailable } from './services/feature';
import { getRoute } from './services/router';
import { FEATURE } from './services/types';
import SimLayout from './layouts/sim/SimLayout';
import SportExternalPage from './pages/sport/external/SportExternalPage';

const SportPage = asyncComponent(() => import('./pages/sport/SportPage' /* webpackChunkName: 'SportPage'*/), {
    fallback: LoaderPage,
});
const VirtualSportsPage = asyncComponent(
    () => import('./pages/virtual-sports/VirtualSportsPage' /* webpackChunkName: 'VirtualSportsPage'*/),
);
const SimPage = asyncComponent(() => import('./pages/sim/SimPage') /* webpackChunkName: 'SimPage'*/);
const SimBalancesPage = asyncComponent(
    () => import('./pages/sim/balances/SimBalancesPage' /* webpackChunkName: 'SimBalancesPage'*/),
);
const SimExchangeShopPage = asyncComponent(
    () => import('./pages/sim/exchange-shop/SimExchangeShopPage' /* webpackChunkName: 'SimExchangeShopPage'*/),
);
const CasinoPage = asyncComponent(() => import('./pages/casino/CasinoPage' /* webpackChunkName: 'CasinoPage'*/), {
    fallback: LoaderPageCasino,
});
const PokerPage = asyncComponent(() => import('./pages/poker/PokerPage' /* webpackChunkName: 'PokerPage' */));

const HorseRacingPage = asyncComponent(
    () => import('./pages/horse-racing/HorseRacingPage' /* webpackChunkName: 'HorseRacingPage'*/),
    { fallback: LoaderPage },
);
const CasinoGamePage = asyncComponent(
    () => import('./pages/casino-game/CasinoGamePage' /* webpackChunkName: 'CasinoGamePage'*/),
);
const LiveLobbyPage = asyncComponent(
    () => import('./pages/live-lobby/LiveLobbyPage' /* webpackChunkName: 'LiveLobbyPage'*/),
);
const AboutPage = asyncComponent(() => import('./pages/about/AboutPage' /* webpackChunkName: 'AboutPage'*/));
const SupportPage = asyncComponent(() => import('./pages/support/SupportPage' /* webpackChunkName: 'SupportPage'*/));
const PromotionsPage = asyncComponent(
    () => import('./pages/promotions/PromotionsPage' /* webpackChunkName: 'PromotionsPage'*/),
);
const AccountPage = asyncComponent(() => import('./pages/account/AccountPage' /* webpackChunkName: 'AccountPage'*/));
const DepositPage = asyncComponent(() => import('./pages/deposit/DepositPage' /* webpackChunkName: 'DepositPage'*/));
const DepositReturnPage = asyncComponent(
    () => import('./pages/deposit-return/DepositReturnPage' /* webpackChunkName: 'DepositReturnPage'*/),
);
const PurchasePackagesPage = asyncComponent(
    () => import('./pages/purchase-packages/PurchasePackagesPage' /* webpackChunkName: 'PurchasePackagesPage'*/),
);
const PayoutPage = asyncComponent(() => import('./pages/payout/PayoutPage' /* webpackChunkName: 'PayoutPage'*/));
const TaxStatementsPage = asyncComponent(
    () => import('./pages/tax-statements/TaxStatementsPage' /* webpackChunkName: 'TaxStatementsPage'*/),
);
const TransactionsPage = asyncComponent(
    () => import('./pages/transactions/TransactionsPage' /* webpackChunkName: 'TransactionsPage'*/),
);
const BetHistoryPage = asyncComponent(
    () => import('./pages/bet-history/BetHistoryPage' /* webpackChunkName: 'BetHistoryPage'*/),
);
const ReferAFriendPage = asyncComponent(
    () => import('./pages/refer-a-friend/ReferAFriendPage' /* webpackChunkName: 'ReferAFriendPage'*/),
);
const WelcomePage = asyncComponent(() => import('./pages/welcome/WelcomePage' /* webpackChunkName: 'WelcomePage'*/));
const BlogPage = asyncComponent(() => import('./pages/blog/BlogPage' /* webpackChunkName: 'BlogPage'*/));
const RegistrationPage = asyncComponent(
    () => import('./pages/registration/RegistrationPage' /* webpackChunkName: 'RegistrationPage'*/),
);
const ResponsibleGamingPage = asyncComponent(
    () => import('./pages/responsible-gaming/ResponsibleGamingPage' /* webpackChunkName: 'ResponsibleGamingPage'*/),
);
const LoginPage = asyncComponent(() => import('./pages/login/LoginPage' /* webpackChunkName: 'LoginPage'*/));
const LoginRedirectPage = asyncComponent(
    () => import('./pages/login-redirect/LoginRedirectPage' /* webpackChunkName: 'LoginRedirectPage' */),
);
const LeaguePage = asyncComponent(() => import('./pages/league/LeaguePage' /* webpackChunkName: 'LeaguePage'*/), {
    fallback: LoaderPage,
});
const LeagueDetailPage = asyncComponent(
    () => import('./pages/league/detail/LeagueDetailPage' /* webpackChunkName: 'LeagueDetailPage'*/),
);
const ReferPage = asyncComponent(() => import('./pages/refer/ReferPage' /* webpackChunkName: 'ReferPage'*/));
const ResetPasswordPage = asyncComponent(
    () => import('./pages/reset-password/ResetPasswordPage' /* webpackChunkName: 'ResetPasswordPage'*/),
);
const SharePage = asyncComponent(() => import('./pages/share/SharePage' /* webpackChunkName: 'SharePage'*/));
const HowToPwaPage = asyncComponent(
    () => import('./pages/how-to-pwa/HowToPwaPage' /* webpackChunkName: 'HowToPwaPage'*/),
);
const StopSessionPage = asyncComponent(
    () => import('./pages/stop-session/StopSessionPage' /* webpackChunkName: 'StopSessionPage'*/),
);
const PreLaunchPage = asyncComponent(
    () => import('./pages/pre-launch/PreLaunchPage' /* webpackChunkName: 'PreLaunchPage'*/),
);
const InboxPage = asyncComponent(() => import('./pages/inbox/InboxPage' /* webpackChunkName: 'InboxPage'*/));
const CrmMessagePreviewPage = asyncComponent(
    () => import('./pages/crm-message-preview/CrmMessagePreviewPage' /* webpackChunkName: 'CrmMessagePreviewPage'*/),
);
const BlogPreviewPage = asyncComponent(
    () => import('./pages/blog-preview/BlogPreviewPage' /* webpackChunkName: 'BlogPreviewPage'*/),
);
const PayAndPlayLoginPage = asyncComponent(
    () => import('./pages/pay-and-play-login/PayAndPlayLoginPage' /* webpackChunkName: 'PayAndPlayLoginPage'*/),
);
const GeocomplyPage = asyncComponent(
    () => import('./pages/geocomply/GeocomplyPage' /* webpackChunkName: 'GeocomplyPage'*/),
);
const GamblingStatisticsPage = asyncComponent(
    () => import('./pages/gambling-statistics/GamblingStatisticsPage' /* webpackChunkName: 'GamblingStatisticsPage'*/),
);
const RegistrationBtobPage = asyncComponent(
    () => import('./pages/registration-btob/RegistrationBtobPage' /* webpackChunkName: 'RegistrationBtobPage'*/),
);
const RegistrationBtobErrorPage = asyncComponent(
    () =>
        import(
            './pages/registration-btob/error/RegistrationBtobErrorPage' /* webpackChunkName: 'RegistrationBtobErrorPage'*/
        ),
);
const PayoutReturnPage = asyncComponent(
    () => import('./pages/payout-return/PayoutReturnPage' /* webpackChunkName: 'PayoutReturnPage'*/),
);
const EmptyPage = asyncComponent(() => import('./pages/empty/EmptyPage' /* webpackChunkName: 'EmptyPage'*/));
const RegistrationBtobCamsPage = asyncComponent(
    () =>
        import(
            './pages/registration-btob/cams/RegistrationBtobCamsPage' /* webpackChunkName: 'RegistrationBtobCamsPage'*/
        ),
);
const RegistrationBtobCamsContinuePage = asyncComponent(
    () =>
        import(
            './pages/registration-btob/cams/continue/RegistrationBtobCamsContinuePage' /* webpackChunkName: 'RegistrationBtobCamsContinuePage'*/
        ),
);
const EmailUnsubscribePage = asyncComponent(
    () => import('./pages/unsubscribe/UnsubscribePage') /* webpackChunkName: 'EmailUnsubscribePage'*/,
);

const OnsiteMessagePreviewPage = asyncComponent(
    () =>
        import(
            './pages/onsite-message-preview/OnsiteMessagePreviewPage'
        ) /* webpackChunkName: 'OnsiteMessagePreviewPage' */,
);

const ArticlePreviewPage = asyncComponent(
    () => import('./pages/article-preview/ArticlePreviewPage') /* webpackChunkName: 'ArticlePreviewPage' */,
);

const CheckTicketPage = asyncComponent(
    () => import('./pages/checkticket/CheckticketPage' /* webpackChunkName: 'CheckticketPage'*/),
);

export function getRootRoutes() {
    const pages = [
        {
            route: getRoute('sport'),
            component:
                isFeatureAvailable(FEATURE.EXTERNAL_SPORTSBOOK) && !isFeatureAvailable(FEATURE.SPORTSBOOK)
                    ? withLayout(SportExternalPage)
                    : withLayout(SportPage),
            disabled: !isFeatureAvailable(FEATURE.SPORTSBOOK) && !isFeatureAvailable(FEATURE.EXTERNAL_SPORTSBOOK),
        },
        {
            route: getRoute('sim.balances'),
            layout: AccountLayout,
            component: SimBalancesPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.MULTI_CURRENCY_WALLET) || !isSIM(),
        },
        {
            route: getRoute('sim.exchange-shop'),
            layout: AccountLayout,
            component: SimExchangeShopPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.PAYMENT_PACKAGES) || !isSIM(),
        },
        {
            route: getRoute('sim'),
            layout: SimLayout,
            component: SimPage,
            disabled: !isSIM(),
        },
        {
            route: getRoute('casino'),
            layout: CasinoLayout,
            component: CasinoPage,
            disabled: !isFeatureAvailable(FEATURE.CASINO) || isSIM(),
        },
        {
            route: getRoute('virtual-sports'),
            component: withLayout(VirtualSportsPage),
            disabled: !isFeatureAvailable(FEATURE.VIRTUAL_SPORTS),
        },
        {
            route: `${getRoute('casino-game')}/:gameSlug`,
            layout: CasinoGameLayout,
            component: CasinoGamePage,
            disabled: !isFeatureAvailable(FEATURE.CASINO),
        },
        {
            route: `${getRoute('live-lobby')}/:providerName/:type`,
            layout: CasinoGameLayout,
            component: LiveLobbyPage,
            disabled: !isFeatureAvailable(FEATURE.CASINO) || isSIM(),
        },
        {
            route: getRoute('poker'),
            component: withLayout(PokerPage, PokerLayout),
            disabled: !(
                isFeatureAvailable(FEATURE.POKER) ||
                isFeatureAvailable(FEATURE.POKER_MARKETING_ONLY) ||
                isOfficeIp()
            ),
        },
        {
            route: getRoute('horse-racing'),
            component: withLayout(HorseRacingPage),
            disabled: !isFeatureAvailable(FEATURE.HORSE_RACING),
        },
        {
            route: getRoute('about'),
            component: withLayout(AboutPage),
            disabled: !isFeatureAvailable(FEATURE.ABOUT),
        },
        {
            route: getRoute('responsible-gaming'),
            component: withLayout(ResponsibleGamingPage, AccountLayout),
            disabled: !isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING),
        },
        {
            route: getRoute('promotions'),
            component: withLayout(PromotionsPage, AccountLayout),
            disabled: !isFeatureAvailable(FEATURE.PROMOTIONS),
        },
        {
            route: getRoute('support'),
            component: withLayout(SupportPage, AccountLayout),
            disabled: !isFeatureAvailable(FEATURE.SUPPORT),
        },
        {
            route: getRoute('refer-a-friend'),
            layout: AccountLayout,
            component: ReferAFriendPage,
            disabled: !isFeatureAvailable(FEATURE.REFER_A_FRIEND),
        },
        {
            route: getRoute('welcome'),
            component: withLayout(WelcomePage),
        },
        {
            route: getRoute('blog'),
            component: withLayout(BlogPage),
            disabled: !isFeatureAvailable(FEATURE.BLOG),
        },
        {
            route: getRoute('blog-preview'),
            component: BlogPreviewPage,
            loginRequired: false,
            disabled: !isFeatureAvailable(FEATURE.BLOG),
        },
        {
            route: getRoute('onsite-message-preview'),
            component: OnsiteMessagePreviewPage,
            loginRequired: false,
        },
        {
            route: getRoute('crm-message-preview'),
            component: CrmMessagePreviewPage,
        },
        {
            route: getRoute('registration'),
            component: withLayout(RegistrationPage),
            disabled: !isFeatureAvailable(FEATURE.REGISTRATION),
        },
        {
            route: getRoute('registration-btob.cams.continue'),
            component: withLayout(RegistrationBtobCamsContinuePage),
            disabled: !isFeatureAvailable(FEATURE.REGISTRATION_B2B),
        },
        {
            route: getRoute('registration-btob.cams'),
            component: withLayout(RegistrationBtobCamsPage, EmptyLayout),
            disabled: !isFeatureAvailable(FEATURE.REGISTRATION_B2B),
        },
        {
            route: getRoute('registration-btob.error'),
            component: withLayout(RegistrationBtobErrorPage),
            disabled: !isFeatureAvailable(FEATURE.REGISTRATION_B2B),
        },
        {
            route: `${getRoute('registration-btob')}/:step`,
            component: withLayout(RegistrationBtobPage),
            disabled: !isFeatureAvailable(FEATURE.REGISTRATION_B2B),
        },
        {
            route: getRoute('registration-btob'),
            component: withLayout(RegistrationBtobPage),
            disabled: !isFeatureAvailable(FEATURE.REGISTRATION_B2B),
        },
        {
            route: getRoute('account'),
            layout: AccountLayout,
            component: AccountPage,
            loginRequired: true,
        },
        {
            route: getRoute('deposit'),
            layout: AccountLayout,
            component: DepositPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.DEPOSIT),
        },
        {
            route: getRoute('deposit-return'),
            layout: AccountLayout,
            component: DepositReturnPage,
            loginRequired: true,
        },
        {
            route: getRoute('purchase-packages'),
            layout: AccountLayout,
            component: PurchasePackagesPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.PAYMENT_PACKAGES),
        },
        {
            route: getRoute('payout-return'),
            layout: AccountLayout,
            component: PayoutReturnPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.WITHDRAWALS),
        },
        {
            route: `${getRoute('pay-and-play-login')}/:token`,
            component: PayAndPlayLoginPage,
            loginRequired: false,
        },
        {
            route: getRoute('payout'),
            layout: AccountLayout,
            component: PayoutPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.WITHDRAWALS),
        },
        {
            route: getRoute('tax-statements'),
            layout: AccountLayout,
            component: TaxStatementsPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.PAYMENTS_W2G_REPORT),
        },
        {
            route: getRoute('transactions'),
            layout: AccountLayout,
            component: TransactionsPage,
            loginRequired: true,
        },
        {
            route: getRoute('bet-history'),
            layout: AccountLayout,
            component: BetHistoryPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.SPORTSBOOK),
        },
        {
            route: getRoute('reset-password'),
            component: withLayout(ResetPasswordPage),
        },
        {
            route: `${getRoute('refer')}/:rafCode`,
            component: withLayout(ReferPage),
            disabled: !isFeatureAvailable(FEATURE.REFER_A_FRIEND),
        },
        {
            route: `${getRoute('refer')}`,
            component: withLayout(ReferPage),
            disabled: !isFeatureAvailable(FEATURE.REFER_A_FRIEND),
        },
        {
            route: `${getRoute('league')}/:tournamentId`,
            component: withLayout(LeagueDetailPage),
            disabled: !isFeatureAvailable(FEATURE.LEAGUE),
        },
        {
            route: `${getRoute('league')}`,
            component: withLayout(LeaguePage),
            disabled: !isFeatureAvailable(FEATURE.LEAGUE),
        },
        {
            route: `${getRoute('share')}`,
            component: SharePage,
        },
        {
            route: getRoute('login'),
            component: withLayout(LoginPage),
            disabled: isB2B(),
        },
        {
            route: getRoute('login-redirect'),
            component: withLayout(LoginRedirectPage),
        },
        {
            route: getRoute('how-to-pwa'),
            component: withLayout(HowToPwaPage),
        },
        {
            route: getRoute('inbox'),
            layout: AccountLayout,
            component: InboxPage,
            loginRequired: true,
            disabled: !isFeatureAvailable(FEATURE.INBOX),
        },
        {
            route: getRoute('stop-session'),
            component: withLayout(StopSessionPage),
            loginRequired: true,
        },
        {
            route: getRoute('pre-launch'),
            component: PreLaunchPage,
        },
        {
            layout: AccountLayout,
            route: getRoute('gambling-statistics'),
            component: GamblingStatisticsPage,
            loginRequired: true,
        },
        {
            route: getRoute('geocomply'),
            component: withLayout(GeocomplyPage),
        },
        {
            route: `${getRoute('article-preview')}/:slug`,
            component: ArticlePreviewPage,
        },
        {
            route: getRoute('empty'),
            layout: EmptyLayout,
            component: EmptyPage,
        },
        {
            route: `${getRoute('checkticket')}/:retailTicketId`,
            layout: EmptyLayout,
            component: CheckTicketPage,
            disabled: !isBYOD(),
        },
        {
            route: `${getRoute('unsubscribe')}/:userHash`,
            component: withLayout(EmailUnsubscribePage),
            disabled: !isFeatureAvailable(FEATURE.XTREMEPUSH),
        },
    ].filter((route) => route && !route?.disabled);

    return pages;
}

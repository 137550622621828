import pick from 'lodash/pick';
import sum from 'lodash/sum';
import {
    COMBO_MARKET_ID,
    QUICK_STAKES,
    QUICK_STATION_STAKES,
    QUICK_WYNNBET_STAKES,
    systemBetTypeBySystemKey,
} from '../constants';
import { BET_TYPE } from '../types';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import { getActiveCurrency } from '../../currency';
import { getClient } from '../../environment';
import { getSystemCombinations } from '../system-bet-helpers';
import round from 'lodash/round';
import { environment } from '../../../stores/environment/environment';
import { payoutRoundUp } from '@staycool/cb-round';

function getBetslipStakeSingles(stakeByMarketId, betSlipMarketIdToOutcomeId) {
    const marketIdsInBetslip = Object.values(pick(stakeByMarketId, Object.keys(betSlipMarketIdToOutcomeId)));
    return sum(marketIdsInBetslip.map(Number));
}

function getBetslipStakeCombo(stakeByMarketId) {
    return stakeByMarketId[String(COMBO_MARKET_ID)];
}

function getBetslipStakeComboOrSingles(betType, stakeByMarketId = {}, betSlipMarketIdToOutcomeId = {}) {
    return [BET_TYPE.COMBO, BET_TYPE.TEASER].includes(betType)
        ? getBetslipStakeCombo(stakeByMarketId)
        : getBetslipStakeSingles(stakeByMarketId, betSlipMarketIdToOutcomeId);
}

const getBetslipQuickStakes = (limitAmountOfOption: number): number[] => {
    const currency = getActiveCurrency();
    const quickStakeChoices = {
        station: QUICK_STATION_STAKES,
        wynnbet: QUICK_WYNNBET_STAKES,
        default: QUICK_STAKES,
    };
    const allQuickStakes = quickStakeChoices[getClient()] || quickStakeChoices.default;
    return allQuickStakes[currency].slice(0, limitAmountOfOption);
};

function getStakeValueByMarketId(marketId: string | typeof COMBO_MARKET_ID) {
    const { stakeByMarketId } = getStoreValue(stores.sports.betSlipUserState);
    const stakeValue = (stakeByMarketId as Record<string, number>)[String(marketId)];
    return stakeValue || 0;
}

function getSystemActiveKeys() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
    const systemTextKeysActive = Object.keys(systemBets).map((integerKey) => systemBetTypeBySystemKey[integerKey]);
    return systemTextKeysActive;
}

function getSystemActiveStakes() {
    const systemTextKeysActive = getSystemActiveKeys();
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { systemStakes } = userState;
    return pick(systemStakes, systemTextKeysActive);
}

function getSystemManualAcceptanceActiveStakes() {
    const systemTextKeysActive = getSystemActiveKeys();
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { MASystemStakes } = userState;
    return pick(MASystemStakes, systemTextKeysActive);
}

function getFixedStake(stake: number | string) {
    if (
        !stake ||
        isNaN(Number(stake)) ||
        /[a-zA-Z]/.test(String(stake)) ||
        Number(stake) > Number.MAX_SAFE_INTEGER ||
        Number(stake) < Number.MIN_SAFE_INTEGER
    ) {
        return '0';
    }

    stake = typeof stake === 'number' ? stake.toString() : stake.replace(',', '.');

    const [integerPart, fractionalPart] = stake.split('.');
    const trimmedFractional = (fractionalPart || '').slice(0, 2);

    return fractionalPart || stake.endsWith('.')
        ? `${parseInt(integerPart, 10)}.${trimmedFractional}`
        : `${parseInt(integerPart, 10)}`;
}

const payoutRound = (value: number) => {
    const { FEATURE_ENABLED_PAYOUT_ROUND_UP } = getStoreValue(environment);
    if (FEATURE_ENABLED_PAYOUT_ROUND_UP === 'true') {
        return payoutRoundUp(value);
    }
    return round(value, 2);
};

export {
    getFixedStake,
    payoutRound,
    getBetslipStakeComboOrSingles,
    getBetslipQuickStakes,
    getStakeValueByMarketId,
    getSystemActiveStakes,
    getSystemManualAcceptanceActiveStakes,
};

import { createStores } from './store/utils';

export const maintenance = createStores({
    sportsbookMaintenanceState: {
        liveBet: false,
        preMatch: false,
        endDate: null,
        betslip: false,
    },
    isVirtualSportsHighlightMaintenance: false,
    isPokerMaintenance: false,
    pokerMaintenanceEndDate: undefined as string | undefined,
    isCasinoMaintenance: false,
    casinoMaintenanceEndDate: undefined as string | undefined,
});

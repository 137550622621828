import styled, { css } from 'styled-components';
import { buttonSizes, mediaQuery } from '../../../styles/utils';

type StyledProps = {
    size: 'default' | 'large' | 'small' | 'tiny';
    $block?: boolean;
    selected?: boolean;
    color?: 'default' | 'primary' | 'warning' | 'ghost' | 'outline';
};

export default styled.button<StyledProps>`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    user-select: none;

    border-radius: var(--ui-radius);
    padding: 0 0.8rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    .button-badge {
        position: absolute;
        top: calc(var(--spacing-8) * -1);
        right: calc(var(--spacing-16) * -1);
    }
    > *:not(:last-child) {
        margin-right: var(--spacing-8);
    }
    @media ${mediaQuery.isPhone} {
        font-weight: var(--button-font-weight-mobile);
        ${({ theme }) => theme.optimizeFontRendering};
    }

    ${({ color }) => color && colors[color].css};
    ${({ size }) => size && buttonSizes[size].css};

    ${({ $block }) =>
        $block &&
        css`
            width: 100%;
        `};

    ${({ selected }) => {
        if (selected) {
            return states.active;
        }
        if (selected === undefined) {
            return css`
                .active > & {
                    ${states.active}
                }
            `;
        }
    }};

    &[disabled] {
        pointer-events: none;
        cursor: not-allowed;
        filter: contrast(0.8);
        opacity: 0.6;
        box-shadow: none;
    }

    &[disabled][data-tooltip-content] {
        pointer-events: auto;
    }
`;

const colors = {
    default: {
        css: css`
            background: var(--button-bg);
            color: var(--button-text-color);
            border: var(--button-border);
            box-shadow: var(--button-shadow);
            &:hover {
                background-color: var(--button-bg-hover);
                color: var(--button-text-color-hover);
            }
        `,
    },
    primary: {
        css: css`
            background: var(--button-primary-bg);
            color: var(--button-primary-text-color);
            border: var(--button-primary-border);
            box-shadow: var(--button-primary-shadow);
            &:hover {
                background-color: var(--button-primary-bg-hover);
                color: var(--button-primary-text-color-hover);
            }
        `,
    },
    ghost: {
        css: css`
            background-color: transparent;
            color: var(--button-ghost-text-color);
            border: none;
            box-shadow: none;
            &:hover {
                background-color: var(--button-ghost-bg-hover);
            }
        `,
    },

    outline: {
        css: css`
            background-color: transparent;
            color: var(--button-outline-text-color);
            border: var(--button-outline-border);
            box-shadow: none;
        `,
    },

    warning: {
        css: css`
            background-color: var(--color-lost);
            color: #fff;
            border: 1px solid var(--color-lost);
            box-shadow: none !important;
            &:hover {
                background-color: var(--color-lost);
                color: #fff;
            }
        `,
    },
};

const states = {
    active: css`
        background-color: var(--button-active-bg);
        border: var(--button-active-border);
        box-shadow: var(--button-active-shadow);
        color: var(--button-active-text-color);
        &:hover {
            background-color: var(--button-active-bg-hover);
            color: var(--button-active-text-color-hover);
        }
    `,
};

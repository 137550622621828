import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { getArticleRoute, getRoute } from '../../../../services/router';
import { isFeatureAvailable } from '../../../../services/feature';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import { getTermsAndConditionsRoute } from '../../../../services/users/terms-and-conditions';
import { getPokerDownloadLink } from '../../../../services/poker';
import { openNewTab } from '../../../../services/util';
import { environment } from '../../../../stores/environment/environment';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import { isWithLicence } from '../../../../services/licence';
import { Licence } from '@staycool/location';

export default function CoolbetFooterNavigation() {
    const [{ ZENDESK }] = useStore(environment);
    const footerNavigation = getFooterNavigation();

    function getFooterNavigation() {
        const footerNavigation: {
            title: string;
            menu: {
                title: string;
                url?: string;
                disabled?: boolean;
                externalUrl?: string;
                onClick?: () => void;
                isAvailable?: boolean;
            }[];
            feature: FEATURE;
        }[] = [
            {
                title: translate('Sports', 'ui.footer'),
                menu: [
                    { title: translate('All Sports', 'ui.footer'), url: getRoute('sport.recommendations') },
                    { title: translate('Live Now', 'ui.footer'), url: getRoute('sport.live') },
                    {
                        title: translate('title', 'seo.league'),
                        url: getRoute('league'),
                        disabled: !isFeatureAvailable(FEATURE.LEAGUE),
                    },
                    {
                        title: translate('Virtual Sports', 'ui.common'),
                        url: getRoute('virtual-sports'),
                        disabled: !isFeatureAvailable(FEATURE.VIRTUAL_SPORTS),
                    },
                ],
                feature: FEATURE.SPORTSBOOK,
            },
            {
                title: translate('Casino', 'ui.footer'),
                menu: [
                    { title: translate('Casino Lobby', 'ui.casino'), url: getRoute('casino.slots') },
                    { title: translate('Live Casino', 'ui.footer'), url: getRoute('casino.live') },
                    {
                        title: translate('Casino Race', 'ui.casino'),
                        url: getRoute('casino.race.schedule'),
                        disabled: !isFeatureAvailable(FEATURE.CASINO_RACE),
                    },
                ],
                feature: FEATURE.CASINO,
            },
            {
                title: translate('Poker', 'ui.header'),
                menu: [
                    { title: translate('Poker', 'ui.header'), url: getRoute('poker') },
                    {
                        title: translate('Download', 'ui.poker'),
                        disabled: !getPokerDownloadLink(),
                        onClick: getPokerDownloadLink(),
                    },
                ],
                feature: FEATURE.POKER,
            },
            {
                title: translate('Promotions', 'ui.footer'),
                menu: [
                    {
                        title: translate('VIP Club', 'ui.footer'),
                        url: getRoute('coolbet-vip-club'),
                        disabled: !isFeatureAvailable(FEATURE.VIP_CLUB),
                    },
                    { title: translate('Coolbet Promotions', 'ui.footer'), url: getRoute('promotions') },
                    {
                        title: translate('Refer a friend', 'ui.account'),
                        url: getRoute('refer-a-friend'),
                        disabled: !isFeatureAvailable(FEATURE.REFER_A_FRIEND),
                    },
                    { title: translate('Bonus Rules', 'ui.account'), url: getRoute('promotions.rules') },
                ],
                feature: FEATURE.PROMOTIONS,
            },
            {
                title: translate('Support', 'ui.footer'),
                menu: [
                    {
                        title: translate('Contact us', 'ui.footer'),
                        url: `${getRoute('support')}`,
                    },
                    {
                        title: translate('ui.account.terms---conditions'),
                        url: getTermsAndConditionsRoute(),
                    },
                    {
                        title: translate('ui.account.cookie-policy'),
                        url: getArticleRoute('support-policy-cookie', 'support.policy'),
                        isAvailable: isFeatureAvailable(FEATURE.COOKIE_POLICY),
                    },
                    {
                        title: translate('ui.account.privacy-policy'),
                        url: getArticleRoute('support-policy-privacy', 'support.policy'),
                    },
                    {
                        title: translate('ui.account.faq'),
                        url: ZENDESK?.FAQ ? undefined : getArticleRoute('support-faq', 'support.faq'),
                        ...(ZENDESK?.FAQ && { onClick: () => openNewTab(ZENDESK.FAQ) }),
                    },
                    {
                        title: translate('ui.footer.complaint'),
                        url: `${getRoute('support.complaint')}`,
                        isAvailable: isWithLicence(Licence.PERU),
                    },
                ].filter((item) => item.isAvailable ?? true),
                feature: FEATURE.SUPPORT,
            },
            {
                title: translate('Responsible Gaming', 'ui.footer'),
                menu: [
                    {
                        title: translate('Introduction', 'ui.footer'),
                        url: getRoute('responsible-gaming'),
                        isAvailable: isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING_COMMITMENT),
                    },
                    {
                        title: translate('Set limits', 'ui.account'),
                        url: getRoute('responsible-gaming.set-limits'),
                        isAvailable: isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING),
                    },
                    {
                        title: translate('Betting advices', 'ui.account'),
                        url: getArticleRoute('betting-advices', 'responsible-gaming'),
                        isAvailable: isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING_BETTING_ADVICE),
                    },
                    {
                        title: translate('Self assessment', 'ui.account'),
                        url: getArticleRoute('self-assessment-test', 'responsible-gaming'),
                        isAvailable: isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING_SELF_ASSESSMENT),
                    },
                ].filter((item) => item.isAvailable ?? true),
                feature: FEATURE.RESPONSIBLE_GAMING,
            },
            {
                title: translate('About', 'ui.footer'),
                menu: [
                    // { label: translate('Welcome', 'ui.about'), url: getRoute('about.welcome') },
                    { title: translate('About Us', 'ui.about'), url: getRoute('about.about-us') },
                    {
                        title: translate('Management', 'ui.about'),
                        url: getRoute('about.management'),
                        isAvailable: isFeatureAvailable(FEATURE.MANAGEMENT_PAGE),
                    },
                    { title: translate('Partners', 'ui.about'), url: getRoute('about.partners') },
                    { title: translate('Career', 'ui.about'), externalUrl: 'https://careers.coolbet.ee' },
                    { title: translate('Coolbet Blog', 'ui.footer'), url: getRoute('blog') },
                ].filter((item) => item.isAvailable ?? true),
                feature: FEATURE.ABOUT,
            },
        ];
        return footerNavigation.filter((item) => isFeatureAvailable(item.feature));
    }

    return (
        <Wrapper className="footer-navigation">
            <div className="coolbet-footer-navigation-container">
                {footerNavigation.map((navigationColumn) => (
                    <div className="coolbet-footer-navigation" key={navigationColumn.title}>
                        <div className="coolbet-footer-navigation-title">{navigationColumn.title}</div>
                        <div className="coolbet-footer-navigation-menu">
                            {navigationColumn.menu.map(
                                (menuOption) =>
                                    (!menuOption.disabled && menuOption.url && (
                                        <UiNavLink
                                            className="coolbet-footer-navigation-menu-element"
                                            exact
                                            to={menuOption.url}
                                            key={menuOption.title}
                                        >
                                            {menuOption.title}
                                        </UiNavLink>
                                    )) ||
                                    (!menuOption.disabled && menuOption.externalUrl && (
                                        <a
                                            className="coolbet-footer-navigation-menu-element"
                                            href={menuOption.externalUrl}
                                            key={menuOption.title}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {menuOption.title}
                                        </a>
                                    )) ||
                                    (!menuOption.disabled && menuOption.onClick && (
                                        <div
                                            className="coolbet-footer-navigation-menu-element"
                                            key={menuOption.title}
                                            onClick={menuOption.onClick}
                                        >
                                            {menuOption.title}
                                        </div>
                                    )),
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}

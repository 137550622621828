import { TicketReceipt } from '@staycool/lvdc-types/bets';
import { BetPoolType, LivestreamDetails, RaceBetType } from '../../microservices/lvdc/types';

export interface SaddleColors {
    bg: string;
    borderColor: string;
    textColor?: string;
    textShadow?: string;
}

export const defaultSaddleTowelColor: SaddleColors = {
    bg: '#281B04',
    borderColor: '#281B04',
};

export const SaddleTowelColors: Record<string, Record<number, SaddleColors>> = {
    Thoroughbred: {
        1: {
            bg: '#F22A0D',
            borderColor: '#F22A0D',
        },
        2: {
            bg: '#FFFFFF',
            borderColor: '#F0F0F0',
            textColor: '#000000',
            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
        },
        3: {
            bg: '#0011FF80',
            borderColor: '#336CFF',
        },
        4: {
            bg: '#FFFE00',
            borderColor: '#FFFE00',
            textColor: '#000000',
        },
        5: {
            bg: '#00B01280',
            borderColor: '#00B012',
        },
        6: {
            bg: '#20202780',
            borderColor: '#636363',
            textColor: '#FFFE00',
        },
        7: {
            bg: '#FF6601',
            borderColor: '#FF6601',
            textColor: '#000000',
        },
        8: {
            bg: '#FF99CC',
            borderColor: '#FF99CC',
            textColor: '#000000',
        },
        9: {
            bg: '#02FFFF',
            borderColor: '#02FFFF',
            textColor: '#000000',
        },
        10: {
            bg: '#800080',
            borderColor: '#800080',
        },
        11: {
            bg: '#959595',
            borderColor: '#959595',
            textColor: '#EB3000',
            textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
        },
        12: {
            bg: '#99CC00',
            borderColor: '#99CC00',
            textColor: '#000000',
        },
        13: {
            bg: '#281B04',
            borderColor: '#281B04',
        },
        14: {
            bg: '#5E0A0A',
            borderColor: '#5E0A0A',
            textColor: '#FDF301',
        },
        15: {
            bg: '#DDD9C3',
            borderColor: '#DDD9C3',
            textColor: '#000000',
        },
        16: {
            bg: '#99CCFF',
            borderColor: '#99CCFF',
            textColor: '#CD8408',
        },
        17: {
            bg: '#0B1D31',
            borderColor: '#0B1D31',
        },
        18: {
            bg: '#022206',
            borderColor: '#022206',
            textColor: '#FCF803',
        },
        19: {
            bg: '#003399',
            borderColor: '#003399',
            textColor: '#000000',
        },
        20: {
            bg: '#FF3399',
            borderColor: '#FF3399',
            textColor: '#FC0313',
        },
        21: {
            bg: '#B5B3D9',
            borderColor: '#B5B3D9',
            textColor: '#000000',
        },
        22: {
            bg: '#10446D',
            borderColor: '#10446D',
        },
        23: {
            bg: '#BDD6CD',
            borderColor: '#BDD6CD',
            textColor: '#000000',
        },
        24: {
            bg: '#41502C',
            borderColor: '#41502C',
        },
    },
    Harness: {
        1: {
            bg: '#F22A0D',
            borderColor: '#F22A0D',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        2: {
            bg: '#014ABB',
            borderColor: '#014ABB',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        3: {
            bg: '#FFFFFF',
            borderColor: '#000000',
            textColor: '#000000',
        },
        4: {
            bg: '#177E27',
            borderColor: '#177E27',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        5: {
            bg: '#070707',
            borderColor: '#070707',
        },
        6: {
            bg: '#F3D406',
            borderColor: '#F3D406',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        7: {
            bg: '#EE2085',
            borderColor: '#EE2085',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        8: {
            bg: '#BCB4B2',
            borderColor: '#BCB4B2',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        9: {
            bg: '#6F0A9A',
            borderColor: '#6F0A9A',
        },
        10: {
            bg: 'linear-gradient(to right bottom, #F22B0D 50%, #014ABB 50.3%)',
            borderColor: '#800080',
        },
        11: {
            bg: '#0995B1',
            borderColor: '#0995B1',
        },
        12: {
            bg: 'linear-gradient(to right bottom, #FFFFFF 50%, #F22B0D 50.3%)',
            borderColor: '#000000',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
    },
    Greyhound: {
        1: {
            bg: '#FF0201',
            borderColor: '#FF0201',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        2: {
            bg: '#176BAF',
            borderColor: '#176BAF',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        3: {
            bg: '#FFFFFF',
            borderColor: '#000000',
            textColor: '#000000',
        },
        4: {
            bg: '#177E27',
            borderColor: '#177E27',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        5: {
            bg: '#000000',
            borderColor: '#000000',
            textColor: '#FF0201',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        6: {
            bg: '#FFF326',
            borderColor: '#FFF326',
            textColor: '#000000',
        },
        7: {
            borderColor: '#FFFFFF',
            bg: 'repeating-linear-gradient(to bottom, #177E27 0%, #177E27 20%, #FFFFFF 20%, #FFFFFF 40%)',
            textColor: '#000000',
        },
        8: {
            borderColor: '#000000',
            bg: 'repeating-linear-gradient(to right, #000000 0%, #000000 20%, #FFF326 20%, #FFF326 40%)',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
        },
        9: {
            bg: 'linear-gradient(to right bottom, #FFFFFF 50%, #CA4CF7 50.3%)',
            textColor: '#000000',
            borderColor: '#CA4CF7',
        },
    },
    'Quarter horse': {
        1: {
            bg: '#F22A0D',
            borderColor: '#F22A0D',
        },
        2: {
            bg: '#FFFFFF',
            borderColor: '#F0F0F0',
            textColor: '#000000',
            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
        },
        3: {
            bg: '#0011FF80',
            borderColor: '#336CFF',
        },
        4: {
            bg: '#FFFE00',
            borderColor: '#FFFE00',
            textColor: '#000000',
        },
        5: {
            bg: '#00B01280',
            borderColor: '#00B012',
        },
        6: {
            bg: '#20202780',
            borderColor: '#636363',
            textColor: '#FFFE00',
        },
        7: {
            bg: '#FF6601',
            borderColor: '#FF6601',
            textColor: '#000000',
        },
        8: {
            bg: '#FF99CC',
            borderColor: '#FF99CC',
            textColor: '#000000',
        },
        9: {
            bg: '#02FFFF',
            borderColor: '#02FFFF',
            textColor: '#000000',
        },
        10: {
            bg: '#800080',
            borderColor: '#800080',
        },
        11: {
            bg: '#959595',
            borderColor: '#959595',
            textColor: '#EB3000',
            textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
        },
        12: {
            bg: '#99CC00',
            borderColor: '#99CC00',
            textColor: '#000000',
        },
        13: {
            bg: '#281B04',
            borderColor: '#281B04',
        },
        14: {
            bg: '#5E0A0A',
            borderColor: '#5E0A0A',
            textColor: '#FDF301',
        },
        15: {
            bg: '#DDD9C3',
            borderColor: '#DDD9C3',
            textColor: '#000000',
        },
        16: {
            bg: '#99CCFF',
            borderColor: '#99CCFF',
            textColor: '#CD8408',
        },
        17: {
            bg: '#0B1D31',
            borderColor: '#0B1D31',
        },
        18: {
            bg: '#022206',
            borderColor: '#022206',
            textColor: '#FCF803',
        },
        19: {
            bg: '#003399',
            borderColor: '#003399',
            textColor: '#000000',
        },
        20: {
            bg: '#FF3399',
            borderColor: '#FF3399',
            textColor: '#FC0313',
        },
        21: {
            bg: '#B5B3D9',
            borderColor: '#B5B3D9',
            textColor: '#000000',
        },
        22: {
            bg: '#10446D',
            borderColor: '#10446D',
        },
        23: {
            bg: '#BDD6CD',
            borderColor: '#BDD6CD',
            textColor: '#000000',
        },
        24: {
            bg: '#41502C',
            borderColor: '#41502C',
        },
    },
};

export const initialHorseRacingBetslipState = {
    nevadaTrackId: 0,
    nevadaTrackName: '',
    raceId: 1,
    betType: {} as RaceBetType,
    breed: 'Thoroughbred',
    runnerMaps: [] as number[][],
    totalStake: 0,
    betStake: 0,
    card: 0,
    races: [] as number[],
    totalCombinations: 0,
    date: '',
};

export const initialHorseRacingBetslipPlacingState = {
    isLoading: false,
    receipt: {} as TicketReceipt,
};

export const initialLivestreamDetails: LivestreamDetails = { code: null, is_stream_available: false };

export function consecutiveRanges(arr: number[]) {
    if (!arr.length) {
        return [];
    }

    let i: number, val: number;
    let idx: number = 0;
    let str = '';
    const len = arr.length;

    while (true) {
        i = 0;
        str += val = arr[idx++];

        while (idx + i < len && arr[idx + i] === val + i + 1) {
            i++;
        }
        if (i > 1) {
            str += `-${arr[(idx += i) - 1]}`;
        }
        if (idx === len) {
            return str.split('|');
        }
        str += '|';
    }
}

export type TrackPageTab = 'race' | 'pools' | 'results' | 'replay';

export const orderedBasicPoolTypes: BetPoolType[] = ['WIN', 'PLC', 'SHW'];

import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import { COMBO_MARKET_ID } from '../constants';
import { BET_TYPE } from '../types';
import { isOpenbetAvailable } from '../../bet-builder';
import { convertOdds, getPreciseOdds } from '../../odds-format';
import { loadOddsByMarketIds } from '../../../microservices/sb-odds';
import { payoutRound } from '../betslip';
import { getStakeValueByMarketId } from '../betslip';
import isEmpty from 'lodash/isEmpty';

function hasBetSlipChangedFromOdds(state) {
    if (!state) {
        return '';
    }

    const outcomeIds = Object.values(getStoreValue(stores.sports.betSlipMarketIdToOutcomeId));

    return outcomeIds
        .map((outcomeId) => (state[outcomeId] ? state[outcomeId].odds_id : `loading_${outcomeId}`))
        .sort()
        .join();
}

function getAllOdds(marketId) {
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const marketIdToOutcomeId =
        marketId && marketId !== String(COMBO_MARKET_ID)
            ? { [marketId]: betSlipMarketIdToOutcomeId[marketId] }
            : betSlipMarketIdToOutcomeId;
    return Object.values(marketIdToOutcomeId)
        .map((outcomeId) => oddsByOutcomeId[outcomeId]?.value)
        .filter((oddsValue): oddsValue is number => !!oddsValue);
}

function calculateTotalOdds(marketId) {
    const betSlipUserState = getStoreValue(stores.sports.betSlipUserState);
    if (betSlipUserState.betType === BET_TYPE.TEASER) {
        return getTeaserTotalOdds();
    }
    if (isOpenbetAvailable() && betSlipUserState.betType === BET_TYPE.COMBO) {
        return getComboBetbuilderTotalOdds();
    }
    const allOddsValues = getAllOdds(marketId);
    return allOddsValues.reduce((totalOdds, oddsValue) => totalOdds * getPreciseOdds(convertOdds(oddsValue)), 1);
}

function getComboBetbuilderTotalOdds() {
    const selectedOutcomesByFixtureId = getStoreValue(stores.sports.customBetbuilderV2.selectedOutcomesByFixtureId);
    if (!isEmpty(selectedOutcomesByFixtureId)) {
        const betbuilderComboPriceByMatchId = getStoreValue(
            stores.sports.customBetbuilderV2.betbuilderComboPriceByMatchId,
        );
        let isSomeOddsInfoMissing = false;
        const totalOdds = Object.keys(selectedOutcomesByFixtureId).reduce((totalOddsAcc, fixtureId) => {
            const { matchId } = getStoreValue(stores.sports.customBetbuilderV2.matchInfoByFixtureId)[fixtureId];
            const price = betbuilderComboPriceByMatchId[matchId];
            if (!price) {
                isSomeOddsInfoMissing = true;
                return totalOddsAcc;
            }
            return totalOddsAcc * getPreciseOdds(convertOdds(price));
        }, 1);
        return isSomeOddsInfoMissing ? 0 : totalOdds;
    }
    const betslipMarketIdsByMatchId = getStoreValue(stores.sports.customBetbuilder.betslipMarketIdsByMatchId);
    const betbuilderMarketOddsByMarketId = getStoreValue(stores.sports.customBetbuilder.betbuilderMarketOddsByMarketId);
    const comboBetMarketIdByMatchId = getStoreValue(stores.sports.customBetbuilder.comboBetMarketIdByMatchId);
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    let isSomeOddsInfoMissing = false;
    const totalOdds = Object.keys(betslipMarketIdsByMatchId).reduce((totalOddsAcc, matchId) => {
        const marketId = comboBetMarketIdByMatchId[matchId];
        if (!marketId) {
            isSomeOddsInfoMissing = true;
            return totalOddsAcc;
        }
        if (betbuilderMarketOddsByMarketId[marketId]) {
            return totalOddsAcc * getPreciseOdds(convertOdds(betbuilderMarketOddsByMarketId[marketId]));
        }
        return totalOddsAcc * getPreciseOdds(convertOdds(oddsByOutcomeId[betSlipMarketIdToOutcomeId[marketId]]?.value));
    }, 1);
    return isSomeOddsInfoMissing ? 0 : totalOdds;
}

function getTeaserTotalOdds() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const selectionsCount = Object.keys(betSlipMarketIdToOutcomeId).length;
    const teaserPoints = getStoreValue(stores.sports.teaserSelectedPoint);
    const teaserPayouts = getStoreValue(stores.sports.teaserPayouts);
    const teaserPayout = teaserPayouts?.payout_odds?.find(
        (payout) => payout.points === teaserPoints && payout.selections_amount === selectionsCount,
    );
    return teaserPayout?.odds || 0;
}

async function calculateTotalOddsWithoutOddsInStore(marketIds: number[], outcomeIds: number[]) {
    const currentOddsByOutcomeIds = await loadOddsByMarketIds(marketIds);
    const allOdds = outcomeIds.map((outcomeId) => currentOddsByOutcomeIds[outcomeId].value);
    return allOdds.reduce((totalOdds, oddsValue) => totalOdds * getPreciseOdds(convertOdds(oddsValue)), 1);
}

function calculateMaxWinByMarketId(marketId: string | typeof COMBO_MARKET_ID) {
    const stakeValue = getStakeValueByMarketId(marketId);
    const totalOdds = calculateTotalOdds(marketId);
    return payoutRound(totalOdds * stakeValue);
}

export {
    hasBetSlipChangedFromOdds,
    getAllOdds,
    calculateTotalOdds,
    getTeaserTotalOdds,
    calculateTotalOddsWithoutOddsInStore,
    calculateMaxWinByMarketId,
};

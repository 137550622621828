import omit from 'lodash/omit';
import some from 'lodash/some';
import { placeBetRequest, getLatestTicketId } from '../../../microservices/bets';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import { isFeatureAvailable } from '../../feature';
import {
    hasErrorsByMarketId,
    getSystemActiveStakes,
    getSystemManualAcceptanceActiveStakes,
    isWarning,
    isInfo,
    addBetslipError,
    getErrorCode,
    removeAllNonFrontendErrors,
    hasLiveMatchesInBetslip,
    clearBetslip,
    addComboCardBetslipError,
} from '../betslip';
import {
    COMBO_MARKET_ID,
    LIMIT_EXCEEDED_ERROR,
    DUPLICATE_TICKET_ERROR,
    GEOCOMPLY_FAILED_ERROR,
    initialBetSlipUserState,
    MA_DISABLED_ERROR,
    MARKET_SPECIFIC_ERRORS,
    ODDS_CHANGED_ERROR,
    ODDS_CHANGED_ERROR_SB_ODDS,
    ODDS_CLOSED_ERRORS_BACKEND,
    ODDS_HEARTBEAT_DOWN,
    GEOCOMPLY_EXPIRED_ERROR,
    GEOCOMPLY_INVALID_ID_ERROR,
} from '../constants';
import { hasBetslipManualAcceptanceError, handleIfManualAcceptance } from '../manual-acceptance-helpers';
import { BET_TYPE } from '../types';
import type { PlaceBetHandle, PlaceBetRequest, PlaceComboCardBetRequest } from '../types';
import { FEATURE } from '../../types';
import { isOpenbetComboBetslip, isOpenbetAvailable } from '../../bet-builder';
import every from 'lodash/every';
import pick from 'lodash/pick';
import sum from 'lodash/sum';
import { getMinStake } from '../limits';
import mapValues from 'lodash/mapValues';
import { getActiveCurrency } from '../../currency';
import { Language } from '../../language/types';
import { Currency } from '../../wallet/types';
import { getOddsFormat } from '../user-settings';
import { getSportsLayout } from '../../layout/utils';
import { SportsLayout } from '../../layout/types';
import { getDeviceUuid } from '../../fingerprint';
import invert from 'lodash/invert';
import { flushSync } from 'react-dom';
import { postBettingContext } from '../../../microservices/analytics';
import { loadOddsByMarketIds } from '../../../microservices/sb-odds';
import { requestLogin } from '../../auth';
import { verifyGeoLocation } from '../../geocomply/geocomply';
import { logger } from '../../logger';
import { NativeMessageEventType, sendNativeEvent } from '../../mobile-app';
import { translate } from '../../translate';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { storageGet, storageSet } from '../../storage';
import { LocalStorage } from '../../local-storage/types';

function isBetslipButtonDisabled() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const comboBetMarketIdByMatchId = getStoreValue(stores.sports.customBetbuilder.comboBetMarketIdByMatchId);
    const { betType, stakeByMarketId, MAStakeByMarketId } = getStoreValue(stores.sports.betSlipUserState);
    const marketIdsActive =
        isOpenbetAvailable() && betType === BET_TYPE.COMBO
            ? Object.values(comboBetMarketIdByMatchId)
            : Object.keys(betSlipMarketIdToOutcomeId);
    const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
    const isComboError = () =>
        Boolean(
            Object.values(pick(betSlipErrorByMarketId, marketIdsActive))
                .flat()
                .filter((error) => !isWarning(error) && !isInfo(error)).length,
        );
    const excludedErrorCodes = [LIMIT_EXCEEDED_ERROR];
    const genericErrors =
        betSlipErrorByMarketId[String(COMBO_MARKET_ID)]?.filter(
            (err) => !isWarning(err) && !isInfo(err) && !excludedErrorCodes.includes(err.code),
        ) || [];
    const isSingleBetsError = () => every(marketIdsActive, hasErrorsByMarketId);
    const isMarketError = [BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType) ? isSingleBetsError : isComboError;
    const minStake = getMinStake();
    if (!minStake) {
        return true;
    }
    const hasValidStakes = () => {
        const getStake = (marketId) =>
            (Number(stakeByMarketId[marketId]) || 0) + (Number(MAStakeByMarketId[marketId]) || 0);
        if (betType === BET_TYPE.SYSTEM) {
            const systemStakes = Object.values({
                ...getSystemActiveStakes(),
                ...getSystemManualAcceptanceActiveStakes(),
            }).map(Number);
            return sum(systemStakes) >= minStake;
        }
        if (betType === BET_TYPE.COMBO) {
            return getStake(COMBO_MARKET_ID) >= minStake;
        }
        if (betType === BET_TYPE.TEASER) {
            return getStake(COMBO_MARKET_ID) >= minStake;
        }
        const marketIdsNoError = (marketIdsActive as any).filter((marketId) => !hasErrorsByMarketId(marketId));
        return some(marketIdsNoError, (marketId) => getStake(marketId) >= minStake);
    };
    return (!hasBetslipManualAcceptanceError() && genericErrors.length) || isMarketError() || !hasValidStakes();
}

function createBetPlaceDataObjectFromStoreByMarketId(
    marketId: string,
    isManualAcceptance = false,
    isForceDuplicate = false,
) {
    const oddsIdByOutcomeId = {};
    const foTranslationsByOutcomeId = {};
    const bankerOddsIdByOutcomeId = {};
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { betType, stakeByMarketId, acceptAnyOddsChanges, MAStakeByMarketId, copiedFrom } = userState;
    const betSlipMarketIdToOutcomeId = isOpenbetComboBetslip(betType)
        ? getStoreValue(stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId)
        : getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    const marketInfoById = isOpenbetComboBetslip(betType)
        ? getStoreValue(stores.sports.customBetbuilder.betbuilderMarketInfoById)
        : getStoreValue(stores.sports.marketInfoById);
    const language = getStoreValue(stores.language);
    const deviceId = getDeviceUuid();

    const marketIds = Object.keys(betSlipMarketIdToOutcomeId);

    (marketId && marketId !== String(COMBO_MARKET_ID) ? [marketId] : marketIds).forEach((marketId) => {
        const outcomeId = betSlipMarketIdToOutcomeId[marketId];
        const outcome = marketInfoById[marketId].outcomes.find((outcome) => outcome.id === Number(outcomeId));
        foTranslationsByOutcomeId[outcomeId] = {
            outcomeName: outcome ? outcome.name : '',
            marketName: marketInfoById[marketId].marketName,
            matchName: marketInfoById[marketId].match_name,
        };
        oddsIdByOutcomeId[outcomeId] = oddsByOutcomeId[outcomeId]?.odds_id;
    });
    const realTicketType = betType === BET_TYPE.BETBUILDER ? BET_TYPE.SINGLE : betType;
    const data = {
        ticketType: realTicketType,
        timestamp: new Date(),
        acceptAnyOddsChanges,
        copiedFrom,
        foTranslationsByOutcomeId,
        oddsFormat: getOddsFormat(),
        layout: getSportsLayout(),
        isForceDuplicate,
        deviceId,
        language,
        betslipCode: userState.betslipCode,
    };

    if (isFeatureAvailable(FEATURE.SEND_CURRENCY)) {
        data['currency'] = getActiveCurrency();
    }

    if (betType === BET_TYPE.SYSTEM) {
        const systemStakes = getSystemActiveStakes();
        data['systemBet'] = {
            oddsIdByOutcomeId,
            systemStakes: mapValues(systemStakes, (x) => (x ? Number(x) : undefined)),
        };
        if (isManualAcceptance) {
            const maSystemStakes = getSystemManualAcceptanceActiveStakes();
            data['systemBet']['maSystemStakes'] = mapValues(maSystemStakes, (x) => (x ? Number(x) : undefined));
        }
        data['systemBet']['bankerOddsIdByOutcomeId'] = bankerOddsIdByOutcomeId;
    } else {
        const stake = Number(stakeByMarketId[marketId]);
        const bet = { stake, oddsIdByOutcomeId };
        if (isManualAcceptance && MAStakeByMarketId[marketId]) {
            bet['stakeRequest'] = Number(MAStakeByMarketId[marketId]);
        }
        data['bets'] = [bet];
    }

    if (betType === BET_TYPE.TEASER) {
        data['teaserPoints'] = getStoreValue(stores.sports.teaserSelectedPoint);
    }

    const betbuilderBetslipIdByMarketIds = storageGet(LocalStorage.BETBUIDLER_BETSLIP_ID_BY_MARKET_ID, {});

    if (Object.values(betbuilderBetslipIdByMarketIds).length) {
        if (betType === BET_TYPE.BETBUILDER || betType === BET_TYPE.SINGLE) {
            const betbuilderBetslipId = betbuilderBetslipIdByMarketIds[Number(marketId)];
            if (betbuilderBetslipId) {
                data['betbuilderIdByMarketId'] = { [Number(marketId)]: betbuilderBetslipId };
            }
        } else if (betType === BET_TYPE.COMBO) {
            data['betbuilderIdByMarketId'] = betbuilderBetslipIdByMarketIds;
        }
    }

    if (isOpenbetComboBetslip(betType)) {
        data['openbetBetbuilderBetslipMarketIds'] = marketIds;
    }

    return data as {
        ticketType: BET_TYPE;
        timestamp: Date;
        acceptAnyOddsChanges: boolean;
        copiedFrom: string | null;
        oddsFormat: string;
        layout: SportsLayout;
        foTranslationsByOutcomeId: Record<string, unknown>;
        systemBet?: {
            oddsIdByOutcomeId?: string;
            systemStakes?: Record<string, number>;
            maSystemStakes?: Record<string, number>;
            bankerOddsIdByOutcomeId?: Record<string, number>;
        };
        oddsIdByOutcomeId: string;
        bets?: { stake: number; oddsIdByOutcomeId: Record<string, number>; stakeRequest?: number }[];
        forceDuplicate?: boolean;
        isForceDuplicate: boolean;
        deviceId: string | null;
        language: Language;
        currency?: Currency;
        betslipCode?: string;
    };
}

async function createDataAndPlaceBet(marketId: string, isManualAcceptance: boolean, isForceDuplicate: boolean) {
    const betAction = placeBetRequest;
    const data = createBetPlaceDataObjectFromStoreByMarketId(marketId, isManualAcceptance, isForceDuplicate);
    stores.sports.betSlipErrorByMarketId.set((state) => {
        // key "null" is a combo bet "marketId"
        if (marketId && marketId !== 'null') {
            delete state[marketId];
        } else {
            return {};
        }
    });
    // TODO: remove after proper fix will be deployed in b2b envs
    if (isFeatureAvailable(FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET)) {
        if (
            data.ticketType !== BET_TYPE.SYSTEM &&
            data.bets &&
            data.bets[0].stake > 0 &&
            data.bets[0].stakeRequest &&
            data.bets[0].stakeRequest > 0
        ) {
            const bet = data.bets[0];
            const normalBetData = { ...data, bets: [omit(bet, 'stakeRequest')], isForceDuplicate: true };
            const manualAcceptanceBetData = { ...data, bets: [{ ...bet, stake: 0 }] };
            const maTicketId = await betAction(manualAcceptanceBetData);
            stores.sports.betSlipPlacingState.set((state) => {
                state.receiptById[String(marketId) + '-ma'] = maTicketId;
            });
            return await betAction(normalBetData);
        } else if (
            data.ticketType === BET_TYPE.SYSTEM &&
            data.systemBet &&
            Object.keys(data.systemBet.systemStakes ?? {}).length > 0 &&
            Object.keys(data.systemBet.maSystemStakes ?? {}).length > 0
        ) {
            const bet = data.systemBet;
            const normalBetData = { ...data, systemBet: omit(bet, 'maSystemStakes'), isForceDuplicate: true };
            const manualAcceptanceBetData = { ...data, systemBet: { ...bet, systemStakes: {} } };
            const maTicketId = await betAction(manualAcceptanceBetData);
            stores.sports.betSlipPlacingState.set((state) => {
                state.receiptById[String(marketId) + '-ma'] = maTicketId;
            });
            return await betAction(normalBetData);
        }
    }
    return await betAction(data);
}

function placeBetWrap(placeBetRequest: PlaceBetRequest): PlaceBetHandle {
    return async (isManualAcceptance = false, allowErrorForMarket = false, isForceDuplicate = false) => {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        const betSlipUserState = getStoreValue(stores.sports.betSlipUserState);
        const betSlipPlacingState = getStoreValue(stores.sports.betSlipPlacingState);
        const betSlipMarketIdToOutcomeId = isOpenbetComboBetslip(betSlipUserState.betType)
            ? getStoreValue(stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId)
            : getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
        const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
        const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
        const manualAcceptanceDisabled = some(genericErrors, (error) => getErrorCode(error) === MA_DISABLED_ERROR);

        if (!isAuthenticated) {
            requestLogin();
            return;
        }

        if (
            !hasLiveMatchesInBetslip() &&
            !betSlipPlacingState.isConfirmed &&
            !isManualAcceptance &&
            !allowErrorForMarket &&
            isFeatureAvailable(FEATURE.BETSLIP_CONFIRM) &&
            !manualAcceptanceDisabled
        ) {
            stores.sports.betSlipPlacingState.set({ ...betSlipPlacingState, needsConfirm: true });
            return true;
        }
        await verifyGeoLocation('BET');
        const { betType } = betSlipUserState;
        const betSlipMarketIds = Object.keys(betSlipMarketIdToOutcomeId);
        let marketIdsToDoPlaceRequestFor = [String(COMBO_MARKET_ID)];
        if ([BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType)) {
            marketIdsToDoPlaceRequestFor = betSlipMarketIds.filter(
                (marketId) => !hasErrorsByMarketId(marketId) || allowErrorForMarket,
            );
        }

        if (!marketIdsToDoPlaceRequestFor.length && !isForceDuplicate) {
            return true;
        }

        flushSync(() => {
            stores.sports.betSlipPlacingState.set({
                ...betSlipPlacingState,
                isLoading: true,
                needsConfirm: false,
                needsConfirmDuplicate: false,
            });
        });
        removeAllNonFrontendErrors();
        const marketIdByOutcomeId = invert(betSlipMarketIdToOutcomeId);
        let isError = false;
        const marketIds: string[] = [];
        // needs to be sequential
        while (marketIdsToDoPlaceRequestFor.length) {
            const ticketSubmitTime = new Date().getTime();
            const marketId = marketIdsToDoPlaceRequestFor.pop() as string;
            try {
                const ticketId = await placeBetRequest(marketId, isManualAcceptance, isForceDuplicate);
                stores.sports.betSlipPlacingState.set((state) => {
                    state.receiptById[String(marketId)] = ticketId;
                });
            } catch (error: any) {
                isError = true;
                if (error.meta && MARKET_SPECIFIC_ERRORS === error.code) {
                    Object.keys(error.meta).forEach((outcomeId) => {
                        let errorCode = error.meta[outcomeId].code;
                        if (ODDS_CLOSED_ERRORS_BACKEND.includes(errorCode)) {
                            errorCode = ODDS_CLOSED_ERRORS_BACKEND[0];
                            marketIds.push(marketIdByOutcomeId[outcomeId]);
                        }
                        if ([ODDS_CHANGED_ERROR, ODDS_HEARTBEAT_DOWN].includes(errorCode)) {
                            marketIds.push(marketIdByOutcomeId[outcomeId]);
                        }
                        addBetslipError(marketIdByOutcomeId[outcomeId], errorCode);
                    });
                    loadOddsByMarketIds(marketIds);
                } else if (error.code === DUPLICATE_TICKET_ERROR) {
                    addBetslipError(marketId, error);
                    stores.sports.betSlipPlacingState.set({ ...betSlipPlacingState, needsConfirmDuplicate: true });
                } else if (error.code === GEOCOMPLY_FAILED_ERROR) {
                    sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_ERROR });
                    addBetslipError(COMBO_MARKET_ID, error);
                } else if (error.code === GEOCOMPLY_EXPIRED_ERROR) {
                    sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_EXPIRED_ERROR });
                    addBetslipError(COMBO_MARKET_ID, error);
                } else if (error.code === GEOCOMPLY_INVALID_ID_ERROR) {
                    sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_INVALID_ID_ERROR });
                    addBetslipError(COMBO_MARKET_ID, error);
                } else if (!error.code || error.code === 1500) {
                    try {
                        const ticketId = await getLatestTicketId(ticketSubmitTime);
                        stores.sports.betSlipPlacingState.set((state) => {
                            state.receiptById[String(marketId)] = ticketId;
                        });
                    } catch (error) {
                        addBetslipError(null, translate('Something went wrong', 'ui.common'));
                        logger.info('SportsBetslipBetPlacementService', 'placeBetWrap', error);
                    }
                } else if (error.code !== ODDS_CHANGED_ERROR_SB_ODDS) {
                    addBetslipError(COMBO_MARKET_ID, error);
                    handleIfManualAcceptance(error, betType);
                }
                logger.info('SportsBetslipBetPlacementService', 'placeBetWrap', `marketId: ${marketId}`);
                logger.info('SportsBetslipBetPlacementService', 'placeBetWrap', error);
            }
        }

        const { receiptById } = getStoreValue(stores.sports.betSlipPlacingState);
        const placedMarketIds = Object.keys(receiptById);
        if (placedMarketIds.length) {
            if (receiptById[String(COMBO_MARKET_ID)]) {
                flushSync(() => {
                    clearBetslip();
                });
            } else {
                flushSync(() => {
                    stores.sports.betSlipMarketIdToOutcomeId.set((state) => omit(state, placedMarketIds));
                });
            }
            stores.sports.betSlipUserState.set((state) => ({
                ...initialBetSlipUserState,
                stakeByMarketId: omit(state.stakeByMarketId, placedMarketIds.concat(String(COMBO_MARKET_ID))),
            }));
            storageSet(LocalStorage.BETBUIDLER_BETSLIP_ID_BY_MARKET_ID, {});
        }

        stores.sports.betSlipPlacingState.set((state) => ({ ...state, isLoading: false, isConfirmed: false }));
        if (!isError) {
            if (some(stores.sports.bonusBetsSelection.state)) {
                stores.sports.bonusBetsSelection.set({});
            }
            isFeatureAvailable(FEATURE.TICKET_TRACKING) &&
                placedMarketIds.forEach(async (marketId) => {
                    const ticket_id = receiptById[marketId];
                    const outcomeId =
                        betSlipMarketIdToOutcomeId[marketId] || Object.values(betSlipMarketIdToOutcomeId)[0];
                    const bettingContext = getStoreValue(stores.sports.bettingContextByOutcomeId)[outcomeId];
                    if (bettingContext) {
                        await postBettingContext({ ticket_id, context: bettingContext });
                    }
                });
        }
        return isError;
    };
}

async function createComboCardsDataAndPlaceBets(cardId: number, isForceDuplicate: boolean) {
    const betAction = placeBetRequest;
    const cardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);
    const cardToBetOn = cardsInBetslip.find((card) => card.id === Number(cardId));
    if (!cardToBetOn) {
        return '';
    }
    const comboCardData = createComboCardBetPlaceDataObject(cardToBetOn, isForceDuplicate);
    return await betAction(comboCardData);
}

function createComboCardBetPlaceDataObject(card: FoComboCardWithOdds, isForceDuplicate: boolean) {
    const { acceptAnyOddsChanges, betslipCode } = getStoreValue(stores.sports.comboCard.betslipState);

    if (!card) {
        return;
    }

    const language = getStoreValue(stores.language);
    const deviceId = getDeviceUuid();
    const [oddsByOutcomeId, marketInfoById] = [
        getStoreValue(stores.sports.oddsByOutcomeId),
        getStoreValue(stores.sports.marketInfoById),
    ];
    const stakeByCardId = getStoreValue(stores.sports.comboCard.stakeByCardId);

    const foTranslationsByOutcomeId = {};
    const cardMarkets = card.matches.flatMap(({ markets }) => markets);
    cardMarkets.forEach(({ outcome_name, outcome_id, id }) => {
        const { marketName, match_name } = marketInfoById[id];
        foTranslationsByOutcomeId[outcome_id] = {
            outcomeName: outcome_name,
            matchName: match_name,
            marketName,
        };
    });
    const stake = Number(stakeByCardId[card.id]);
    const cardOddsIdByOutcomeIdObjects = cardMarkets.map(({ outcome_id }) => ({
        [outcome_id]: oddsByOutcomeId[outcome_id]?.odds_id,
    }));
    const bet = { stake, oddsIdByOutcomeId: Object.assign({}, ...cardOddsIdByOutcomeIdObjects) };

    const data = {
        ticketType: BET_TYPE.COMBO_CARD,
        timestamp: new Date().toString(),
        acceptAnyOddsChanges,
        copiedFrom: null,
        betslipCode,
        foTranslationsByOutcomeId,
        bets: [bet],
        comboCardId: card.id,
        oddsFormat: getOddsFormat(),
        layout: getSportsLayout(),
        isForceDuplicate,
        deviceId,
        language,
    };

    if (isFeatureAvailable(FEATURE.SEND_CURRENCY)) {
        data['currency'] = getActiveCurrency();
    }

    return data;
}

function placeComboCardBetWrap(placeBetRequest: PlaceComboCardBetRequest): PlaceBetHandle {
    return async (isForceDuplicate = false) => {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);

        const cardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);
        const stakeByCardId = getStoreValue(stores.sports.comboCard.stakeByCardId);
        const cardsToBetOn = cardsInBetslip.filter(({ id }) => Number(stakeByCardId[id]));

        const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipComboCardMarketIdToOutcomeId);

        if (!isAuthenticated) {
            requestLogin();
            return;
        }

        await verifyGeoLocation('BET');

        removeAllNonFrontendErrors();
        let isError = false;

        if (!cardsToBetOn.length) {
            return true;
        }

        await Promise.all(
            cardsToBetOn.map(async ({ id: cardId }) => {
                const ticketSubmitTime = new Date().getTime();
                try {
                    stores.sports.comboCard.betslipState.set((state) => ({ ...state, isLoading: true }));
                    const ticketId = await placeBetRequest(cardId, isForceDuplicate);
                    stores.sports.comboCard.receiptByCardId.set((state) => ({ ...state, [cardId]: ticketId }));
                    stores.sports.comboCard.stakeByCardId.set((state) => ({ ...state, [cardId]: undefined }));
                } catch (error: any) {
                    isError = true;

                    // Based on placeBetWrap logic.
                    const isMarketSpecificErrorWithMeta = error.meta && MARKET_SPECIFIC_ERRORS === error.code;
                    const isGeoComplyError = [
                        GEOCOMPLY_FAILED_ERROR,
                        GEOCOMPLY_EXPIRED_ERROR,
                        GEOCOMPLY_INVALID_ID_ERROR,
                    ].includes(error.code);
                    const isFalsePositive = !error.code || error.code === 1500;
                    const isNotOddsChangedError = error.code !== ODDS_CHANGED_ERROR_SB_ODDS;

                    if (isMarketSpecificErrorWithMeta) {
                        Object.keys(error.meta).forEach((outcomeId) => {
                            let errorCode = error.meta[outcomeId].code;
                            if (ODDS_CLOSED_ERRORS_BACKEND.includes(errorCode)) {
                                errorCode = ODDS_CLOSED_ERRORS_BACKEND[0];
                            }
                            addComboCardBetslipError(cardId, errorCode);
                        });
                        loadOddsByMarketIds(Object.keys(betSlipMarketIdToOutcomeId));
                    } else if (error.code === DUPLICATE_TICKET_ERROR) {
                        addComboCardBetslipError(cardId, error);
                        stores.sports.comboCard.betslipState.set((state) => ({
                            ...state,
                            needsConfirmDuplicate: true,
                        }));
                    } else if (isGeoComplyError) {
                        switch (error.code) {
                            case GEOCOMPLY_FAILED_ERROR:
                                sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_ERROR });
                                break;
                            case GEOCOMPLY_EXPIRED_ERROR:
                                sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_EXPIRED_ERROR });
                                break;
                            case GEOCOMPLY_INVALID_ID_ERROR:
                                sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_INVALID_ID_ERROR });
                                break;
                        }
                        addComboCardBetslipError(cardId, error);
                    } else if (isFalsePositive) {
                        try {
                            const ticketId = await getLatestTicketId(ticketSubmitTime);
                            stores.sports.comboCard.receiptByCardId.set((state) => {
                                state[cardId] = ticketId;
                            });
                        } catch (error) {
                            addComboCardBetslipError(cardId, translate('Something went wrong', 'ui.common'));
                            logger.info('SportsBetslipBetPlacementService', 'placeComboCardBetWrap', error);
                        }
                    } else if (isNotOddsChangedError) {
                        addComboCardBetslipError(cardId, error);
                    }
                    logger.info(
                        'SportsBetslipBetPlacementService',
                        'placeComboCardBetWrap',
                        `card: ${cardId} - ${error}`,
                    );
                } finally {
                    stores.sports.comboCard.betslipState.set((state) => ({ ...state, isLoading: false }));
                }
            }),
        );
        return isError;
    };
}

export {
    placeBetWrap,
    placeComboCardBetWrap,
    isBetslipButtonDisabled,
    createBetPlaceDataObjectFromStoreByMarketId,
    createDataAndPlaceBet,
    createComboCardsDataAndPlaceBets,
};

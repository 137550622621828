import moment from 'moment';
import {
    getIsScheduledTaskPending,
    purgeExpiredScheduledTaskStorageKeys,
    setScheduledTaskStatusExecuting,
    setScheduledTaskStatusIdle,
} from '../../hooks/useScheduler';
import {
    getGeoVerificationStatus,
    getGeocomplyLicense,
    postGeopacketForProcessing,
} from '../../microservices/geocomply';
import { fetchIpDetails } from '../../microservices/ip';
import { stores } from '../../stores';
import { geocomply } from '../../stores/geocomply';
import { getStoreValue } from '../../stores/store/utils';
import { OPERATING_SYSTEM, getOS } from '../device';
import { isFeatureAvailable } from '../feature';
import { getDeviceUuid, setDeviceUuid } from '../fingerprint';
import { logger } from '../logger';
import { FEATURE } from '../types';
import { getGeocomplyMobileSettings } from './mobile';
import {
    GEOCOMPLY_REASON,
    GeoComplyDesktopLibrary,
    GeoComplyMobileHint,
    GeoComplyMobileClient,
    GeoComplyMobileLibrary,
} from './types';
import {
    geocomplyClientInitialStatus,
    geocomplyInitiaLastReason,
    geocomplyInitialLicense,
    geocomplyInitialServiceError,
    geocomplyInitialVerification,
} from './constants';
import { attemptWithRetry, geocomplyClientErrorHandler, logMessage, startDurationTimer } from './helpers';
import { getGeocomplyDesktopSettings } from './desktop';

export const GEOCOMPLY_VERIFICATION_TASK_NAME = 'GEOCOMPLY_VERIFICATION_TASK';
export const GEOCOMPLY_IP_CHECK_TASK_NAME = 'GEOCOMPLY_IP_CHECK_TASK';

export async function loadGeocomplyLicenseStatus(isLoadByError = false): Promise<void> {
    geocomply.isLoadingLicense.set(true);

    try {
        const geocomplyLicense = await getGeocomplyLicense(isLoadByError);
        geocomply.license.set(geocomplyLicense);
    } catch (error) {
        logger.error('GeocomplyGeocomplyService', 'loadGeocomplyLicenseStatus', error);
        geocomply.license.set(geocomplyInitialLicense);
    } finally {
        geocomply.isLoadingLicense.set(false);
    }
}

export async function loadGeocomplyVerificationStatus(): Promise<void> {
    geocomply.isLoadingVerification.set(true);

    try {
        const { deviceUuid, isGeoVerified, expiresAt, retryAt, ipAddress } = await getGeoVerificationStatus();

        geocomply.verification.set({
            deviceUuid,
            isGeoVerified,
            expiresAt,
            retryAt,
            ipAddress,
        });
    } catch (error) {
        logger.error('GeocomplyGeocomplyService', 'loadGeocomplyVerificationStatus', error);
        geocomply.verification.set(geocomplyInitialVerification);
    } finally {
        geocomply.isLoadingVerification.set(false);
    }
}

export async function getClientIp(): Promise<string> {
    const ipDetails = await fetchIpDetails(window.userIp);
    return ipDetails.query;
}

export function isDesktopPlatform() {
    const os = getOS();
    return [OPERATING_SYSTEM.APPLE_DESKTOP, OPERATING_SYSTEM.WINDOWS_DESKTOP].includes(os);
}

export function isMobilePlatform() {
    const os = getOS();
    return [OPERATING_SYSTEM.APPLE_MOBILE, OPERATING_SYSTEM.ANDROID].includes(os);
}

let geoComplyVerificationPromise: Promise<void> | undefined;

function getGeoComplyVerificationPromise(reason: GEOCOMPLY_REASON, isSilentMode = false, forceVerification = false) {
    if (geoComplyVerificationPromise) {
        return geoComplyVerificationPromise;
    }

    // We build one promise which we return to every verifyGeoLocation call while the processing is taking place
    // eslint-disable-next-line @typescript-eslint/require-await
    geoComplyVerificationPromise = (async () => {
        try {
            setScheduledTaskStatusExecuting(GEOCOMPLY_VERIFICATION_TASK_NAME);

            geocomply.serviceError.set(geocomplyInitialServiceError);
            geocomply.unexpectedError.set(null);
            geocomply.isSilentMode.set(isSilentMode);
            geocomply.lastReason.set(reason);

            if (!forceVerification) {
                await loadGeocomplyVerificationStatus();
            }

            if (!forceVerification && getStoreValue(geocomply.verification).isGeoVerified) {
                return;
            }

            if (isMobilePlatform()) {
                return await geoComplyMobilePlatformGeoVerification(reason);
            }

            if (isDesktopPlatform()) {
                return await geoComplyDesktopPlatformGeoVerification(reason);
            }

            throw new Error(`verifyGeoLocation: Geo-verification handler not implemented for platform ${getOS()}`);
        } catch (error: any) {
            logger.error('GeocomplyGeocomplyService', 'getGeoComplyVerificationPromise', error);
            const hasLicenceExpired = error.code === 608;

            if (hasLicenceExpired) {
                return await recallVerifyGeolocation(reason, isSilentMode, forceVerification);
            } else {
                throw new GeocomplyError(error.message);
            }
        } finally {
            geoComplyVerificationPromise = undefined;
            setScheduledTaskStatusIdle(GEOCOMPLY_VERIFICATION_TASK_NAME);
        }
    })();

    return geoComplyVerificationPromise;
}

export async function renewGeoLocation() {
    try {
        if (!isFeatureAvailable(FEATURE.GEOCOMPLY)) {
            return;
        }

        if (geoComplyVerificationPromise) {
            return geoComplyVerificationPromise;
        }

        await getGeoComplyVerificationPromise('RENEW', true, true);
    } catch (error) {
        logger.error('GeocomplyGeocomplyService', 'renewGeoLocation', error);
    }
}

export async function verifyGeoLocation(reason: GEOCOMPLY_REASON, isSilentMode = false, forceVerification = false) {
    if (!isFeatureAvailable(FEATURE.GEOCOMPLY)) {
        return;
    }

    purgeExpiredScheduledTaskStorageKeys(GEOCOMPLY_VERIFICATION_TASK_NAME);

    if (geoComplyVerificationPromise) {
        return geoComplyVerificationPromise;
    }

    if (getIsScheduledTaskPending(GEOCOMPLY_VERIFICATION_TASK_NAME)) {
        throw new GeocomplyError(
            `Task "${GEOCOMPLY_VERIFICATION_TASK_NAME}" is most likely already running in another tab. Not running it in this tab.`,
        );
    }

    return await getGeoComplyVerificationPromise(reason, isSilentMode, forceVerification);
}

async function recallVerifyGeolocation(reason: GEOCOMPLY_REASON, isSilentMode = false, forceVerification = false) {
    const isLoadingLicense = getStoreValue(geocomply.isLoadingLicense);

    geoComplyVerificationPromise = undefined;
    setScheduledTaskStatusIdle(GEOCOMPLY_VERIFICATION_TASK_NAME);

    if (!isLoadingLicense) {
        return await verifyGeoLocation(reason, isSilentMode, forceVerification);
    } else {
        setTimeout(() => {
            recallVerifyGeolocation(reason, isSilentMode, forceVerification);
        }, 250);
    }
}

async function handleEncryptedGeopacket(encryptedGeopacket: string, reason: GEOCOMPLY_REASON): Promise<void> {
    try {
        const geoVerificationStatus = await postGeopacketForProcessing(encryptedGeopacket);

        const { deviceUuid, isGeoVerified, expiresAt, retryAt, ipAddress } = geoVerificationStatus;
        setDeviceUuid(deviceUuid);

        geocomply.verification.set({
            deviceUuid,
            isGeoVerified,
            expiresAt,
            retryAt,
            ipAddress,
        });
    } catch (error: any) {
        const isKnownErrorCode = error.code >= 1000 && error.code < 4000;
        const hasLicenceExpired = error.code === 608;

        if (hasLicenceExpired) {
            return await recallVerifyGeolocation(reason);
        } else if (isKnownErrorCode) {
            geocomply.serviceError.set({
                code: error.code,
                name: error.name,
                troubleshooter: error.troubleshooter ?? null,
            });
        } else {
            geocomply.unexpectedError.set(error);
        }

        logger.error('GeocomplyGeocomplyService', 'handleEncryptedGeopacket', error);

        throw error;
    }
}

export function softResetGeocomplyStore() {
    geocomply.isSilentMode.set(false);
    geocomply.serviceError.set(geocomplyInitialServiceError);
    geocomply.unexpectedError.set(null);
    geocomply.client.set({
        ...geocomplyClientInitialStatus,
        logs: getStoreValue(geocomply.persistLogs) ? getStoreValue(geocomply.client).logs : [],
        isLibraryLoaded: getStoreValue(geocomply.client).isLibraryLoaded,
    });
}

export function hardResetGeocomplyStore() {
    geocomply.isDevModeVisibility.set(false);
    geocomply.isSilentMode.set(false);
    geocomply.isLoadingVerification.set(false);
    geocomply.isLoadingLicense.set(false);
    geocomply.persistLogs.set(false);
    geocomply.lastReason.set(geocomplyInitiaLastReason);
    geocomply.license.set(geocomplyInitialLicense);
    geocomply.verification.set(geocomplyInitialVerification);
    geocomply.serviceError.set(geocomplyInitialServiceError);
    geocomply.unexpectedError.set(null);
    geocomply.client.set(geocomplyClientInitialStatus);
}

export function getCountdownState(countdownEndTimeISO: string, currentTimeISO?: string) {
    const currentTime = currentTimeISO ? moment(currentTimeISO) : moment();
    const countdownEndTime = moment(countdownEndTimeISO);
    const durationUntilEndTime = moment.duration(countdownEndTime.diff(currentTime));
    const isCountdownCompleted = durationUntilEndTime.asSeconds() <= 0;

    return {
        formattedCountdown: isCountdownCompleted
            ? '00:00'
            : (durationUntilEndTime as any).format('mm:ss', { trim: false }),
        totalMinutesLeft: Math.max(durationUntilEndTime.asMinutes(), 0),
        totalSecondsLeft: Math.max(durationUntilEndTime.asSeconds(), 0),
        totalMillisecondsLeft: Math.max(durationUntilEndTime.asMilliseconds(), 0),
    };
}

class GeocomplyError extends Error {
    constructor(message = 'GeoComply Error') {
        super(message);
    }
}

async function geoComplyDesktopPlatformGeoVerification(reason: GEOCOMPLY_REASON) {
    const geocomplyDesktopSettings = getGeocomplyDesktopSettings(reason);
    const GeoComplyDesktopLibrary = (window as any).GeoComply as GeoComplyDesktopLibrary;

    (window as any).CoolbetGeoComplyDesktopLibrary = GeoComplyDesktopLibrary;

    const knownErrorCodes = Object.entries(GeoComplyDesktopLibrary.Client)
        .filter(([key]) => key.startsWith('CLNT_ERROR_'))
        .map(([, value]) => value) as Array<number>;

    geocomply.client.set((geocomplyClient) => {
        geocomplyClient.libraryVersion = GeoComplyDesktopLibrary.Client.getJSLibraryVersion();
        geocomplyClient.clientError = null;
    });

    const attemptConnection = (attemptIndex: number, totalAttempts: number) =>
        new Promise<void>((resolve, reject) => {
            logMessage(`Connection attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();

            GeoComplyDesktopLibrary.Client.on('connect', () => {
                logMessage(`Connecting succeeded after ${getDuration().humanized}`, 'INFO');
                logMessage('GeoComply client connected', 'SUCCESS');

                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = null;
                    geocomplyClient.clientVersion = GeoComplyDesktopLibrary.Client.getVersion();
                    geocomplyClient.isConnected = true;
                    geocomplyClient.isConnecting = false;
                    geocomplyClient.isGeolocating = false;
                });

                resolve();
            });

            GeoComplyDesktopLibrary.Client.on('error', (code, message) => {
                logMessage(`Connecting failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(code, message);
                reject({ code, message });
            });

            GeoComplyDesktopLibrary.Client.on('log', (message) => logMessage(message, 'DEBUG'));

            GeoComplyDesktopLibrary.Client.connect(
                geocomplyDesktopSettings.installerID,
                geocomplyDesktopSettings.envId,
            );

            geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isConnecting = true;
            });
        }).finally(() => {
            GeoComplyDesktopLibrary.Client.off('connect');
            GeoComplyDesktopLibrary.Client.off('error');
            GeoComplyDesktopLibrary.Client.off('log');
        });

    const attemptConnectionWithRetry = () =>
        attemptWithRetry(
            [GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptConnection(attemptIndex, totalAttempts),
        );

    const attemptGeoLocation = (attemptIndex: number, totalAttempts: number) =>
        new Promise<string>((resolve, reject) => {
            logMessage(`Geolocation attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();

            GeoComplyDesktopLibrary.Client.on('geolocation', (encryptedGeopacket: string) => {
                logMessage(`GeoLocating succeeded after ${getDuration().humanized}`, 'INFO');
                logMessage(`GeoPacket created: ${encryptedGeopacket}`, 'SUCCESS');

                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = null;
                    geocomplyClient.clientVersion = GeoComplyDesktopLibrary.Client.getVersion();
                    geocomplyClient.isConnected = true;
                    geocomplyClient.isConnecting = false;
                    geocomplyClient.isGeolocating = false;
                });

                resolve(encryptedGeopacket);
            });

            GeoComplyDesktopLibrary.Client.on('error', (code, message) => {
                logMessage(`GeoLocating failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(code, message);
                reject({ code, message });
            });

            GeoComplyDesktopLibrary.Client.on('log', (message) => logMessage(message, 'DEBUG'));

            const { license, reason, userId, customFields } = geocomplyDesktopSettings;
            GeoComplyDesktopLibrary.Client.setLicense(license as string);
            GeoComplyDesktopLibrary.Client.setGeolocationReason(reason as string);
            GeoComplyDesktopLibrary.Client.setUserId(userId as string);
            GeoComplyDesktopLibrary.Client.customFields.set('session_key', customFields.sessionKey as string);

            GeoComplyDesktopLibrary.Client.requestGeolocation();

            geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isGeolocating = true;
            });
        }).finally(() => {
            GeoComplyDesktopLibrary.Client.off('geolocation');
            GeoComplyDesktopLibrary.Client.off('error');
            GeoComplyDesktopLibrary.Client.off('log');
        });

    const attemptGeolocationWithRetry = () =>
        attemptWithRetry(
            [GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptGeoLocation(attemptIndex, totalAttempts),
        );

    try {
        geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = true;
        });

        await attemptConnectionWithRetry();

        geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isAttemptingGeolocation = true;
        });

        const encryptedGeopacket = await attemptGeolocationWithRetry();
        await handleEncryptedGeopacket(encryptedGeopacket, reason);
    } finally {
        GeoComplyDesktopLibrary.Client.disconnect();

        geocomply.client.set((geocomplyClient) => {
            geocomplyClient.clientVersion = null;
            geocomplyClient.isConnected = false;
            geocomplyClient.isConnecting = false;
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isGeolocating = false;
            geocomplyClient.isAttemptingGeolocation = false;
        });
    }
}

async function geoComplyMobilePlatformGeoVerification(reason: GEOCOMPLY_REASON): Promise<void> {
    const geocomplyMobileSettings = getGeocomplyMobileSettings(reason);
    const GeoComplyMobileLibrary = (window as any).GCOobee as GeoComplyMobileLibrary;
    const GeoComplyMobileClient = GeoComplyMobileLibrary.createClient();

    (window as any).CoolbetGeoComplyMobileLibrary = GeoComplyMobileLibrary;
    (window as any).CoolbetGeoComplyMobileClient = GeoComplyMobileClient;

    const knownErrorCodes = Object.values(GeoComplyMobileClient.ErrorCodes);

    geocomply.client.set((geocomplyClient) => {
        geocomplyClient.libraryVersion = GeoComplyMobileClient.getVersion();
        geocomplyClient.clientError = null;
        geocomplyClient.hint = null;
    });

    function setupInformationalEventLoggers(geoComplyMobileClient: GeoComplyMobileClient) {
        const EVENTS = geoComplyMobileClient.EVENTS;

        GeoComplyMobileClient.events.on(EVENTS.LOG, (...args: any[]) => {
            logMessage(`(${EVENTS.LOG}) ${JSON.stringify(args, null, 4)}`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.BEFORE, () => {
            logMessage(`(${EVENTS.BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.REVISE_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(`(${EVENTS.REVISE_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.INIT_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(`(${EVENTS.INIT_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.INIT_SUCCESS, () => {
            logMessage(`(${EVENTS.INIT_SUCCESS})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_BEFORE, () => {
            logMessage(`(${EVENTS.USERDEVICE_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_FAILED, () => {
            logMessage(`(${EVENTS.USERDEVICE_FAILED})`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_REGISTERED, () => {
            logMessage(`(${EVENTS.USERDEVICE_REGISTERED})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_UNREGISTERED, () => {
            logMessage(`(${EVENTS.USERDEVICE_UNREGISTERED})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.REGISTER_BEFORE, () => {
            logMessage(`(${EVENTS.REGISTER_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.REGISTER_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(
                `(${EVENTS.REGISTER_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`,
                'ERROR',
            );
        });

        GeoComplyMobileClient.events.on(EVENTS.REGISTER_SUCCESS, (deviceUid: string, pairCode: string) => {
            logMessage(`(${EVENTS.REGISTER_SUCCESS}) | deviceUid: ${deviceUid} | pairCode: ${pairCode}`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.DEREGISTER_BEFORE, () => {
            logMessage(`(${EVENTS.DEREGISTER_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.DEREGISTER_FAILED, () => {
            logMessage(`(${EVENTS.DEREGISTER_FAILED})`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.DEREGISTER_SUCCESS, () => {
            logMessage(`(${EVENTS.DEREGISTER_SUCCESS})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.CONNECT_BEFORE, () => {
            logMessage(`(${EVENTS.CONNECT_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.CONNECT_SUCCESS, () => {
            logMessage(`(${EVENTS.CONNECT_SUCCESS})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.CONNECT_FAILED, () => {
            logMessage(`(${EVENTS.CONNECT_FAILED})`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_BEFORE, () => {
            logMessage(`(${EVENTS.GEOLOCATION_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_PENDING, () => {
            logMessage(`(${EVENTS.GEOLOCATION_PENDING})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(
                `(${EVENTS.GEOLOCATION_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`,
                'ERROR',
            );
        });

        GeoComplyMobileClient.events.on(
            EVENTS.GIF_STOP_UPDATING,
            ({ code, message }: { code: number; message: string }) => {
                logMessage(`(${EVENTS.GIF_STOP_UPDATING}) | code: ${code} | message: ${message}`, 'ERROR');
            },
        );

        GeoComplyMobileClient.events.on(EVENTS.GIF_BLUETOOTH_OFF, (message: string) => {
            logMessage(`(${EVENTS.GIF_BLUETOOTH_OFF}) | message: ${message}`, 'ERROR');
        });
    }

    const attemptConnection = (attemptIndex: number, totalAttempts: number) =>
        new Promise<void>((resolve, reject) => {
            if (GeoComplyMobileLibrary.utils.browser.is.ios && GeoComplyMobileClient.hasGeolocatedRecently()) {
                logMessage(`iOS GeoComply client has geolocated recently - skipping connection attempt`, 'INFO');
                resolve();
                return;
            }

            logMessage(`Connection attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();
            const EVENTS = GeoComplyMobileClient.EVENTS;

            setupInformationalEventLoggers(GeoComplyMobileClient);

            GeoComplyMobileClient.events.on(EVENTS.HINT, (hint: GeoComplyMobileHint) => {
                logMessage(`(${EVENTS.HINT}) | hint: ${JSON.stringify(hint, null, 4)}`, 'WARNING');

                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.hint = hint;
                });
            });

            GeoComplyMobileClient.events.on('*.failed', (errorCode: number, errorMessage: string) => {
                logMessage(`Connecting failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(errorCode, errorMessage);
                reject({ code: errorCode, message: errorMessage });
            });

            GeoComplyMobileClient.connect(
                {
                    timeout: 15000,
                    userAction: true,
                },
                () => {
                    logMessage(`Connecting succeeded after ${getDuration().humanized}`, 'INFO');
                    logMessage('GeoComply client connected', 'SUCCESS');

                    geocomply.client.set((geocomplyClient) => {
                        geocomplyClient.clientError = null;
                        geocomplyClient.clientVersion = GeoComplyMobileClient.getVersion();
                        geocomplyClient.isConnected = true;
                        geocomplyClient.isConnecting = false;
                        geocomplyClient.isGeolocating = false;
                    });

                    resolve();
                },
                () => {
                    logMessage(`Connecting failed after ${getDuration().humanized}`, 'INFO');
                    logMessage('GeoComply client connection failed', 'ERROR');

                    geocomply.client.set((geocomplyClient) => {
                        geocomplyClient.clientError = null;
                        geocomplyClient.clientVersion = null;
                        geocomplyClient.isConnected = false;
                        geocomplyClient.isConnecting = false;
                        geocomplyClient.isGeolocating = false;
                    });

                    reject();
                },
            );

            geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isConnecting = true;
            });
        }).finally(() => {
            GeoComplyMobileClient.events.removeAllListeners();
        });

    const attemptConnectionWithRetry = () =>
        attemptWithRetry(
            [
                GeoComplyMobileClient.ErrorCodes.ERROR_INTERNAL as number,
                GeoComplyMobileClient.ErrorCodes.UNEXPECTED as number,
                GeoComplyMobileClient.ErrorCodes.ERROR_UNEXPECTED as number,
            ],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptConnection(attemptIndex, totalAttempts),
        );

    const attemptGeoLocation = (attemptIndex: number, totalAttempts: number) =>
        new Promise<string>((resolve, reject) => {
            logMessage(`Geolocation attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();
            const EVENTS = GeoComplyMobileClient.EVENTS;

            setupInformationalEventLoggers(GeoComplyMobileClient);

            GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_SUCCESS, (encryptedGeopacket: string) => {
                logMessage(`GeoLocating succeeded after ${getDuration().humanized}`, 'INFO');

                logMessage(`GeoPacket created: ${encryptedGeopacket}`, 'SUCCESS');

                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = null;
                    geocomplyClient.clientVersion = GeoComplyMobileClient.getVersion();
                    geocomplyClient.isConnected = true;
                    geocomplyClient.isConnecting = false;
                    geocomplyClient.isGeolocating = false;
                });

                resolve(encryptedGeopacket);
            });

            GeoComplyMobileClient.events.on(EVENTS.HINT, (hint: GeoComplyMobileHint) => {
                logMessage(`(${EVENTS.HINT}) | hint: ${JSON.stringify(hint, null, 4)}`, 'WARNING');

                geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.hint = hint;
                });
            });

            GeoComplyMobileClient.events.on('*.failed', (errorCode: number, errorMessage: string) => {
                logMessage(`GeoLocating failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(errorCode, errorMessage);
                reject({ code: errorCode, message: errorMessage });
            });

            GeoComplyMobileClient.request();

            geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isGeolocating = true;
            });
        }).finally(() => {
            GeoComplyMobileClient.events.removeAllListeners();
        });

    const attemptGeolocationWithRetry = () =>
        attemptWithRetry(
            [
                GeoComplyMobileClient.ErrorCodes.ERROR_INTERNAL as number,
                GeoComplyMobileClient.ErrorCodes.UNEXPECTED as number,
                GeoComplyMobileClient.ErrorCodes.ERROR_UNEXPECTED as number,
            ],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptGeoLocation(attemptIndex, totalAttempts),
        );

    try {
        try {
            GeoComplyMobileClient.configure({
                serviceUrl: geocomplyMobileSettings.serviceUrl,
                oobeeUrl: geocomplyMobileSettings.oobeeUrl,
                license: geocomplyMobileSettings.license,
                userId: geocomplyMobileSettings.userId,
                reason: geocomplyMobileSettings.reason,
                customFields: {
                    sessionKey: geocomplyMobileSettings.customFields.sessionKey as string,
                },
            });
        } catch (error: any) {
            logMessage(`GeoComply client configuration failed | ${error.message}`, 'ERROR');
            throw error;
        }

        geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = true;
        });

        await attemptConnectionWithRetry();

        geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isAttemptingGeolocation = true;
        });

        const encryptedGeopacket = await attemptGeolocationWithRetry();
        await handleEncryptedGeopacket(encryptedGeopacket, reason);
    } finally {
        geocomply.client.set((geocomplyClient) => {
            geocomplyClient.clientVersion = null;
            geocomplyClient.isConnected = false;
            geocomplyClient.isConnecting = false;
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isGeolocating = false;
            geocomplyClient.isAttemptingGeolocation = false;
        });
    }
}

export function getGeocomplyHeaders() {
    const deviceUuid = getDeviceUuid();
    const verificationData = getStoreValue(stores.verification.data);

    const headers = {
        ...(deviceUuid && { device_uuid: deviceUuid }),
        ...(verificationData.signUpToken && { 'signup-token': verificationData.signUpToken }),
    };

    return headers;
}

import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../services/date';

export default function CasinoV2Maintenance() {
    const [endDate] = useStore(stores.maintenance.casinoMaintenanceEndDate);

    return (
        <Wrapper>
            <div className="image-wrapper">
                <h1 className="title">{translate('lobby-maintenance-title', 'ui.casino')}</h1>
                <p className="content">{translate('lobby-maintenance-description-1', 'ui.casino')}</p>
                <p className="content">
                    {translate('lobby-maintenance-description-2', 'ui.casino')}
                    <span>
                        {getFormattedDate({
                            date: endDate,
                            format: DATE_YEAR_TIME_FORMAT,
                            useDots: true,
                        })}
                    </span>
                </p>
            </div>
        </Wrapper>
    );
}

import capitalize from 'lodash/capitalize';
import invert from 'lodash/invert';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { isStage } from './environment';
import { Country, Licence } from '@staycool/location';
import { environment } from '../stores/environment/environment';
import { storageGet } from './storage';

export enum LicenceRequirementSteps {
    REQUIRED_FIELDS,
    DEPOSIT_LIMIT,
    LOSS_LIMIT,
    PERSONAL_ID,
    LOGIN_DURATION,
}

const PRODUCTION_LICENCES = [
    Licence.ESTONIA,
    Licence.MALTA,
    Licence.MEXICO,
    Licence.PERU,
    Licence.SIMULATED_GAMING,
    Licence.SWEDEN,
    Licence.USA_ARKANSAS,
    Licence.USA_MICHIGAN,
    Licence.USA_MISSISSIPPI,
    Licence.USA_NEVADA,
];

export const LICENCE_NAME_BY_LICENCE_CODE = invert(Licence);

export function getLicenceName() {
    return capitalize(LICENCE_NAME_BY_LICENCE_CODE[getLicence()].toLowerCase());
}

export function getFormattedLicenceName() {
    return LICENCE_NAME_BY_LICENCE_CODE[getLicence()].toLowerCase().replace('_', '-');
}

export function isWithLicence(licence: Licence) {
    return getLicence() === licence;
}

export function getLicence(): Licence {
    const user = getStoreValue(stores.user);
    if (user?.licence) {
        return user.licence;
    }
    return getVisitorLicence() || getDefaultLicence();
}

function getVisitorLicence() {
    const appLicence = storageGet<Licence>('app-licences');
    if (isLicenceEnabled(appLicence)) {
        return appLicence;
    }

    const ipLicence = Object.values(Licence).find((code) => code === getStoreValue(stores.ipCountry));
    if (isLicenceEnabled(ipLicence)) {
        return ipLicence as Licence;
    }
}

function isLicenceEnabled(licence?: Licence) {
    if (!licence) {
        return false;
    }
    const { AVAILABLE_LICENSES: availableLicences } = getStoreValue(environment);
    return availableLicences.includes(licence) && isEnvironmentLicence(licence);
}

function isEnvironmentLicence(licence: Licence) {
    const licences = isStage() ? Object.values(Licence) : PRODUCTION_LICENCES;
    return licences.includes(licence);
}

function getDefaultLicence() {
    const { AVAILABLE_LICENSES: availableLicences } = getStoreValue(environment);
    const defaultLicence = availableLicences.find((licence) => isEnvironmentLicence(licence));
    return defaultLicence || Licence.MALTA;
}

export const LICENCE_FLAG_COUNTRY = {
    [Licence.ESTONIA]: Country.ESTONIA,
    [Licence.MEXICO]: Country.MEXICO,
    [Licence.SWEDEN]: Country.SWEDEN,
    [Licence.USA_ARKANSAS]: Country.USA,
    [Licence.USA_MICHIGAN]: Country.USA,
    [Licence.USA_MISSISSIPPI]: Country.USA,
    [Licence.USA_NEVADA]: Country.USA,
};

import { CasinoRace, CasinoRaceHistory, CasinoRaceScore, CasinoGame } from '../services/casino/types';
import { createStores } from './store/utils';

export const casinoRace = createStores({
    isMobileRaceGameMenuVisible: false,
    raceGamesModal: createStores({
        isVisible: false,
        races: {} as Record<number, CasinoRace>,
    }),
    active: createStores({
        races: {} as Record<number, CasinoRace>,
        scores: {} as Record<number, CasinoRaceScore>,
        leaderboards: {} as Record<number, CasinoRaceScore[]>,
        positions: {} as Record<number, number>,
    }),
    clientServerTimeDifference: 0,
    raceEnds: {} as Record<number, CasinoRace>,
    isRaceSidebarVisible: false,
    historyCardClicked: {} as CasinoRaceHistory,
    activeRaceClicked: {} as CasinoRace,
    games: [] as CasinoGame[],
});

export const LocalStorage = {
    AVAILABLE_LICENCES: 'app-licences',
    BETSLIP_SETTINGS: 'betSlipSettings',
    BETBUIDLER_BETSLIP_ID_BY_MARKET_ID: 'betbuilderBetslipIdByMarketId',
    CASINO_BOOSTER_IS_COMPACT: 'casino.booster.isCompact',
    LANGUAGE: 'language',
    PREFER_LOGIN: 'preferLogin',
    PREMATCH_SELECTED_MENU: 'prematchSelectedMenu',
    REPORTED_MISSING_TRANSLATIONS: 'reportedMissingTranslations',
    RECENTLY_PLAYED: 'recentlyPlayed',
    SHOW_ANNUAL_REPORT: 'show_annual_report',
    QUICK_DEPOSIT_STATUS: 'quickDepositStatus',
} as const;
